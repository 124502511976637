import * as React from 'react';
import { UserProfile } from '../models/ProfileServiceModel';

export interface UserContext {
    skypetoken: string | undefined;
    expiration: number | undefined;
    profile: UserProfile | undefined;
    isLoginInProgress: boolean;
    silentLoginNotSupported?: boolean;
}

// Default context does not mean much sense here
const context = React.createContext<UserContext>({} as UserContext);

export const UserContextProvider = context.Provider;

export const UserContextConsumer = context.Consumer;
