export interface IMeetNowSettings {
    MeetNowEdgeJoinApiHost: string;
    MeetNowEdgeNumberOfFetchRetries: number;
    MeetNowEdgeAriaTenantId: string;
    MeetNowEdgeAllowedQueryParameters: string[];
    DefaultMeetingTitle: string;
}

const rootElementId = 'meetnowContainer';
const settingsDataAttributeName = 'meetnowEdgeSettings';
const allowedQueryParameters = ['source', 'exp', 'correlationId', 'call', 'video', 'mic'];
const defaultMeetingTitle = 'Meet Now';

let settings: MeetNowSettings;

const init = () => {
    const rootElement = document.getElementById(rootElementId);
    if (rootElement) {
        const data = rootElement.dataset[settingsDataAttributeName] || '';
        const parsedSettings = JSON.parse(data) as IMeetNowSettings;
        settings = new MeetNowSettings(parsedSettings);
    } else {
        throw new Error('Config not found.');
    }
};

class MeetNowSettings implements IMeetNowSettings {
    private readonly settings: IMeetNowSettings;

    constructor(currentSettings: IMeetNowSettings) {
        this.settings = currentSettings;
    }

    get MeetNowEdgeAriaTenantId() {
        return this.settings.MeetNowEdgeAriaTenantId;
    }
    get MeetNowEdgeJoinApiHost() {
        return this.settings.MeetNowEdgeJoinApiHost;
    }
    get MeetNowEdgeNumberOfFetchRetries() {
        return this.settings.MeetNowEdgeNumberOfFetchRetries;
    }
    get MeetNowEdgeAllowedQueryParameters() {
        return allowedQueryParameters;
    }
    get DefaultMeetingTitle() {
        return defaultMeetingTitle;
    }
}

const getMeetNowSettings = (): MeetNowSettings => {
    if (settings) {
        return settings;
    }

    init();
    return settings;
};

export default getMeetNowSettings;
