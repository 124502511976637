import { localizer } from '@skype/bew-localization';
import config from 'config';
import * as React from 'react';
import { clipboardHelper } from '../../helpers/ClipboardHelper';

export interface JoinLinkProps {
    shortId: string;
}

interface JoinLinkState {
    copied: boolean;
}

const removeProtocol = (url: string): string => url.replace('https://', '');

export class JoinLink extends React.Component<JoinLinkProps, JoinLinkState> {
    constructor(props: JoinLinkProps) {
        super(props);

        this.state = {
            copied: false,
        };
    }

    componentDidUpdate() {
        if (!this.state.copied) {
            return;
        }

        setTimeout(() => this.setState({
            copied: false,
        }), 5000);
    }

    render() {
        const copied = this.state.copied;
        const joinLink = `${ config.urls.app.join }/${ this.props.shortId }`;
        const joinLinkClassName = `joinLink ${ copied ? 'copied' : '' }`;

        return (
            <div className='joinLinkContainer'>
                { localizer.getString('txt_launch_universal_link_fallback') }

                <div role='button' tabIndex={ 0 } className={ joinLinkClassName } onClick={ this._getClickHandler(joinLink) } onKeyDown={ this._getKeyDownHandler(joinLink) }>
                    { removeProtocol(joinLink) }
                </div>

                { copied && <div className='info'>
                    { localizer.getString('txt_launch_link_copied') }
                </div> }
            </div>
        );
    }

    private _getClickHandler = (joinLink: string) => () => {
        clipboardHelper.copyToClipboard(joinLink);

        this.setState({
            copied: true,
        });
    }

    private _getKeyDownHandler = (joinLink: string) => (e: React.KeyboardEvent) => {
        if (13 !== e.keyCode) {
            return;
        }

        this._getClickHandler(joinLink)();
    }
}
