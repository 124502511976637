import { localizer } from '@skype/bew-localization';
import { Avatar, Button, Header, Text } from '@stardust-ui/react';
import config from 'config';
import * as React from 'react';
import { logger } from 'src/ts/helpers/LoggerHelper';
import { ProfilesContextConsumer } from '../../contexts/profiles/ProfilesContext';
import { OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { Footer } from '../common/Footer';
import { Padder } from '../common/Padder';

interface ProfileViewProps {
    onLaunchClick: () => void;
}

export class ProfileView extends React.PureComponent<ProfileViewProps, React.StatelessComponent> {

    renderAvatar() {
        return <ProfilesContextConsumer>{ (profilesContext) =>
            <Avatar styles={ { margin: '10px 0px' } } size={ 100 } image={ {
                src: `https://avatar.skype.com/v1/avatars/${ profilesContext.skypeId }/public?size=m&returnDefaultImage=true`,
                styles: { width: '100%', height: '100%', objectFit: 'cover' },
            } } />
        }</ProfilesContextConsumer>;
    }

    renderTitle() {
        return <ProfilesContextConsumer>{ (profilesContext) =>
            <Header as='h1' className='title'>{ localizer.get('add_to_contacts_user', {
                user_name: <b style={ { fontWeight: 'bold' } }>{ profilesContext.skypeId }</b>,
            }) }</Header>
        }</ProfilesContextConsumer>;
    }

    renderControls() {
        return <>
            <Button key={ 0 } id='btnLaunch' primary={ true } onClick={ this.props.onLaunchClick }>
                { localizer.getString('add_to_contacts') }
            </Button>
        </>;
    }

    renderBody() {
        return <>
            <Text size={ 'medium' } className='body'>
                { localizer.getString('txt_launch_first_time_device') }
            </Text>
            <Text size={ 'medium' }>
                { localizer.get('txt_launch_please_download', {
                    download_skype: <a href='javascript:void(0);' id='downloadMobileClient'
                        onClick={ this._downloadSkype } style={ { textDecoration: 'none', color: '#0078d4' } }>
                        { localizer.getString('btn_launch_download_skype') }
                    </a>,
                }) }
            </Text>
        </>;
    }

    render() {
        return <div className='profiles profileView'>
            <Padder />
            <div className='scrollable-y'>
                { this.renderAvatar() }
                { this.renderTitle() }
                { this.renderControls() }
                { this.renderBody() }
            </div>
            <Padder />
            <Footer />
        </div>;
    }

    private _downloadSkype = () => {
        logger.action({ name: 'download' });

        const downloadUrl = os === OperatingSystems.MacOS ? config.urls.app.downloadDesktop : config.urls.app.download;
        window.open(downloadUrl, '_blank');
    }
}
