import { AWTPiiKind } from '@aria/webjs-sdk';
import { localizer } from '@skype/bew-localization';
import { Button, Portal, Provider, ThemeInput, themes } from '@stardust-ui/react';
import config from 'config';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import * as React from 'react';
import * as SyncTasks from 'synctasks';
import '../../vendor/silentLogin';
import { RootView as BotsRootView } from './components/bots/RootView';
import { RootView as ChannelsRootView } from './components/channels/RootView';
import { AriaLive } from './components/common/AriaLive';
import { ErrorBoundary } from './components/common/ErrorBoundary';
import { ErrorDetails } from './components/common/ErrorDetails';
import { RootView as DoRootView } from './components/do/RootView';
import { RootView as InvitesRootView } from './components/invites/RootView';
import { RootView as MeetingsRootView } from './components/meetings/RootView';
import { RootView as ProfilesRootView } from './components/profiles/RootView';
import { AriaContext, AriaContextProvider, AriaMessage } from './contexts/AriaContext';
import { ConfigContext, ConfigContextProvider } from './contexts/ConfigContext';
import { EcsConfigFallback, EcsContext, EcsContextProvider } from './contexts/EcsContext';
import { ErrorContext, ErrorContextProvider } from './contexts/ErrorContext';
import { UserContext, UserContextProvider } from './contexts/UserContext';
import { CookieHelper } from './helpers/CookieHelper';
import { ecsHelper } from './helpers/EcsHelper';
import { browser, Browsers, canJoinOnWeb, deviceType, DeviceTypes, EnvironmentHelper } from './helpers/EnvironmentHelper';
import { DisplayableError } from './helpers/ErrorHelper';
import { loadTranslation } from './helpers/LocalizerHelper';
import { CommonProperty, logger } from './helpers/LoggerHelper';
import { silentLoginAsync, SilentLoginResult } from './helpers/SilentLoginHelper';
import { mainContentId, Themes } from './models/SharedTypes';
import ProfileRestClient from './rest/ProfileRestClient';
import * as localThemes from './themes';

export enum EntryPoint {
    Meetings = 'meetings',
    Invites = 'invites',
    Bots = 'bots',
    Channels = 'channels',
    Do = 'do',
    Profiles = 'profiles',
}

export interface AppProps {
    readonly language: string;
    readonly basePath: string;
    readonly entryPoint: EntryPoint;
    readonly skypetoken?: string;
    readonly theme?: Themes;
    readonly country?: string;
}

export interface AppState {
    loadingTranslations: boolean;
    loadingEcs: boolean;
    theme: ThemeInput;
    userContext: UserContext;
    configContext: ConfigContext;
    errorContext: ErrorContext;
    ariaContext: AriaContext;
    ecsContext: EcsContext;
}

const SilentLoginTimeout = 8000;

export class App extends React.Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            loadingTranslations: true,
            loadingEcs: true,
            configContext: {
                ...config,
                basePath: this.props.basePath,
                language: props.language,
                switchLanguage: this._switchLanguage,
            },
            theme: merge({}, localThemes.common, props.theme === Themes.Teams ? localThemes.teams : localThemes.light),
            userContext: {
                skypetoken: undefined,
                expiration: undefined,
                profile: undefined,
                isLoginInProgress: this._canUseSilentLogin(),
                silentLoginNotSupported: !this._canUseSilentLogin(),
            },
            errorContext: {
                error: undefined,
                setError: this._setErrorContext,
                clear: this._clearErrorContext,
            },
            ariaContext: {
                message: { assertive: false, text: '' },
                announce: this._announce,
            },
            ecsContext: {
                settings: EcsConfigFallback,
            },
        };

        logger.initCommonProperty({ key: CommonProperty.EntryPoint, value: props.entryPoint });
    }

    componentDidMount() {
        // Injecting browser specific styles in <head>
        const style = document.createElement('style');
        if (browser === Browsers.Safari && deviceType === DeviceTypes.Mobile && document.head) {
            style.innerHTML = `.meetings .ui-button, .bots .ui-button, .invites .ui-button { display: block; }`;
            document.head.appendChild(style);
        }

        loadTranslation(this.props.language);
        this.setState({ loadingTranslations: false });

        if (this._canUseSilentLogin()) {
            const silentLoginPromise = silentLoginAsync()
                .then(this._getProfileAsync)
                .then((userContext) => {
                    this.setState({ userContext });
                })
                .catch((error) => {
                    logger.error(error);
                    this.setState({
                        userContext: {
                            profile: undefined,
                            skypetoken: undefined,
                            expiration: undefined,
                            isLoginInProgress: false,
                        },
                    });
                }); // it's ok if the user is not logged in

            SyncTasks.raceTimer(silentLoginPromise, SilentLoginTimeout).then((result) => {
                if (result.timedOut) {
                    logger.error(new Error('Silent Login TimeOut'));
                    silentLoginPromise.cancel();
                    this._startLoadingEcs();
                    this.setState({
                        userContext: {
                            profile: undefined,
                            skypetoken: undefined,
                            expiration: undefined,
                            isLoginInProgress: false,
                        },
                    });
                }
            });
        } else {
            this._startLoadingEcs();
        }
    }

    componentDidUpdate(_: AppProps, prevState: AppState) {
        if (prevState.userContext.isLoginInProgress && !this.state.userContext.isLoginInProgress) {
            this._startLoadingEcs();
        }
    }

    render() {
        return <>
            { !this.state.loadingTranslations && <a tabIndex={ -1 } className='hidden' href={ `#${ mainContentId }` }>{ localizer.getString('Common.a11y_lnk_skip_content') }</a> }
            <Provider theme={ { ...themes.teams, siteVariables: { ...themes.teams.siteVariables, htmlFontSize: '16px' } } }>
                <Provider theme={ this.state.theme }>
                    <ConfigContextProvider value={ this.state.configContext }>
                        <UserContextProvider value={ this.state.userContext }>
                            <EcsContextProvider value={ this.state.ecsContext }>
                                <ErrorBoundary>
                                    <ErrorContextProvider value={ this.state.errorContext }>
                                        <AriaContextProvider value={ this.state.ariaContext }>
                                            <AriaLive />
                                            { this._getRootView() }
                                            { this._renderErrorPortal() }
                                        </AriaContextProvider>
                                    </ErrorContextProvider>
                                </ErrorBoundary>
                            </EcsContextProvider>
                        </UserContextProvider>
                    </ConfigContextProvider>
                </Provider>
            </Provider>
        </>;
    }

    private _renderErrorPortal() {
        const error = this.state.errorContext && this.state.errorContext.error;
        if (!error) {
            return;
        }
        return <Portal
            trapFocus={ {
                isClickableOutsideFocusTrap: false,
            } }
            open={ !!error }
            content={ this._renderErrorPortalContent(error) }
            as={ noop }
        />;
    }

    private _renderErrorPortalContent(error: DisplayableError) {
        return <div className='portalContainer' role='dialog' aria-modal='true'>
            <div className='portalContent' >
                <ErrorDetails error={ error } />
                <Button content={ localizer.getString('Common.btn_close') } className='closeButton' onClick={ this.state.errorContext && this.state.errorContext.clear } />
            </div>
        </div>;
    }

    private _startLoadingEcs() {
        ecsHelper.loadConfig(this.state.userContext.skypetoken, this.state.userContext.expiration, (settings) => {
            const wasPreLoggedin = Boolean(this.props && this.state.userContext && this.state.userContext.skypetoken);
            const useUnifiedView = this._determineIsItUnifiedView(settings.useUnifiedView);
            const quickJoinFlowSupportedBrowsers = settings.quickJoinFlowSupportedBrowsers ?? EcsConfigFallback.quickJoinFlowSupportedBrowsers;
            EnvironmentHelper.updateEnvironmentSettings(quickJoinFlowSupportedBrowsers);
            logger.sessionStarted({useUnifiedView, wasPreLoggedin});
            this.setState({
                loadingEcs: false,
                ecsContext: {
                    settings: {
                        useUnifiedView,
                        quickJoinFlowSupportedBrowsers,
                        useMeetingsApi: settings.useMeetingsApi ?? EcsConfigFallback.useMeetingsApi,
                        maxRetries: settings.maxRetries ?? EcsConfigFallback.maxRetries,
                        useNewMobileJoinFlow: settings.useMeetingsApi ?? EcsConfigFallback.useNewMobileJoinFlow,
                        allowDesktopSafariGuestFlow: settings.allowDesktopSafariGuestFlow ?? EcsConfigFallback.allowDesktopSafariGuestFlow,
                        useJoinThread: settings.useJoinThread ?? EcsConfigFallback.useJoinThread,
                    },
                },
            });
        }, EcsConfigFallback);
    }

    private _determineIsItUnifiedView(unifiedViewEcsSettings: boolean) {
        return (unifiedViewEcsSettings && deviceType === DeviceTypes.Desktop);
    }

    private _canUseSilentLogin() {
        if (this.props.entryPoint === EntryPoint.Meetings && !canJoinOnWeb) {
            return false;
        }

        return this.props.entryPoint !== EntryPoint.Bots;
    }

    private _getRootView() {
        switch (this.props.entryPoint) {
            case EntryPoint.Meetings:
                return <MeetingsRootView
                    loading={ this.state.loadingTranslations || this.state.loadingEcs }
                    userContext={ this.state.userContext }
                    ecsSettings={ this.state.ecsContext.settings }
                    country={this.props.country} />;
            case EntryPoint.Invites:
                return <InvitesRootView loading={ this.state.loadingTranslations } />;
            case EntryPoint.Bots:
                return <BotsRootView loading={ this.state.loadingTranslations } skypetoken={ this.props.skypetoken } />;
            case EntryPoint.Channels:
                return <ChannelsRootView loading={ this.state.loadingTranslations } />;
            case EntryPoint.Do:
                return <DoRootView loading={ this.state.loadingTranslations } />;
            case EntryPoint.Profiles:
                return <ProfilesRootView loading={ this.state.loadingTranslations } />;
        }
    }

    private _switchLanguage = (language: string) => {
        CookieHelper.setCookieValue(this.state.configContext.cookies.skype, language, 'LC');
        window.location.reload();
    }

    private _announce = (message: AriaMessage) => {
        this.setState((prevState) => {
            const { ariaContext } = prevState;
            ariaContext.message = message;
            return { ariaContext };
        });
    }

    private _setErrorContext = (error: DisplayableError) => {
        this.setState((prevState) => {
            const { errorContext } = prevState;
            errorContext.error = error;
            return {
                errorContext,
            };
        });
    }

    private _clearErrorContext = () => {
        this.setState((prevState) => {
            const { errorContext } = prevState;
            errorContext.error = undefined;
            return {
                errorContext,
            };
        });
    }

    private _getProfileAsync = (loginResult: SilentLoginResult | undefined): SyncTasks.Promise<UserContext> => {
        if (!loginResult || !loginResult.skypetoken) {
            return SyncTasks.Resolved<UserContext>({
                profile: undefined,
                skypetoken: undefined,
                expiration: undefined,
                isLoginInProgress: false,
            });
        }

        return ProfileRestClient.getUserProfileInfo(loginResult.skypetoken)
            .then((profile) => {
                const userContext: UserContext = {
                    profile,
                    skypetoken: loginResult.skypetoken,
                    expiration: loginResult.expiration,
                    isLoginInProgress: false,
                };

                logger.initCommonProperty({ key: CommonProperty.SkypeId, value: profile.username, piiKind: AWTPiiKind.Identity });
                return userContext;
            });
    }
}
