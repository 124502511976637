import config from 'config';
import { OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { logger } from '../../helpers/LoggerHelper';
import { ALauncher } from '../common/ALauncher';

interface BotLauncherProps {
    botSkypeId: string;
}

export class BotLauncher extends ALauncher<BotLauncherProps> {

    protected _getLauncherUrl() {
        const sessionId = logger.getCorrelationId();
        if (os === OperatingSystems.Android) {
            return config.urls.botLauncher.intentUri(this.props.botSkypeId, sessionId, 'launcher', config.urls.app.download);
        }
        return config.urls.botLauncher.skypeUri(this.props.botSkypeId, sessionId, 'launcher');
    }

    protected _getLoggerAction() {
        return 'bot-launch';
    }
}
