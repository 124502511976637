import * as React from 'react';
import { AriaContextConsumer } from '../../contexts/AriaContext';
import { ProfilesContext, ProfilesContextProvider } from '../../contexts/profiles/ProfilesContext';
import { displayableErrors } from '../../helpers/ErrorHelper';
import { CommonProperty, logger } from '../../helpers/LoggerHelper';
import { ARootView, ARootViewProps } from '../common/ARootView';
import { StackNavigationView } from '../common/StackNavigationView';
import { Launcher } from './Launcher';
import { ProfileView } from './ProfileView';

export interface RootViewState {
    launcherKey: number;
    profilesContext: ProfilesContext;
    error?: Error | undefined;
}

export class RootView extends ARootView<ARootViewProps, RootViewState> {

    static getSkypeIdFromUrl() {
        const pathName = window.location.pathname.replace(/\/+$/, '');
        const pathParts = pathName.split('/');

        return pathParts.pop();
    }

    constructor(props: ARootViewProps) {
        super(props);

        const skypeId = RootView.getSkypeIdFromUrl();
        if (!skypeId) {
            throw displayableErrors.Generic;
        }

        logger.initCommonProperty({
            key: CommonProperty.Profile,
            value: skypeId,
        });

        this.state = {
            launcherKey: 0,
            profilesContext: { skypeId },
        };
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        return <ProfilesContextProvider value={ this.state.profilesContext }>
            { !!this.state.launcherKey && <Launcher key={ this.state.launcherKey } profile={ this.state.profilesContext } /> }
            <AriaContextConsumer>{ (ariaContext) =>
                <StackNavigationView ariaContext={ ariaContext } loading={ this.props.loading } initialView={ <ProfileView onLaunchClick={ this._onLaunchClick } /> }
                    initialViewProps={ { eventViewName: 'profile-view' } } />
            }</AriaContextConsumer>
        </ProfilesContextProvider>;
    }

    private _onLaunchClick = () => {
        this.setState((oldState) => {
            return { launcherKey: oldState.launcherKey + 1 };
        });
    }
}
