export interface OffSkypeInviteServiceModel {
    readonly clientSource: string;
    readonly inviterName: string;
    readonly inviterSkypeId: string;
    readonly longId: string;
}

export interface RawOffSkypeInviteServiceModel {
    readonly ClientSource: string;
    readonly InviterName: string;
    readonly InviterSkypeId: string;
    readonly LongId: string;
}

export function transform(model: RawOffSkypeInviteServiceModel): OffSkypeInviteServiceModel {
    return {
        clientSource: model.ClientSource,
        inviterName: model.InviterName,
        inviterSkypeId: model.InviterSkypeId,
        longId: model.LongId,
    };
}
