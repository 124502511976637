import config from 'config';
import * as SyncTasks from 'synctasks';
import { CaptchaSolution } from '../components/common/Captcha';
import { ConversationServiceModel } from '../models/ConversationServiceModel';
import { GuestUserServiceModel } from '../models/GuestUserServiceModel';
import { OffSkypeInviteServiceModel, RawOffSkypeInviteServiceModel, transform as transformOffNetwork } from '../models/OffSkypeInviteServiceModel';
import { BaseRestClient } from './BaseRestClient';

export interface GuestJoinPayloadHuddleApi {
    captcha?: CaptchaSolution;
    flowId: string;
    name: string;
    threadId: string;
}

export interface ConversationPayload {
    shortId: string;
    type: string;
}

export interface OffSkypeInvitePayload {
    shortId: string;
    type: string;
}

export interface RawConversationServiceModel {
    readonly FlowId: string;
    readonly Id: string;
    readonly Resource: string;
    readonly Action: string;
}

function transform(model: RawConversationServiceModel): ConversationServiceModel {
    return {
        action: model.Action,
        flowId: model.FlowId,
        id: model.Id,
        resource: model.Resource,
    };
}

class HuddleApiClient extends BaseRestClient {
    joinAsGuest(payload: GuestJoinPayloadHuddleApi): SyncTasks.Promise<GuestUserServiceModel> {
        return this.performApiPost<GuestUserServiceModel>(
            '/v1/users/guests',
            payload,
            {
                eventProperties: {
                    customPropertiesProvider: () => {
                        return {
                            includesCaptcha: !!payload.captcha,
                        };
                    },
                },
            });
    }

    getConversation(payload: ConversationPayload): SyncTasks.Promise<ConversationServiceModel> {
        return this.performApiPost<RawConversationServiceModel>('/v2/conversation/', payload).then(transform);
    }

    getOffSkypeInvite(payload: OffSkypeInvitePayload): SyncTasks.Promise<OffSkypeInviteServiceModel> {
        return this.performApiPost<RawOffSkypeInviteServiceModel>('/v2/offskypeinvites', payload).then(transformOffNetwork);
    }
}

export default new HuddleApiClient(config.urls.api.huddle);
