import config from 'config';
import * as React from 'react';
import { UrlHelper } from 'src/ts/helpers/UrlHelper';
import { EcsContextConsumer } from '../../../contexts/EcsContext';
import { MeetingContextConsumer } from '../../../contexts/meetings/MeetingContext';
import { CookieHelper } from '../../../helpers/CookieHelper';
import { getRedirectSubdomain } from '../../../helpers/SafariRedirectHelper';

export interface GuestRedirectProps {
    username: string;
    skypetoken: string;
}

export class GuestRedirect extends React.PureComponent<GuestRedirectProps, React.StatelessComponent> {

    render() {
        return <MeetingContextConsumer>{ (meetingContext) => meetingContext && meetingContext.conversation &&
            <EcsContextConsumer>{ (ecsContext) =>
                <form
                    ref={ this._autoSubmit }
                    method='POST'
                    action={ UrlHelper.getWebClientUrlWithCorrelationId({ threadId: meetingContext.conversation.resource }, getRedirectSubdomain(ecsContext.settings)) }
                    style={ { display: 'none' } }
                >
                    <input type='hidden' name='skypetoken' value={ this.props.skypetoken } />
                    <input type='hidden' name='expires_in' value={ 24 * 3600 } />
                    <input type='hidden' name='state' value={ CookieHelper.getCookie(config.cookies.csrf).singleValue } />
                    <input type='hidden' name='skypeid' value={ this.props.username } />
                </form>
            }</EcsContextConsumer>
        }</MeetingContextConsumer>;
    }

    private _autoSubmit = (instance: HTMLFormElement | null) => {
        if (instance) {
            instance.submit();
        }
    }
}
