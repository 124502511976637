import { Animation, Provider, ProviderConsumer, ThemePrepared } from '@stardust-ui/react';
import * as React from 'react';

const spinner = {
    keyframe: {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
    duration: '5s',
    iterationCount: 'infinite',
};

export interface SpinnerProps {
    style?: React.CSSProperties;
}

// tslint:disable:max-line-length
export class Spinner extends React.PureComponent<SpinnerProps, React.StatelessComponent> {

    renderThemeAware = (theme: ThemePrepared) => {
        return <div className='spinnerContainer' style={ this.props.style }>
            <Animation name='spinner' duration='1s'>
                <svg width='100%' height='100%' viewBox='0 0 64 64' version='1.1' xmlns='http://www.w3.org/2000/svg'>
                    <g fill='none' fillRule='evenodd' strokeLinecap='round'>
                        <g stroke={ theme.siteVariables.brand } strokeWidth='2.5'>
                            <path d='M 32 3 C 48.01629 3 61 15.983714 61 32 C 61 48.01629 48.01629 61 32 61 C 15.983714 61 3 48.01629 3 32 C 3 23.706 6.482071 16.226071 12.0625 10.941857' />
                        </g>
                    </g>
                </svg>
            </Animation>
        </div>;
    }

    render() {
        const theme = {
            animations: {
                spinner,
            },
        };

        return <Provider theme={ theme }>
            <ProviderConsumer render={ this.renderThemeAware } />
        </Provider>;
    }
}
