import { CookieConfig } from 'config';
import { Dictionary } from '../models/SharedTypes';

const defaultValueSeparator = ':';

export class CookieHelper {

    /**
     * Get the cookie
     *
     * param   {string} name
     *
     * @return {object}
     */
    static getCookie(config: CookieConfig) {
        return (CookieHelper.hasCookie(config) === true) ? CookieHelper.parse()[config.name] : {};
    }

    /**
     * Set the cookie
     *
     * @param {string}
     *
     * @return void
     */
    static setCookie(config: CookieConfig, cookieValuesObject: object ) {
        const now = Math.round(Date.now() / 1000); // seconds
        let value = cookieValuesObject as any;

        // We have to add TS if cookie does not exist
        if (CookieHelper.hasCookie(config) === false) {
            value.TS = now;
        }

        // we need to add modification timestamp
        value.TM = now;

        value = CookieHelper.cookieObjectToString(value);

        const options = [];
        if (config.domain) {
            options.push('domain=' + config.domain);
        }
        if (config.path) {
            options.push('path=' + config.path);
        }
        if (config.maxAge) {
            options.push('expires=' + CookieHelper.getExpirationDate(config.maxAge).toUTCString());
        }

        const optionsString = options.length ? ';' + options.join(';') : '';

        document.cookie = config.name + '=' + value + optionsString;
    }

    /**
     * If cookie is present
     *
     * @return boolean
     */
    static hasCookie(config: CookieConfig) {
        return CookieHelper.parse().hasOwnProperty(config.name);
    }

    /**
     * Get cookie value
     *
     * @param  {string} key
     *
     * @return {string} key value
     */
    static getCookieValue(config: CookieConfig, key: string = 'singleValue') {
        const params = CookieHelper.getCookie(config);

        return params && params[key] ? params[key] : undefined;
    }

    /**
     * Set cookie value
     *
     * @param {string} key
     * @param {string} value
     *
     */
    static setCookieValue(config: CookieConfig, value: string, key = 'singleValue') {
        const params = CookieHelper.getCookie(config);
        params[config.key || key] = value;

        CookieHelper.setCookie(config, params);
    }

    /**
     *
     */
    static removeCookie(config: CookieConfig) {
        if (!CookieHelper.hasCookie(config)) {
            return;
        }

        CookieHelper.setCookie({ name: config.name }, {});
    }

    /**
     * Parse document.cookie to JSON
     *
     * @example {'SC': 'LC=en:CCY=EUR', 'SWA':'webapp'}
     *
     *
     * @return  {object}
     */
    static parse() {
        if (!document.cookie) {
            return {};
        }

        const cookies: any = {};
        document.cookie.split(';').forEach((cookie) => {
            const separatorPos = cookie.indexOf('=');

            const key = cookie.substring(0, separatorPos).trim();
            const value = cookie.substring(separatorPos + 1).trim();
            cookies[key] = CookieHelper.parseCookieValues(value);
        });

        return cookies;
    }
    /**
     * Turns object to string
     *
     * @param  {object} cookieObject
     * @param  {string} cookieSeparator
     *
     * @return {string}
     */
    private static cookieObjectToString(cookieObject: object, cookieSeparator = defaultValueSeparator) {
        const cookieObjectKeys = Object.keys(cookieObject);
        const cookieValue: string[] = [];

        if (cookieObject.hasOwnProperty('singleValue')) {
            return (cookieObject as any).singleValue;
        }

        cookieObjectKeys.forEach((cookieObjectKey) => {
            cookieValue.push(cookieObjectKey + '=' + (cookieObject as any)[cookieObjectKey]);
        });

        return cookieValue.join(cookieSeparator);
    }

    /**
     * Parse cookie values into JSON
     *
     * @example {'LC':'en','CCY':'EUR'}
     *
     * @param   {string} cookieSeparator
     *
     * @return  {object}
     */
    private static parseCookieValues(cookieValue: string, cookieSeparator = defaultValueSeparator) {
        const params: Dictionary<string> = {};

        cookieValue.split(cookieSeparator).forEach((splitCookieValue) => {
            let splitParams: string[];
            if (splitCookieValue.indexOf('=') !== -1) {
                splitParams = splitCookieValue.split('=');
            } else {
                splitParams = ['singleValue', splitCookieValue];
            }

            params[splitParams[0].trim()] = splitParams[1].trim();
        });
        return params;
    }

    /**
     * Get the date object for expiration
     *
     * @param  {int} seconds Number of seconds to add
     *
     * @return {Date}
     */
    private static getExpirationDate(seconds: number) {
        return new Date((seconds * 1000) + Date.now());
    }
}
