import Config from 'config';
import { logger } from '../helpers/LoggerHelper';

export const triggerFallbackFlow = (error: Error, reason: string) => {
    logger.init(Config.logging.ariaTenant, 'meetnow_edge_');
    logger.meetNowFallbackFlow({ message: error.message, reason });

    showErrorMessage();
    handleReloadButton();
    handleNavigateToWebClientButton();
};

const showErrorMessage = () => {
    const pageTitle = document.querySelector<HTMLDivElement>('.splashScreen');
    if (pageTitle) {
        pageTitle.style.display = 'none';
    }

    const fallbackSection = document.getElementById('fallbackSection');
    if (fallbackSection) {
        fallbackSection.style.display = 'block';
    }
};

const handleReloadButton = () => {
    const reloadPageButton = document.getElementById('reloadPageButton');
    if (reloadPageButton) {
        reloadPageButton.addEventListener('click', (event) => {
            event.preventDefault();
            logger.action({ name: 'reloadFallback' });
            window.location.reload();
        });
    }
};

const handleNavigateToWebClientButton = () => {
    const goToSkypeButton = document.getElementById('goToSkypeButton');
    if (goToSkypeButton) {
        goToSkypeButton.addEventListener('click', (event) => {
            event.preventDefault();
            logger.action({ name: 'goToSkypeFallback' });
            window.location.href = Config.urls.app.s4lWeb({});
        });
    }
};
