import * as React from 'react';
import { AriaContextConsumer } from 'src/ts/contexts/AriaContext';
import { ChannelsContext, ChannelsContextProvider } from 'src/ts/contexts/channels/ChannelsContext';
import ChannelsApiClient from 'src/ts/rest/ChannelsApiClient';
import { deviceType, DeviceTypes } from '../../helpers/EnvironmentHelper';
import { displayableErrors } from '../../helpers/ErrorHelper';
import { CommonProperty, logger } from '../../helpers/LoggerHelper';
import { ARootView, ARootViewProps } from '../common/ARootView';
import { StackNavigationView } from '../common/StackNavigationView';
import { ChannelLauncher } from './ChannelLauncher';
import { ChannelView } from './ChannelView';

export interface RootViewState {
    launcherKey: number;
    channelContext?: ChannelsContext;
    error?: Error | undefined;
}

export class RootView extends ARootView<ARootViewProps, RootViewState> {
    // Setup according to the logic in url shortener service and current configuration in its storage.
    private readonly shortIdLength = 12;
    private isComponentMounted = false;

    constructor(props: ARootViewProps) {
        super(props);
        this.state = {
            launcherKey: deviceType === DeviceTypes.Desktop ? 1 : 0,
        };
    }

    componentDidMount() {
        this.isComponentMounted = true;
        const shortId = this._getShortIdFromUrl();
        if (!shortId || shortId.length < this.shortIdLength) {
            throw displayableErrors.ChannelNotFound;
        }

        ChannelsApiClient.getChannelInfo(shortId)
        .then((channel) => {
            this._safeSetState({
                channelContext: {
                    channelId: shortId,
                    channelInfo: channel,
                },
            });
            logger.initCommonProperty({ key: CommonProperty.ChannelId, value: shortId });
        }).catch((error: Error) => {
            this._safeSetState({ error });
        });
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        const channelId = this.state.channelContext?.channelInfo ? this.state.channelContext.channelInfo.id : undefined;
        return <ChannelsContextProvider value={ this.state.channelContext }>
            { !!this.state.launcherKey && channelId && <ChannelLauncher key={ this.state.launcherKey } channelId={ channelId } /> }
            <AriaContextConsumer>{ (ariaContext) =>
                <StackNavigationView ariaContext={ ariaContext } loading={ this.props.loading } initialView={ <ChannelView onLaunchClick={ this._onLaunchClick } /> }
                    initialViewProps={ { eventViewName: 'join-channel-view' } } />
            }</AriaContextConsumer>
        </ChannelsContextProvider>;
    }

    private _getShortIdFromUrl() {
        const pathName = window.location.pathname.replace(/\/+$/, '');
        const pathParts = pathName.split('/');

        // Remove any additional charachters appended to the shortId.
        return pathParts.pop()?.substring(0, this.shortIdLength);
    }

    private _onLaunchClick = () => {
        this.setState((oldState) => {
            return { launcherKey: oldState.launcherKey + 1 };
        });
    }

    private _safeSetState(state: Partial<RootViewState>) {
        if (!this.isComponentMounted) {
            return;
        }
        this.setState(state as RootViewState);
    }
}
