export interface SkypeTokenContentHeader {
    alg: string;
    kid: string;
    typ: string;
}

export interface SkypeTokenContentPayload {
    aat: number;
    cid: string;
    csi: string;
    exp: number;
    iat: number;
    scp: number;
    skypeid: string;
}

export interface SkypeTokenContent {
    header: SkypeTokenContentHeader;
    payload: SkypeTokenContentPayload;
    signature: string;
}

export function parseSkypeToken(token: string | undefined): SkypeTokenContent | undefined {
    if (!token) {
        return;
    }

    const parts = token.split('.');
    if (parts.length !== 3) {
        return;
    }

    try {
        const header: SkypeTokenContentHeader = JSON.parse(atob(parts[0]));
        const payload: SkypeTokenContentPayload = JSON.parse(atob(parts[1]));
        const signature = parts[2];

        if (!payload.skypeid) {
            return;
        }

        return { header, payload, signature };
    } catch (e) {
        return;
    }
}

export function getSkypeIdFromSkypeToken(token: string | undefined): string | undefined {
    const tokenContent = parseSkypeToken(token);
    if (tokenContent) {
        return tokenContent.payload.skypeid;
    }

    return;
}

export function getMsaCidHexFromSkypeToken(token: string | undefined): string | undefined {
    const tokenContent = parseSkypeToken(token);
    if (tokenContent && tokenContent.payload && tokenContent.payload.cid) {
        return tokenContent.payload.cid.toUpperCase();
    }

    return;
}
