import { localizer } from '@skype/bew-localization';
import { Image, Text } from '@stardust-ui/react';
import * as React from 'react';
import { ConfigContextConsumer } from '../../contexts/ConfigContext';
import { canJoinOnWeb, deviceType, DeviceTypes, OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { defaultDimension, Dimension } from '../../models/SharedTypes';

interface HeaderState {
    browserSupportDimension: Dimension;
}

export class Header extends React.PureComponent<{}, HeaderState> {

    private _browserSupportElement?: HTMLDivElement;
    private _debounce: number;

    constructor(props: {}) {
        super(props);
        this.state = {
            browserSupportDimension: defaultDimension,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this._updateDimensionDebouncer);
        this._updateDimension();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._updateDimensionDebouncer);
        delete this._browserSupportElement;
    }

    renderJoinOnWebDisclaimer(): React.ReactNode {
        // Join on web only available in desktop
        if (deviceType !== DeviceTypes.Desktop || canJoinOnWeb) {
            return null;
        }

        let param;
        switch (os) {
            case OperatingSystems.MacOS:
                param = { list_supported_browser: localizer.getString('Meetings.txt_list_supported_browser_macos') };
                break;

            case OperatingSystems.Win:
            case OperatingSystems.WinRT:
                param = { list_supported_browser: localizer.getString('Meetings.txt_list_supported_browser_win') };
                break;

            case OperatingSystems.Linux:
            default:
                param = { list_supported_browser: localizer.getString('Meetings.txt_list_supported_browser_linux') };
                break;
        }

        return <div className='outer' style={ { minHeight: this.state.browserSupportDimension.height, width: '100%' } }>
            <div key='browser-compat' className='header dark' ref={ this._setBrowserSupport } style={ { padding: '1rem 2rem' } }>
                <ConfigContextConsumer>{ (configContext) =>
                    <Image style={ { width: '1.5rem', height: '1.5rem' } }
                        src={ `${ configContext.basePath }/images/icons/warning.svg` }
                        onLoad={ this._updateDimensionDebouncer } />
                }</ConfigContextConsumer>
                <Text size='medium' styles={ { alignSelf: 'flex-end', marginLeft: '1rem' } } weight='semibold'>
                    { localizer.getString('Meetings.txt_banner_unsupported_browser', param) }
                </Text>
            </div>
        </div>;
    }

    render() {
        return <>
            { this.renderJoinOnWebDisclaimer() }
        </>;
    }

    private _updateDimensionDebouncer = () => {
        if (this._debounce) {
            window.clearTimeout(this._debounce);
        }

        this._debounce = window.setTimeout(() => {
            this._updateDimension();
        }, 100);
    }

    private _updateDimension = () => {
        this.setState(() => {
            let browserSupportDimension: Dimension = defaultDimension;
            if (this._browserSupportElement) {
                browserSupportDimension = {
                    height: this._browserSupportElement.clientHeight,
                    width: this._browserSupportElement.clientWidth,
                };
            }
            return {
                browserSupportDimension,
            };
        });
    }

    private _setBrowserSupport = (element?: HTMLDivElement | null) => {
        if (element) {
            this._browserSupportElement = element;
        }
    }
}
