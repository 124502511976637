import { WebErrorResponse } from 'simplerestclients';

export interface DisplayableError extends Error {
    headerLocString: string;
    descriptionLocString: string;
    pageTitleLocString: string;
    emoticonPath?: string;
}

export const displayableErrors = {
    ShortIdFromUrl: {
        name: 'ShortIdFromUrl',
        message: 'Cannot retrieve the shortId from url.',
        headerLocString: 'hdr_not_found_page_oops',
        descriptionLocString: 'txt_not_found_page_redirect',
        pageTitleLocString: 'hdr_not_found_page_title',
        emoticonPath: '/images/emoticons/error.png',
    } as DisplayableError,
    InviteIdFromUrl: {
        name: 'InviteIdFromUrl',
        message: 'Cannot retrieve the inviteId from url.',
        headerLocString: 'hdr_not_found_page_oops',
        descriptionLocString: 'txt_not_found_page_redirect',
        pageTitleLocString: 'hdr_not_found_page_title',
        emoticonPath: '/images/emoticons/error.png',
    } as DisplayableError,
    TooManyUsers: {
        name: 'TooManyUsers',
        message: 'TooManyUsers',
        headerLocString: 'hdr_toomanyusers_page_oops',
        descriptionLocString: 'txt_toomanyusers_page_explanation',
        emoticonPath: '/images/emoticons/busy.png',
        pageTitleLocString: 'hdr_toomanyusers_page_title',
    } as DisplayableError,
    Busy: {
        name: 'Busy',
        message: 'Busy',
        headerLocString: 'hdr_busy_page_oops',
        descriptionLocString: 'txt_busy_page_explanation',
        pageTitleLocString: 'hdr_busy_page_title',
        emoticonPath: '/images/emoticons/busy.png',
    } as DisplayableError,
    InviteNotFound: {
        name: 'InviteNotFound',
        message: 'InviteNotFound',
        headerLocString: 'hdr_not_found_page_oops',
        descriptionLocString: 'txt_not_found_offskypeinvite_page_redirect',
        pageTitleLocString: 'hdr_not_found_page_title',
        emoticonPath: '/images/emoticons/busy.png',
    } as DisplayableError,
    JoinDisabled: {
        name: 'JoinDisabled',
        message: 'JoinDisabled',
        headerLocString: 'hdr_joindisabled_page_oops',
        descriptionLocString: 'txt_joindisabled_page_explanation',
        pageTitleLocString: 'hdr_joindisabled_page_title',
        emoticonPath: '/images/emoticons/busy.png',
    } as DisplayableError,
    Generic: {
        name: 'Generic',
        message: 'Generic error',
        headerLocString: 'hdr_error_page_oops',
        descriptionLocString: 'txt_error_page_explanation',
        pageTitleLocString: 'hdr_error_page_title',
        emoticonPath: '/images/emoticons/error.png',
    } as DisplayableError,
    BotNotFound: {
        name: 'BotIdFromUrl',
        message: 'Cannot retrieve the bot id from url.',
        headerLocString: 'hdr_not_found_page_oops',
        descriptionLocString: 'bot_not_found',
        pageTitleLocString: 'hdr_not_found_page_title',
        emoticonPath: '/images/emoticons/error.png',
    } as DisplayableError,
    BotTooManyContacts: {
        name: 'BotTooManyContacts',
        message: 'BotTooManyContacts',
        headerLocString: 'bot_problem_page_oops',
        descriptionLocString: 'bot_too_many_contacts_page_redirect',
        pageTitleLocString: 'bot_too_many_contacts_page_title',
        emoticonPath: '/images/emoticons/error.png',
    } as DisplayableError,
    ChannelNotFound: {
        name: 'ChannelIdFromUrl',
        message: 'Cannot retrieve the channel short id from url.',
        headerLocString: 'hdr_not_found_page_oops',
        descriptionLocString: 'channels.channel_not_found',
        pageTitleLocString: 'hdr_not_found_page_title',
        emoticonPath: '/images/emoticons/error.png',
    } as DisplayableError,
};

export class ErrorHelper {

    static convertToGenericDisplayableError(error: Error): DisplayableError {
        return {
            ...error,
            headerLocString: 'hdr_error_page_oops',
            descriptionLocString: 'txt_error_page_explanation',
            pageTitleLocString: 'hdr_error_page_title',
            emoticonPath: '/images/emoticons/error.png',
        };
    }

    static isDisplayableError(arg: any): arg is DisplayableError {
        return (arg as DisplayableError).headerLocString !== undefined && (arg as DisplayableError).descriptionLocString !== undefined;
    }

    static enrichErrorFromWebResponse(displayableError: DisplayableError, webResponse: WebErrorResponse, description?: string): DisplayableError {
        return {
            ...displayableError,
            ...ErrorHelper.getErrorFromWebErrorResponse(webResponse, description),
        };
    }

    private static getErrorFromWebErrorResponse(response: WebErrorResponse, description?: string): Error {
        return {
            name: description || `Request failed`,
            message: `${response.method} request failed: ${response.statusCode} ${response.statusText}`,
        };
    }
}
