export interface Dictionary<T> {
    [index: string]: T;
}

export enum Themes {
    Teams = 'teams',
    Light = 'light',
}

export enum AppIds {
    Meetings = 'wl',
    OffSkypeInvites = 'oi',
}

export enum Actions {
    Chat = 'chat',
    Call = 'call',
    MeetNowJoin = 'meetnowjoin',
}

export interface Dimension { width: number; height: number; }
export const defaultDimension: Dimension = { width: 0, height: 0 };
export const mainContentId = 'mainContent';
export const COUNTRY_CHINA = 'cn';
