import * as React from 'react';
import { ChannelInfoServiceModel } from '../../models/ChannelServiceModel';

export interface ChannelsContext {
    channelInfo: ChannelInfoServiceModel;
    // Short id
    channelId: string;
}

// Default context does not mean much sense here
const context = React.createContext<ChannelsContext | undefined>(undefined);

export const ChannelsContextProvider = context.Provider;

export const ChannelsContextConsumer = context.Consumer;
