import config from 'config';
import * as React from 'react';
import { DoContext, DoContextProvider } from 'src/ts/contexts/do/DoContext';
import { CookieHelper } from 'src/ts/helpers/CookieHelper';
import { displayableErrors } from 'src/ts/helpers/ErrorHelper';
import { Actions } from 'src/ts/models/SharedTypes';
import { AriaContextConsumer } from '../../contexts/AriaContext';
import { deviceType, DeviceTypes } from '../../helpers/EnvironmentHelper';
import { CommonProperty, logger } from '../../helpers/LoggerHelper';
import { UrlHelper } from '../../helpers/UrlHelper';
import { ARootView, ARootViewProps } from '../common/ARootView';
import { StackNavigationView } from '../common/StackNavigationView';
import { DoView } from './DoView';
import { Launcher } from './Launcher';

export interface RootViewState {
    launcherKey: number;
    doContext: DoContext;
    error?: Error | undefined;
}

export class RootView extends ARootView<ARootViewProps, RootViewState> {

    static getParamsFromUrl() {
        const { recipient, action, correlationId, source } = UrlHelper.getQueryParameters();

        logger.initCommonProperty({
            key: CommonProperty.Source,
            value: source as string,
        });
        logger.initCommonProperty({
            key: CommonProperty.CorrelationId,
            value: correlationId as string,
        });

        // S4L handle all of 'skype:8:myskypeid', 'skype:myskypeid', 'skype:19:mythreadid'
        // Thread will only work if '19:' prefix is provided.
        // Original spec does not say anything about ThreadId #564363, and also the page we are rewriting does not prepend '19:' suffix
        // Also, original implementation does not populate utm properties as specified in PBI. We also drop it because there is no handling on S4L level.
        return {
            skypeId: recipient,
            action: !action || action.toString().toLowerCase() === 'chat' ? Actions.Chat : Actions.Call,
        } as DoContext;
    }

    constructor(props: ARootViewProps) {
        super(props);
        this.state = {
            launcherKey: 0,
            doContext: RootView.getParamsFromUrl(),
        };
    }

    componentDidMount() {
        const params = RootView.getParamsFromUrl();
        if (!params.action || !params.skypeId) {
            throw displayableErrors.Generic;
        }

        if (deviceType === DeviceTypes.Desktop) {
            logger.action({ name: 'join-do' });

            const webClientUrl = UrlHelper.getWebClientUrlWithCorrelationId({ threadId: params.skypeId });
            window.location.assign(config.urls.app.login(webClientUrl, CookieHelper.getCookie(config.cookies.csrf).singleValue));
        }
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        return <DoContextProvider value={ this.state.doContext }>
            { !!this.state.launcherKey && <Launcher key={ this.state.launcherKey } do={ this.state.doContext } /> }
            <AriaContextConsumer>{ (ariaContext) =>
                <StackNavigationView ariaContext={ ariaContext } loading={ this.props.loading } initialView={ <DoView onLaunchClick={ this._onLaunchClick } /> }
                    initialViewProps={ { eventViewName: 'do-view' } } />
            }</AriaContextConsumer>
        </DoContextProvider>;
    }

    private _onLaunchClick = () => {
        this.setState((oldState) => {
            return { launcherKey: oldState.launcherKey + 1 };
        });
    }
}
