import noop from 'lodash/noop';
import * as React from 'react';
import { DisplayableError } from '../helpers/ErrorHelper';

export interface ErrorContext {
    error: DisplayableError | undefined;
    setError: (error: DisplayableError) => void;
    clear: () => void;
}

// Default context does not mean much sense here
const context = React.createContext<ErrorContext>({
    error: undefined,
    setError: noop,
    clear: noop,
});

export const ErrorContextProvider = context.Provider;

export const ErrorContextConsumer = context.Consumer;
