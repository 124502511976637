import { AWTPiiKind, AWTPropertyType } from '@aria/webjs-sdk';
import { localizer } from '@skype/bew-localization';
import curry from 'lodash/curry';
import { WebErrorResponse } from 'simplerestclients';
import * as SyncTasks from 'synctasks';
import config from '../config/BaseConfig';
import { canJoinOnWeb } from '../helpers/EnvironmentHelper';
import { DisplayableError, displayableErrors, ErrorHelper } from '../helpers/ErrorHelper';
import { CommonProperty, logger } from '../helpers/LoggerHelper';
import { getSkypeIdFromSkypeToken } from '../helpers/SkypeTokenHelper';
import { buildRedirectQueryParameters, getShortIdFromJoinLink, getThreadId, redirectToSkypeClient } from '../meetnow/MeetNowEdgeHandler';
import { IMeetNowSettings } from '../meetnow/MeetNowSettings';
import { ConversationServiceModel } from '../models/ConversationServiceModel';
import { GuestJoinPayloadMeetingsApi, GuestUserServiceModel, WebClientRedirectParams } from '../models/GuestUserServiceModel';
import MeetingsApiClient from '../rest/MeetingsApiClient';

const joinLinkRegex = /^https:\/\/join\.skype\.com\/[a-zA-Z0-9]{7,20}$/;

export const handleJoin = (
    settings: IMeetNowSettings,
    conversationServiceModel: ConversationServiceModel,
    skypeToken?: string,
    isNewJoinFlowFromJoinLink = false) => {
    logger.initCommonProperty({
        key: CommonProperty.ThreadId,
        value: conversationServiceModel.resource,
        piiKind: AWTPiiKind.GenericData,
        type: AWTPropertyType.String,
    });

    if (conversationServiceModel.shortId) {
        logger.initCommonProperty({
            key: CommonProperty.ShortId,
            value: conversationServiceModel.shortId,
            piiKind: AWTPiiKind.GenericData,
            type: AWTPropertyType.String,
        });
    }

    // For unsupported devices we navigate users to regular join flow where they an an opportunity to launch a desktop client or download it
    if (!canJoinOnWeb) {
        logger.action({ name: 'redirectToJoinPageUnsupportedDevice' });
        window.location.href =
            `/${ conversationServiceModel.shortId }?${
                buildRedirectQueryParameters(
                    settings.MeetNowEdgeAllowedQueryParameters,
                    logger.getCorrelationId(),
                    isNewJoinFlowFromJoinLink)
            }`;
        return SyncTasks.Resolved();
    }

    if (skypeToken) {
        const redirectParams: WebClientRedirectParams = {
            threadId: conversationServiceModel.resource,
            skypetoken: skypeToken,
            shortId: conversationServiceModel.shortId,
            skypeId: getSkypeIdFromSkypeToken(skypeToken) || '',
        };

        redirectToSkypeClient(settings, 'MeetNowJoin', 'joinmeetnow', isNewJoinFlowFromJoinLink, redirectParams);
        return SyncTasks.Resolved();
    } else {
        return addUserToMeeting(settings, conversationServiceModel)
            .then(redirectToSkypeClient(settings, 'MeetNowJoin', 'joinmeetnow', isNewJoinFlowFromJoinLink));
    }
};

export const addUserToMeeting = curry((settings: IMeetNowSettings, conversationServiceModel: ConversationServiceModel) => {
    if (!conversationServiceModel.shortId) {
        throw new Error('ShortId not provided to addUserToMeeting function');
    }

    const localizedGuestDefaultName = localizer.getString('Meetings.txt_guest_user_name') ?? 'Guest user';
    const params: GuestJoinPayloadMeetingsApi = {
        displayName: localizedGuestDefaultName,
        threadId: conversationServiceModel.resource,
        flowId: conversationServiceModel.flowId,
        shortId: conversationServiceModel.shortId,
    };

    return MeetingsApiClient
        .joinAsGuest<GuestJoinPayloadMeetingsApi>(params, settings.MeetNowEdgeNumberOfFetchRetries, true)
        .then((guestUserServiceModel: GuestUserServiceModel) => ({
            threadId: conversationServiceModel.resource,
            shortId: conversationServiceModel.shortId,
            ...guestUserServiceModel,
        }))
        .catch((response: WebErrorResponse<any>) => {
            let error: DisplayableError | undefined;
            const createGuestRequest = 'Create guest user';
            switch (response.statusCode) {
                case 403:
                    error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.JoinDisabled, response, createGuestRequest);
                    break;
                case 404:
                    error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.InviteNotFound, response, createGuestRequest);
                    break;
                case 412:
                    error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.TooManyUsers, response, createGuestRequest);
                    break;
                case 429:
                    error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.TooManyUsers, response, createGuestRequest);
                    break;
                case 500:
                    error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.Generic, response, createGuestRequest);
                    break;
                default:
                    error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.Busy, response, createGuestRequest);
                    break;
            }

            throw error;
        });
});

export const validateJoinLinkOrCode = async (settings: IMeetNowSettings, joinLink: string) => {
    const normalizedJoinLink = normalizeJoinLink(config.urls.app.join, joinLink);

    if (!normalizedJoinLink.match(joinLinkRegex)) {
        return false;
    }

    const conversationServiceModel: ConversationServiceModel = await getThreadId(settings, getShortIdFromJoinLink(normalizedJoinLink));
    if (conversationServiceModel && conversationServiceModel.resource) {
        return conversationServiceModel;
    }

    return false;
};

export const normalizeJoinLink = (joinLinkDomain: string, joinLink: string) => {
    const trimmedJoinLink = joinLink.replace(/(^\s*)|(\s*\.*\s*$)/g, '');

    if (!trimmedJoinLink.startsWith(joinLinkDomain)) {
        return `${ joinLinkDomain }/${ trimmedJoinLink }`;
    }
    return trimmedJoinLink;
};
