import * as React from 'react';
import { deviceType, DeviceTypes } from '../../helpers/EnvironmentHelper';
import { logger } from '../../helpers/LoggerHelper';

interface LauncherState {
    launchUrl: string;
}

export abstract class ALauncher<P> extends React.PureComponent<P, LauncherState> {

    constructor(props: P) {
        super(props);
        this.state = {
            launchUrl: this._getLauncherUrl(),
        };
    }

    componentDidMount() {
        logger.action({ name: this._getLoggerAction() });
        if (deviceType !== DeviceTypes.Desktop) {
            window.location.href = this.state.launchUrl;
        }
    }

    render() {
        return <iframe id='skypeLauncher' style={ { display: 'none' } } src={ this.state.launchUrl }></iframe>;
    }

    protected abstract _getLauncherUrl(): string;
    protected abstract _getLoggerAction(): string;
}
