import { Image, ProviderConsumer, ThemePrepared } from '@stardust-ui/react';
import * as React from 'react';
import { IMeetNowSettings } from 'src/ts/meetnow/MeetNowSettings';
import { AriaContextConsumer } from '../../contexts/AriaContext';
import { ConfigContextConsumer } from '../../contexts/ConfigContext';
import { Footer } from '../common/Footer';
import { NestedStackNavigationView } from '../common/NestedStackNavigationView';
import { UnifiedLoginMethod } from './login/UnifiedLoginMethod';

interface UnifiedViewProps {
    onLaunchClick: () => void;
    allowDesktopSafariGuestFlow: boolean;
    meetNowSettings: IMeetNowSettings;
    country?: string;
}

export class UnifiedView extends React.PureComponent<UnifiedViewProps> {

    renderThemeAware = (_: ThemePrepared) => {
        return <div className='unifiedView'>

            <div className='body'>
                <ConfigContextConsumer>{ (configContext) =>
                    <Image className='logo' src={ `${ configContext.basePath }/images/icons/skype-logo-blue-new.png` } aria-hidden='true' />
                }</ConfigContextConsumer>
                <AriaContextConsumer>{ (ariaContext) =>
                    <NestedStackNavigationView
                        ariaContext={ ariaContext }
                        initialView={
                            <UnifiedLoginMethod
                                country={this.props.country}
                                onLaunchClick={this.props.onLaunchClick}
                                allowDesktopSafariGuestFlow={ this.props.allowDesktopSafariGuestFlow }
                                meetNowSettings={ this.props.meetNowSettings } />
                        }
                        initialViewProps={
                            { eventViewName: 'login-method' }
                        }
                    />
                }</AriaContextConsumer>
            </div>

            <Footer dark={ false } />
        </div>;
    }

    render() {
        return <ProviderConsumer render={ this.renderThemeAware } />;
    }
}
