import config from 'config';
import { OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { logger } from '../../helpers/LoggerHelper';
import { ALauncher } from '../common/ALauncher';

interface ChannelLauncherProps {
    channelId: string;
}

export class ChannelLauncher extends ALauncher<ChannelLauncherProps> {

    protected _getLauncherUrl() {
        const sessionId = logger.getCorrelationId();
        if (os === OperatingSystems.Android) {
            return config.urls.channelLauncher.intentUri(this.props.channelId, sessionId, 'launcher', config.urls.app.download);
        }
        return config.urls.channelLauncher.skypeUri(this.props.channelId, sessionId, 'launcher');
    }

    protected _getLoggerAction() {
        return 'channel-launch';
    }
}
