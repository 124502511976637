import * as React from 'react';
import { StackNavigationContext, StackNavigationContextConsumer } from '../../contexts/StackNavigationContext';
import { StackNavigationProps, StackNavigationView } from './StackNavigationView';

export class NestedStackNavigationView extends React.PureComponent<StackNavigationProps, React.StatelessComponent> {

    render() {
        return <StackNavigationContextConsumer>{ (parentContext) => this._renderNested(parentContext) }</StackNavigationContextConsumer>;
    }
    private _renderNested = (parentContext: StackNavigationContext) => {
        const childProps = { ...this.props, parentContext };
        return React.createElement(StackNavigationView, childProps);
    }
}
