import * as React from 'react';

export interface AriaMessage {
    assertive: boolean;
    text: string;
}

export interface AriaContext {
    message: AriaMessage;
    announce: (message: AriaMessage) => void;
}

// Default context does not mean much sense here
const context = React.createContext<AriaContext>({} as AriaContext);

export const AriaContextProvider = context.Provider;

export const AriaContextConsumer = context.Consumer;
