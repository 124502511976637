import { ThemeInput } from '@stardust-ui/react';

// https://github.com/stardust-ui/react/blob/master/src/themes/teams/colors.ts
const colors = {
    50: '#E7F2FB',
    100: '#B9DAF3',
    200: '#8BC1EB',
    300: '#5CA9E3',
    400: '#2E90DB',
    500: '#0078d4',
    600: '#0063AE',
    700: '#004D87',
    800: '#003761',
    900: '#00213A',
};

// tslint:disable:object-literal-key-quotes
const theme: ThemeInput = {
    siteVariables: { // https://github.com/stardust-ui/react/tree/master/src/themes/teams/siteVariables.ts
        brand: colors[500],
        brand02: colors[900],
        brand04: colors[700],
        brand06: colors[500],
        brand08: colors[300],
        brand12: colors[200],
        brand14: colors[100],
        brand16: colors[50],
        brandIcon: '/images/icons/skype-logo-blue-new.png',
    },
    componentStyles: {
        Button: {
            root: {
                borderRadius: '6rem',
                ':focus': {
                    '::after': {
                        // tslint:disable-next-line:max-line-length
                        borderRadius: '6rem !important', // borderRadius 3px is hardCoded in stardust https://github.com/stardust-ui/react/blob/86e896e8b21ecd22c0fdfc151e6e2562af0d6cfe/src/themes/teams/components/Button/buttonStyles.ts#L115
                    },
                },
            },
        },
    },
};

export default theme;
