import { localizer } from '@skype/bew-localization';
import { Avatar, Button, Header, Image, Text } from '@stardust-ui/react';
import config from 'config';
import * as React from 'react';
import { ChannelsContext } from 'src/ts/contexts/channels/ChannelsContext';
import { logger } from 'src/ts/helpers/LoggerHelper';
import { UrlHelper } from 'src/ts/helpers/UrlHelper';
import { ConfigContextConsumer } from '../../contexts/ConfigContext';
import { CookieHelper } from '../../helpers/CookieHelper';
import { canJoinOnWeb, deviceType, DeviceTypes, OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { Footer } from '../common/Footer';
import { Header as PageHeader } from '../common/Header';
import { Padder } from '../common/Padder';
import { withChannelsContext, WithChannelsContextProps } from './withChannelsContext';

interface ChannelViewProps extends WithChannelsContextProps {
    onLaunchClick: () => void;
}

class ChannelViewKlass extends React.PureComponent<ChannelViewProps, React.StatelessComponent> {
    render() {
        return (
            <div className='channels channelView'>
                <PageHeader />
                <Padder />
                <div className='scrollable-y body'>
                    { this.renderHeader() }
                    { this.renderChannelDetails() }
                    { this.renderControls() }
                    { this.renderMobileBody() }
                    { this.renderDesktopBody() }
                </div>
                <Padder />
                <Footer />
            </div>
        );
    }

    renderHeader() {
        return <Header as='h1' content='Skype' />;
    }

    renderChannelDetails() {
        return (
            <section className='channelInfoContainer'>
                { this.renderAvatar() }
                { this.renderIsCertified()}
                { this.renderTitle() }
                { this.renderFollowers() }
                { this.renderDescription() }
            </section>
        );
    }

    renderAvatar() {
        return this.props.channelContext &&
            <Avatar styles={ { margin: '10px 0px' } } size={ 100 }
                { ...this._getAvatarContent(this.props.channelContext)}
            />;
    }

    renderIsCertified() {
        return (
            this.props.channelContext?.channelInfo.isTrusted &&
            <div>
                <Text size='medium' className='certified' content={ localizer.getString('Channels.skype_certified') }/>
                <ConfigContextConsumer>{ (configContext) =>
                    <Image className='certifiedChannelIcon' src={ `${ configContext.basePath }/images/certified-icon.png` } aria-hidden='true' /> }
                </ConfigContextConsumer>
            </div>
        );
    }

    renderTitle() {
        return this.props.channelContext && <Header as='h2' content={this.props.channelContext.channelInfo.name } />;
    }

    renderFollowers() {
        const formatter = Intl.NumberFormat(localizer.getLocale(), {
            // @ts-ignore
            notation: 'compact',
        });
        const { channelContext } = this.props;
        return channelContext && channelContext.channelInfo.followers > 0 &&
            <Header as='h3' className='followers'
                content={ localizer.get('Channels.followers', { followers_count: <span>{formatter.format(channelContext.channelInfo.followers)}</span>})
            } />;
    }

    renderDescription() {
        return this.props.channelContext && <Text size='medium' content={this.props.channelContext.channelInfo.description } />;
    }

    renderDesktopBody() {
        if (deviceType !== DeviceTypes.Desktop) {
            return null;
        }

        return <Text size={ 'medium' } className='body'>
            { localizer.getString('Channels.already_have_skype') }&nbsp;
            { <Button
                key={ 0 }
                id='btnLaunchNow'
                onClick={ this.props.onLaunchClick }
                className='fakeLink'
                aria-label={ `${ localizer.getString('Channels.already_have_skype') } ${ localizer.getString('Channels.lnk_launch_skype') }` }
                text={ true }>{ localizer.getString('Channels.lnk_launch_skype') }
            </Button> }
        </Text>;
    }

    renderControls() {
        if (deviceType === DeviceTypes.Mobile) {
            return <Button key={ 0 } id='btnLaunch' primary={ true } onClick={ this.props.onLaunchClick }>
                { localizer.getString('Channels.join_channel') }
            </Button>;
        }

        return this.props.channelContext &&
            <>
                { canJoinOnWeb && <Button key={ 0 } id='btnJoin' primary={ true } autoFocus
                    onClick={ this._joinOnWeb(this.props.channelContext) }>
                    { localizer.getString('Channels.join_channel') }
                </Button> }

                <Button key={ 1 } id='btnDownload' primary={ !canJoinOnWeb } text={ canJoinOnWeb } onClick={ this._downloadSkype() }>
                    { localizer.getString('Channels.btn_launch_download_skype') }
                </Button>
            </>;
    }

    renderMobileBody() {
        if (deviceType !== DeviceTypes.Mobile) {
            return null;
        }

        return <>
            <Text size={ 'medium' } className='body'>
                { localizer.getString('Channels.txt_launch_first_time_device') }
            </Text>
            <Text size={ 'medium' }>
                { localizer.get('txt_launch_please_download', {
                    download_skype: <a href='javascript:void(0);' id='downloadMobileClient'
                        onClick={ this._downloadSkype() } style={ { textDecoration: 'none', color: '#0078d4' } }>
                        { localizer.getString('Channels.btn_launch_download_skype') }
                    </a>,
                }) }
            </Text>
        </>;
    }

    // If a channel's iconUrl is available, use it as an avatar; otherwise, generate initials.
    private _getAvatarContent = (channelContext: ChannelsContext) => {
        return channelContext.channelInfo.iconUrl ?
            {
                image: {
                    src: channelContext.channelInfo.iconUrl,
                    styles: { width: '100%', height: '100%', objectFit: 'cover' },
                    className: 'channelsAvatar',
                },
            } :
            {
                name: channelContext.channelInfo.name,
                getInitials: this._getInitials,
                className: 'custom-avatar',
            };
    }

    // Provide initials for the initial two words or the first two characters of the first word if there's only one word.
    private _getInitials = (name: string) => {
        const words = name.split(' ');
        return words.length > 1 ? words[0].charAt(0) + words[1].charAt(0) : name.substring(0, 2);
    }

    private _joinOnWeb = (channelContext: ChannelsContext) => {
        return () => {
            logger.action({ name: 'join-channel' });

            const webClientUrl = UrlHelper.getWebClientUrlWithCorrelationId({
                channelId: channelContext.channelInfo.id,
            });
            window.location.href = config.urls.app.login(webClientUrl, CookieHelper.getCookie(config.cookies.csrf).singleValue);
        };
    }

    private _downloadSkype = () => {
        return () => {
            logger.action({ name: 'download' });
            const downloadUrl = os === OperatingSystems.MacOS ? config.urls.app.downloadDesktop : config.urls.app.download;
            window.open(downloadUrl, '_blank');
        };
    }
}

export const ChannelView = withChannelsContext(ChannelViewKlass);
