import { localizer } from '@skype/bew-localization';
import { Button, Header, Text } from '@stardust-ui/react';
import config from 'config';
import uniq from 'lodash/uniq';
import * as React from 'react';
import { WebErrorResponse } from 'simplerestclients';
import { OperatingSystems, os } from 'src/ts/helpers/EnvironmentHelper';
import { displayableErrors, ErrorHelper } from 'src/ts/helpers/ErrorHelper';
import { UrlHelper } from 'src/ts/helpers/UrlHelper';
import BotsRestClient from 'src/ts/rest/BotsRestClient';
import { BotsContext, BotsContextConsumer } from '../../contexts/bots/BotsContext';
import { logger } from '../../helpers/LoggerHelper';
import { Footer } from '../common/Footer';
import { Padder } from '../common/Padder';
import { BotInfo } from './BotInfo';
import { BotLauncher } from './BotLauncher';

interface AddBotViewProps {
    skypetoken?: string;
    onProgress: () => void;
    onAdd: () => void;
    onError: (error: Error) => void;
}

export class AddBotView extends React.PureComponent<AddBotViewProps, React.StatelessComponent> {

    render() {
        return <div className='bots addBotView'>
            <Padder />
            <div className='scrollable-y'>
                <div className='body'>
                    <Header as='h1' content='Skype' />
                    <BotInfo />
                    <BotsContextConsumer>{ (botsContext) => botsContext &&
                        (botsContext.isAddedAsContact ? this._renderBotAddedContent(botsContext) : this._renderBotAddPromptContent(botsContext))
                    }</BotsContextConsumer>
                </div>
            </div>
            <Padder />
            <Footer showOnlyLegalInfo={ true }/>
        </div>;
    }

    private _renderBotAddPromptContent(botContext: BotsContext) {
        return <>
            { botContext.botInfo.addAsContactAllowed && this._renderAddButton(botContext) }
            { this._renderBotDetails(botContext) }
        </>;
    }

    private _renderBotAddedContent(botContext: BotsContext) {
        return <div>
          <Button key={ 1 } id='btnDownload' primary={ true } onClick={ this._downloadSkype }>
          { localizer.getString('download_skype') }
          </Button>
          <Text size='large' content={ localizer.getString('bot_added') } />
          <BotLauncher botSkypeId={ botContext.botInfo.skypeId } />
        </div>;
    }

    private _renderAddButton(botContext: BotsContext) {
        return <Button key={ 0 } id='btnAddBot' primary={ true } autoFocus
            onClick={ this._addBotToContacts(botContext) }>
                <Text>{ localizer.getString('add_to_contacts') }</Text>
        </Button>;
    }

    private _renderBotDetails(botContext: BotsContext) {
        const privacyAndTos = botContext.botInfo.privacyStatement && botContext.botInfo.tos ?
             localizer.get('privacy_and_terms', {
                 linkPrivacy: { type: 'a', href: botContext.botInfo.privacyStatement},
                 linkTerms: { type: 'a', href: botContext.botInfo.tos},
             }) : undefined;
        return <section className='botDetails'>
            <Text size='medium' content={ botContext.botInfo.description } />
            { this._renderCapabilities(botContext.botInfo.capabilities) }
            <Text className='disclaimer' size='medium' content={ localizer.getString('bot_disclaimer') } />
            <div className='privacy'>
                { privacyAndTos }
            </div>
        </section>;
    }

    private _renderCapabilities(botCapabilities: string[]) {

        const capabilitiestoLocStringMap: { [key: string]: string; } = {
            'im.sendReceive': 'capability_im_send_receive',
            'im.send': 'capability_im_send_receive',
            'im.receive': 'capability_im_send_receive',
            'audio.send': 'capability_audio_send',
        };

        const uniqueLocalizedCapabilities = uniq(botCapabilities
            .filter((cap) => !!capabilitiestoLocStringMap[cap])
            .map((cap) => localizer.getString(capabilitiestoLocStringMap[cap])));

        return <div className='capabilities'>
            <Header as='h3' content={ localizer.getString('capabilities') } />
            <ul>
                { uniqueLocalizedCapabilities.map((cap, index) => <li key={ index }><Text size='medium' content={ cap } /></li>) }
            </ul>
        </div>;
    }

    private _addBotToContacts(botContext: BotsContext) {
        return () => {
            logger.action({name: 'bot-add-contact'});
            const { skypetoken } = this.props;
            if (!skypetoken) {
                this._redirectToLogin(botContext.botId);
                return;
            }

            this.props.onProgress();
            BotsRestClient.addBotToContacts(botContext.botId, skypetoken)
                .then(() => this.props.onAdd())
                .catch((response: WebErrorResponse) => {
                    const error = response && response.statusCode === 412 ?
                    ErrorHelper.enrichErrorFromWebResponse(displayableErrors.BotTooManyContacts, response, 'Add bot request') :
                    ErrorHelper.enrichErrorFromWebResponse(displayableErrors.Generic, response, 'Add bot request');
                    this.props.onError(error);
                });
        };
    }

    private _redirectToLogin(botId: string) {
        const useBotsLauncherQueryParam = !!UrlHelper.getQueryParameter('botsLauncher');
        window.location.href = config.urls.app.botLogin(botId, useBotsLauncherQueryParam);
    }

    private _downloadSkype = () => {
        logger.action({ name: 'download' });
        const downloadUrl = os === OperatingSystems.MacOS ? config.urls.app.downloadDesktop : config.urls.app.download;
        window.open(downloadUrl, '_blank');
    }
}
