import * as React from 'react';
import { OffSkypeInviteServiceModel } from '../../models/OffSkypeInviteServiceModel';

export interface InviteContext {
    invite: OffSkypeInviteServiceModel;
}

// Default context does not mean much sense here
const context = React.createContext<InviteContext | undefined>(undefined);

export const InviteContextProvider = context.Provider;

export const InviteContextConsumer = context.Consumer;
