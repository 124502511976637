import * as React from 'react';
import { ChannelsContext, ChannelsContextConsumer } from 'src/ts/contexts/channels/ChannelsContext';

export interface WithChannelsContextProps {
    channelContext?: ChannelsContext;
}

export const  withChannelsContext = <T extends WithChannelsContextProps>(Klass: React.ComponentType<T>) => (props: T) => (
    <ChannelsContextConsumer>
        {(channelContext: ChannelsContext) => <Klass channelContext={channelContext} {...props} />}
    </ChannelsContextConsumer>
);
