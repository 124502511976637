import config from 'config';
import * as SyncTasks from 'synctasks';
import { ChannelInfoServiceModel } from '../models/ChannelServiceModel';
import { ApiCallOptions, BaseRestClient } from './BaseRestClient';

class ChannelsRestClient extends BaseRestClient {
    getChannelInfo(shortId: string): SyncTasks.Promise<ChannelInfoServiceModel> {
        const basePath = '/api/v2/channels/info/';
        const options: ApiCallOptions = {
            eventProperties: {
                genericEventPath: basePath,
            },
        };

        return this.performApiGet<ChannelInfoServiceModel>(basePath + `?shortId=${encodeURIComponent(shortId)}`, options);
    }

}

export default new ChannelsRestClient(config.urls.api.channels);
