import * as React from 'react';
import { AriaContext } from 'src/ts/contexts/AriaContext';
import { logger, NavigationEventType } from 'src/ts/helpers/LoggerHelper';
import { Dimension, mainContentId } from 'src/ts/models/SharedTypes';
import {
    AnimationType, defaultStackViewProperties, StackAnimation, StackNavigationContext,
    StackNavigationContextProvider, StackView, StackViewProperties, ViewType,
} from '../../contexts/StackNavigationContext';
import { ARootViewProps } from './ARootView';
import { LoadingView } from './LoadingView';

export interface StackNavigationProps extends ARootViewProps {
    ariaContext: AriaContext;
    loading?: boolean;
    parentContext?: StackNavigationContext;
    readonly initialView: React.ReactNode;
    readonly initialViewProps?: Partial<StackViewProperties>;
    onDimensionUpdate?: (dimension: Dimension) => void;
}

export interface StackNavigationState extends StackNavigationContext {
    mainContentId?: string;
}

export class StackNavigationView extends React.PureComponent<StackNavigationProps, StackNavigationState> {
    constructor(props: StackNavigationProps) {
        super(props);

        this.state = {
            animating: false,
            views: [{ view: props.initialView, properties: { ...defaultStackViewProperties, ...props.initialViewProps } }],
            push: this._push,
            pop: this._pop,
            pushRoot: props.parentContext ? props.parentContext.pushRoot : this._push,
            popRoot: props.parentContext ? props.parentContext.popRoot : this._pop,
            pushParent: props.parentContext ? props.parentContext.push : this._push,
            popParent: props.parentContext ? props.parentContext.pop : this._pop,
            mainContentId: props.parentContext ? undefined : mainContentId,
        };
    }

    componentDidMount() {
        this._track('push');
    }

    componentWillUnmount() {
        this._track('pop');
    }

    render() {
        return <StackNavigationContextProvider value={ this.state }>
            { this.props.loading ? <LoadingView /> : this.renderViews() }
        </StackNavigationContextProvider>;
    }

    private _track = (type: NavigationEventType) => {
        const views = this.state.views;
        if (!views.length) {
            return;
        }

        const current = views[views.length - 1];
        if (current.properties.eventViewName) {
            logger.navigation({ view: current.properties.eventViewName, type, isRoot: !this.props.parentContext }, current.properties.eventPriority);
        }
    }

    private _push = (view: React.ReactNode, properties?: Partial<StackViewProperties>) => {
        if (properties && properties.ariaMessage) {
            this.props.ariaContext.announce(properties.ariaMessage);
        }

        this.setState((prevState) => {
            if (prevState.animating) {
                return null;
            }

            const views = [...prevState.views];
            views.push({
                view,
                properties: { ...defaultStackViewProperties, ...properties },
            });

            let animating = false;
            if (properties && properties.animation && properties.animation.type !== AnimationType.None) {
                animating = true;
                setTimeout(() => {
                    this.setState({ animating: false });
                }, properties.animation.duration * 1000);
            }

            return {
                views,
                animating,
            };
        }, () => this._track('push'));
    }

    private _pop = () => {
        this._track('pop');

        if (this.state.views.length > 1) {
            const previous = this.state.views[this.state.views.length - 2];
            if (previous.properties.ariaMessage) {
                this.props.ariaContext.announce(previous.properties.ariaMessage);
            }
        }

        this.setState((prevState, props) => {
            if (prevState.animating) {
                return null;
            }

            const views = [...prevState.views];
            if (!views.length) {
                return null;
            }

            let animating = false;
            const current = views[views.length - 1];
            const usedAnimation = current.properties.animation;

            if (current.properties.eventViewName) {
                logger.navigation({ view: current.properties.eventViewName, type: 'pop', isRoot: !props.parentContext });
            }
            if (usedAnimation.type === AnimationType.None) {
                views.pop();
            } else {
                animating = true;
                current.properties.animation = { ...usedAnimation, type: `${ usedAnimation.type }Out` as AnimationType };
                setTimeout(() => {
                    animating = false;
                    views.pop();
                    this.setState({
                        views,
                        animating,
                    });
                }, current.properties.animation.duration * 1000);
            }

            return {
                views,
                animating,
            };
        });
    }

    private _getAnimationStyle = (animation: StackAnimation, prev: boolean): React.CSSProperties => {
        switch (animation.type) {
            case AnimationType.Fade:
                return prev ?
                    { animation: `stackFadeOut ${ animation.duration }s forwards` }
                    : { animation: `stackFade ${ animation.duration }s forwards` };
            case AnimationType.FadeOut:
                return prev ?
                    { animation: `stackFade ${ animation.duration }s forwards` }
                    : { animation: `stackFadeOut ${ animation.duration }s forwards` };
            case AnimationType.Slide:
                return prev ?
                    { animation: `stackFadeOut ${ animation.duration }s forwards, stackRightOut ${ animation.duration }s forwards` }
                    : { animation: `stackFade ${ animation.duration }s forwards, stackLeft ${ animation.duration }s forwards` };
            case AnimationType.SlideOut:
                return prev ?
                    { animation: `stackFade ${ animation.duration }s forwards, stackRight ${ animation.duration }s forwards` }
                    : { animation: `stackFadeOut ${ animation.duration }s forwards, stackLeftOut ${ animation.duration }s forwards` };
            case AnimationType.None:
                return {};
            default:
                throw new Error('Not implemented yet');
        }
    }

    private renderPrevView = (current: StackView, prev: StackView, visible = true) => {
        const fullscreenClassName = !this.props.parentContext ? 'fullscreen' : 'nested';

        return <div key='previous' className={ `stack prev ${ fullscreenClassName }` }
            style={ { ...this._getAnimationStyle(current.properties.animation, true), visibility: visible ? undefined : 'hidden' } }>{ prev.view }</div>;
    }

    private renderCurrentView = (current: StackView) => {
        const fullscreenClassName = !this.props.parentContext ? 'fullscreen' : 'nested';

        return <div key='current' id={ this.state.mainContentId } role='main' className={ `stack current ${ fullscreenClassName }` }
            style={ this._getAnimationStyle(current.properties.animation, false) }>{ current.view }</div>;
    }

    private renderViews = () => {
        if (!this.state.views.length) {
            return undefined;
        }

        const current = this.state.views[this.state.views.length - 1];
        let prev: StackView | undefined;
        for (let i = this.state.views.length - 1; i > 0; i--) {
            const candidate = this.state.views[i - 1];
            if (candidate.properties.type !== ViewType.Overlay) {
                prev = candidate;
                break;
            }
        }
        if (prev && this.state.animating) {
            return <>
                { this.renderPrevView(current, prev) }
                { this.renderCurrentView(current) }
            </>;
        } else if (prev && current.properties.type === ViewType.Overlay) {
            // Modal should always be displayed on top of a screen, in order to keep the state and have a proper close animation
            // Visibility hidden prevent user from tabbing inside the 'prev' view
            return <>
                { this.renderPrevView(current, prev, false) }
                { this.renderCurrentView(current) }
            </>;
        }

        return <>
            { this.renderCurrentView(current) }
        </>;
    }
}
