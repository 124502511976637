import * as React from 'react';
import { AriaContextConsumer } from 'src/ts/contexts/AriaContext';

// AriaLive need to re-render when its context is being updated. This does not make it candidate for PureComponent
export class AriaLive extends React.Component {
    render() {
        // ariaLive refs should stay unchanged for the lifecycle of the application
        // It means that conditional rendering of div[role=region] won't work, but its content will
        return <AriaContextConsumer>{ (ariaContext) =>
            <>
                <div key='assertive' role='region' className='ariaLive' aria-live='assertive'>
                    { ariaContext.message.assertive && ariaContext.message.text }
                </div>
                <div key='polite' role='region' className='ariaLive' aria-live='polite'>
                    { !ariaContext.message.assertive && ariaContext.message.text }
                </div>
            </>
        }</AriaContextConsumer>;

    }
}
