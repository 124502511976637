import EcsClient from '@skype/ecsclient';
import { EcsConfigType, EcsResponseBase } from '@skype/ecsclient/dist/Models';
import config from 'config';
import * as SyncTasks from 'synctasks';
import { STPromise } from 'synctasks';
import { v4 } from 'uuid';
import { EcsSettings, EcsSettings as EcsResponse } from '../contexts/EcsContext';

export interface EcsSettingsResponse extends EcsResponseBase {
    SWG_WebClient: EcsSettings;
}

class EcsHelper {
    private static readonly deviceIdLocalStorageKey = 'mvt.device_id';
    private _ecsClient = new EcsClient<EcsSettingsResponse>();

    public loadConfig(
        skypetoken: string | undefined,
        expiration: number | undefined,
        callback: ((settings: EcsResponse) => void),
        fallback: EcsResponse): STPromise<void> {
        return this._ecsClient.initialize({
            clientName: 'Launcher',
            clientVersion: '1.0.0.0', // @todo use version from build?
            configsToFetch: (!!skypetoken) ? [EcsConfigType.User] : [EcsConfigType.Default],
            hosts: [config.urls.ecs],
            initialAppActiveState: true,
            getEcsParameters: () => SyncTasks.Resolved({ clientId: EcsHelper.clientId}),
            initialSkypeTokenData: {
                skypeToken: skypetoken,
                skypeTokenExpiration: Date.now() + (expiration || 0),
            },
        }).then(() => {
            this._ecsClient.configUpdated.subscribe(() => {
                const response = this._ecsClient.getConfig();
                if (!!response && !!response.config) {
                    callback(response.config.SWG_WebClient);
                } else {
                    callback(fallback);
                }
            });
        });
    }

    private static get clientId() {
        let deviceId;
        try {
            deviceId = localStorage.getItem(EcsHelper.deviceIdLocalStorageKey);
        } catch (e) {
            return '';
        }

        if (!deviceId) {
            deviceId = v4();
            try {
                localStorage.setItem(EcsHelper.deviceIdLocalStorageKey, deviceId);
            } catch (e) {
                return '';
            }
        }

        return deviceId;
    }
}

export const ecsHelper = new EcsHelper();
