import * as React from 'react';
import { BotInfoServiceModel } from '../../models/BotServiceModel';

export interface BotsContext {
    botInfo: BotInfoServiceModel;
    botId: string;
    isAddedAsContact: boolean;
}

// Default context does not mean much sense here
const context = React.createContext<BotsContext | undefined>(undefined);

export const BotsContextProvider = context.Provider;

export const BotsContextConsumer = context.Consumer;
