import * as React from 'react';
import { getSupportedBrowsersDefault } from './../helpers/EnvironmentHelper';

export interface EcsSettings {
    useMeetingsApi: boolean;
    useUnifiedView: boolean;
    useJoinThread?: boolean;
    maxRetries?: number;
    allowDesktopSafariGuestFlow?: boolean;
    webSubdomainForRedirect?: string;
    useNewMobileJoinFlow?: boolean;
    quickJoinFlowSupportedBrowsers?: QuickJoinFlowSupportedBrowsers;
}

export interface QuickJoinFlowSupportedBrowsers {
    windows: string[];
    mac: string[];
    ios: string[];
    android: string[];
    others: string[];
}

export interface EcsContext {
    settings: EcsSettings;
}

const context = React.createContext<EcsContext>({} as EcsContext);

const ecsDefaults: EcsSettings = {
    useMeetingsApi: true,
    useUnifiedView: true,
    useJoinThread: true,
    maxRetries: 0,
    useNewMobileJoinFlow: true,
    allowDesktopSafariGuestFlow: true,
    quickJoinFlowSupportedBrowsers: getSupportedBrowsersDefault,
};

export const EcsContextProvider = context.Provider;

export const EcsContextConsumer = context.Consumer;

export const EcsConfigFallback = ecsDefaults;
