import { localizer } from '@skype/bew-localization';
import { Avatar, Button, Icon, Layout, Text } from '@stardust-ui/react';
import config from 'config';
import * as React from 'react';
import { ConfigContext, ConfigContextConsumer } from 'src/ts/contexts/ConfigContext';
import { logger } from 'src/ts/helpers/LoggerHelper';
import { UrlHelper } from 'src/ts/helpers/UrlHelper';
import { MeetingContext, MeetingContextConsumer } from '../../../contexts/meetings/MeetingContext';
import { StackNavigationContext, StackNavigationContextConsumer } from '../../../contexts/StackNavigationContext';
import { UserContextConsumer } from '../../../contexts/UserContext';
import { CookieHelper } from '../../../helpers/CookieHelper';
import { UserProfile } from '../../../models/ProfileServiceModel';
import { GuestLogin } from './GuestLogin';

const secondaryButtonTransparentStyle = { backgroundColor: 'transparent', color: 'white' };
export class LoginMethod extends React.PureComponent {

    render() {
        return <div className='body'>
            { this._renderTitle() }
            { this._renderActions() }
        </div>;
    }

    private _renderTitle() {
        return <UserContextConsumer>{ (userContext) =>
            <Text size='larger' styles={ { margin: '2rem' } }>
                { userContext.profile ?
                    localizer.getString('Login.txt_sign_in_with_title')
                    :
                    localizer.getString('hdr_welcome') }
            </Text>
        }</UserContextConsumer>;
    }

    private _renderActions() {
        return <UserContextConsumer>{ (userContext) =>
            <div className='actions'>
                { userContext.profile ?
                    this._renderAuthenticatedActions(userContext.profile) :
                    this._renderUnauthenticatedActions() }
            </div>
        }</UserContextConsumer>;
    }

    private _renderAuthenticatedActions(userProfile: UserProfile) {
        return <MeetingContextConsumer>{ (meetingContext) => meetingContext &&
            <>
                <ConfigContextConsumer>{ (configContext) =>
                    this._renderAccounts(userProfile, meetingContext, configContext) }
                </ConfigContextConsumer>
                { this._renderWhatsThis() }
            </>
        }</MeetingContextConsumer>;
    }

    private _renderAccounts(userProfile: UserProfile, meetingContext: MeetingContext, configContext: ConfigContext) {
        const email: string | undefined = userProfile.emails && userProfile.emails.length ?
            userProfile.emails[0] : undefined;
        const name: string = userProfile.firstname && userProfile.lastname ?
            `${ userProfile.firstname } ${ userProfile.lastname }` : userProfile.username;
        const avatarImage = {
            src: userProfile.avatarUrl || `${ configContext.basePath }/images/avatar.jpg`,
            styles: { width: '100%', height: '100%', objectFit: 'cover' },
        };

        const currentAccountAvatar = <Avatar styles={ { margin: '10px 0px' } } size={ 40 } image={ avatarImage } />;
        const currentAccountText = <Layout vertical
            start={ <Text size='small' weight='semibold'>{ name }</Text> }
            end={ <Text size='small'>{ email }</Text> } />;
        const currentAccount = <Button autoFocus text onClick={ this._joinOnWeb(meetingContext) }>
            <Layout className='login-option' start={ currentAccountAvatar } main={ currentAccountText } />
        </Button>;

        const otherAccountIcon = <Icon name='add' size='large'
            styles={ { backgroundColor: '#E5E4E8', borderRadius: '100%', margin: '10px 0px', color: 'white', padding: '10px' } } />;
        const otherAccountText = <Layout vertical
            start={ <Text size='small' weight='semibold'>{ localizer.getString('Login.btn_use_other_account') }</Text> } />;
        const otherAccount = <Button text onClick={ this._joinOnWebOtherAccount(meetingContext) }>
            <Layout className='login-option' start={ otherAccountIcon } main={ otherAccountText } />
        </Button>;

        return <div className='active'>
            <Layout vertical start={ currentAccount } end={ otherAccount } />
        </div>;
    }

    private _joinOnWebAsGuest = (stackNavigationContext: StackNavigationContext) => {
        return () => {
            logger.action({ name: 'join-as-guest' });
            logger.sessionIntermediateStep({eventType: 'JoinOnWebAsGuestSecondClickOldView'});
            stackNavigationContext.push(<GuestLogin />, { eventViewName: 'guest-login' });
        };
    }

    private _joinOnWebOtherAccount = (meetingContext: MeetingContext) => {
        return () => {
            logger.action({ name: 'join-logout' });
            logger.sessionFinished({completionType: 'JoinOnWebOtherAccount'});
            const webClientUrl = UrlHelper.getWebClientUrlWithCorrelationId({ threadId: meetingContext.conversation.resource });
            window.location.href = config.urls.app.logout(webClientUrl, CookieHelper.getCookie(config.cookies.csrf).singleValue);
        };
    }

    private _joinOnWeb = (meetingContext: MeetingContext) => {
        return () => {
            logger.action({ name: 'join-login' });
            logger.sessionFinished({completionType: 'JoinOnWeb'});
            window.location.assign(UrlHelper.getWebClientUrlWithCorrelationId({ threadId: meetingContext.conversation.resource }));
        };
    }

    private _renderUnauthenticatedActions() {
        return <>
            <StackNavigationContextConsumer>{ (stackNavigationContext) =>
                <Button key={ 0 } id='btnJoinAsGuest' autoFocus onClick={ this._joinOnWebAsGuest(stackNavigationContext) }>
                    <Text weight='semibold'>{ localizer.getString('Login.btn_join_as_guest') }</Text>
                </Button>
            }</StackNavigationContextConsumer>
            <MeetingContextConsumer>{ (meetingContext) => meetingContext &&
                <>
                    <Button styles={ secondaryButtonTransparentStyle } key={ 1 } id='btnLogin' onClick={ this._joinOnWeb(meetingContext) }>
                        <Text weight='semibold'>{ localizer.getString('Login.btn_signin_create') }</Text>
                    </Button>
                    { this._renderWhatsThis() }
                </>
            }</MeetingContextConsumer>
        </>;
    }

    private _renderWhatsThis() {
        return <span className='help'>
            { localizer.getString('Login.txt_use_ms_account') }
            &nbsp;
            <a href={ 'https://go.skype.com/help.password.faq.oldview' }
                onClick={ () => logger.action({ name: 'what-is-msa' }) }
                target='_blank'
                aria-label={ localizer.getString('Login.lnk_a11y_what_is_ms_account') } >
                { localizer.getString('Login.lnk_what_is_ms_account') }
            </a>
        </span>;
    }
}
