import { localizer } from '@skype/bew-localization';
import * as React from 'react';
import { AriaContextConsumer } from 'src/ts/contexts/AriaContext';

export interface CaptchaSolution {
    solution: string; // "dhjdprwgyn",
    challengeId: string;
    azureRegion: string;
}

export interface CaptchaProps {
    challengeId: string;
    challenge: string;
    azureRegion: string;
    onSolutionUpdated: (solutionValue: string) => void;
}

export interface CaptchaState {
    currentSolution: string;
}

export class Captcha extends React.PureComponent<CaptchaProps, CaptchaState>  {
    private challenge: string;
    constructor(props: CaptchaProps) {
        super(props);
        this.state = {
            currentSolution: '',
        };
        this.challenge = props.challenge;
    }

    render() {
        return <>
            <AriaContextConsumer>{ (ariaContext) =>
                <div id='captcha-container' className='captcha-container' ref={ () => {
                    ariaContext.announce({ assertive: true, text: localizer.getString('Common.a11y_announce_captcha') });
                } }>
                    <img id='captchaImage' src={`data:image/png;base64,${this.challenge}`} />
                    <input
                        id='inputCaptchaSolution'
                        className='solution'
                        value={this.state.currentSolution || ''}
                        onChange={this.onSolutionUpdated}
                        autoFocus />
                    <div id='captchaError' className='captcha__error'></div>
                </div>
            }</AriaContextConsumer>
        </>;
    }

    private onSolutionUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (newValue !== this.state.currentSolution) {
            this.setState({
                currentSolution: newValue,
            });
            this.props.onSolutionUpdated(newValue);
        }
    }
}
