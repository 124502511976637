import { localizer } from '@skype/bew-localization';
import { Header, Image, Text } from '@stardust-ui/react';
import * as React from 'react';
import { ConfigContextConsumer } from '../../contexts/ConfigContext';
import { DisplayableError, ErrorHelper } from '../../helpers/ErrorHelper';

export interface ErrorDetailsProps {
    error: Error;
    showSkype?: boolean;
    assetsBasePath?: string;
}

export class ErrorDetails extends React.PureComponent<ErrorDetailsProps, React.StatelessComponent>  {
    constructor(props: ErrorDetailsProps) {
        super(props);
    }

    render() {
        const displayableError: DisplayableError = ErrorHelper.isDisplayableError(this.props.error)
            ? this.props.error
            : ErrorHelper.convertToGenericDisplayableError(this.props.error);

        return <div className='errorDetails'>
            { this.props.showSkype && <Header as='h1' content='Skype' className='title'/> }
            <ConfigContextConsumer>
                    { (configContext) => displayableError.emoticonPath &&
                        <Image className='logo' src={ `${ this.props.assetsBasePath ?? configContext.basePath }${ displayableError.emoticonPath }` } aria-hidden='true' />
                    }
            </ConfigContextConsumer>
            <Header as='h2' content={ localizer.getString(displayableError.headerLocString) } className='title'/>
            <Text size={ 'medium' } className='description'>
                { localizer.getString(displayableError.descriptionLocString) }
            </Text>
        </div>;
    }
}
