import { localizer } from '@skype/bew-localization';
import { Button, Header, Image, Text } from '@stardust-ui/react';
import config from 'config';
import * as React from 'react';
import { ConfigContextConsumer } from 'src/ts/contexts/ConfigContext';
import { logger } from 'src/ts/helpers/LoggerHelper';
import { OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { Footer } from '../common/Footer';
import { Padder } from '../common/Padder';

interface DoViewProps {
    onLaunchClick: () => void;
}

export class DoView extends React.PureComponent<DoViewProps, React.StatelessComponent> {

    renderLogo() {
        return <ConfigContextConsumer>
            { (configContext) => <Image
                src={ `${ configContext.basePath }/images/states/browser-to-mobile.svg` }
                className='logo'
                aria-hidden='true'
            /> }
        </ConfigContextConsumer>;
    }

    renderTitle() {
        return <Header as='h1' className='title'>{ localizer.getString('hdr_launch_joining_conversation') }</Header>;
    }

    renderControls() {
        return <>
        <Button key={ 0 } id='btnLaunch' primary={ true } onClick={ this.props.onLaunchClick }>
            { localizer.getString('btn_launch_join') }
        </Button>
        </>;
    }

    renderBody() {
        return <>
            <Text size={ 'medium' } className='body'>
                { localizer.getString('txt_launch_first_time_device') }
            </Text>
            <Text size={ 'medium' }>
                { localizer.get('txt_launch_please_download', {
                    download_skype: <a href='javascript:void(0);' id='downloadMobileClient'
                        onClick={ this._downloadSkype } style={ { textDecoration: 'none', color: '#0078d4' } }>
                        { localizer.getString('btn_launch_download_skype') }
                    </a>,
                }) }
            </Text>
        </>;
    }

    render() {
        return <div className='do doView'>
            <Padder />
            <div className='scrollable-y'>
                { this.renderLogo() }
                { this.renderTitle() }
                { this.renderControls() }
                { this.renderBody() }
            </div>
            <Padder />
            <Footer />
        </div>;
    }

    private _downloadSkype = () => {
        logger.action({ name: 'download' });

        const downloadUrl = os === OperatingSystems.MacOS ? config.urls.app.downloadDesktop : config.urls.app.download;
        window.open(downloadUrl, '_blank');
    }
}
