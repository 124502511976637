import { localizer } from '@skype/bew-localization';
import { Button, Header, Image, ProviderConsumer, Text, ThemePrepared } from '@stardust-ui/react';
import * as React from 'react';
import { logger } from 'src/ts/helpers/LoggerHelper';
import { ConfigContextConsumer } from '../../contexts/ConfigContext';
import { defaultFadeAnimation, StackNavigationContextConsumer } from '../../contexts/StackNavigationContext';
import { downloadSkype } from '../../helpers/DownloadSkypeHelper';
import { canJoinOnWeb, deviceType, DeviceTypes, isIOSInAppBrowserFlow } from '../../helpers/EnvironmentHelper';
import { UrlHelper } from '../../helpers/UrlHelper';
import { Footer } from '../common/Footer';
import { Header as PageHeader } from '../common/Header';
import { Padder } from '../common/Padder';
import { JoinLink, JoinLinkProps } from './JoinLink';
import { LoginView } from './LoginView';

interface JoinViewProps extends JoinLinkProps {
    onLaunchClick: () => void;
    threadId?: string;
}

export class JoinView extends React.PureComponent<JoinViewProps, React.StatelessComponent> {

    renderLogo(theme: ThemePrepared) {
        return (
            <ConfigContextConsumer>
                { (configContext) => <Image src={ `${ configContext.basePath }${ theme.siteVariables.brandIcon }` } className='logo' aria-hidden='true' /> }
            </ConfigContextConsumer>
        );
    }

    renderTitle() {
        return <>
            <Header as='h1' className='title'>{ localizer.getString('Meetings.txt_join_meeting_title') }</Header>
        </>;
    }

    renderDesktopBody() {
        if (deviceType !== DeviceTypes.Desktop) {
            return null;
        }

        return <Text size={ 'medium' } className='body'>
            { localizer.getString('Meetings.txt_join_meeting_description') }&nbsp;
            { <Button
                key={ 0 }
                id='btnLaunchNow'
                onClick={ this.props.onLaunchClick }
                className='fakeLink'
                aria-label={ `${ localizer.getString('Meetings.txt_join_meeting_description') } ${ localizer.getString('Meetings.lnk_join_meeting_retry') }` }
                text={ true }>{ localizer.getString('Meetings.lnk_join_meeting_retry') }
            </Button> }
        </Text>;
    }

    renderControls() {
        if (deviceType === DeviceTypes.Mobile) {
            return <Button key={ 1 } id='btnDownload' primary={ true } text={ false } onClick={ downloadSkype(this.props.threadId) }>
                { localizer.getString('btn_launch_download_skype') }
            </Button>;
        }

        return <>
            { canJoinOnWeb && <StackNavigationContextConsumer>{ (stackNavigationContext) =>
                <Button key={ 0 } id='btnJoin' primary={ true } autoFocus
                    onClick={ () => {
                        logger.sessionIntermediateStep({eventType: 'JoinOnWebAsGuestInitialClickOldView'});
                        stackNavigationContext.push(<LoginView />, {
                        eventViewName: 'login-view',
                        animation: defaultFadeAnimation,
                        ariaMessage: { assertive: true, text: localizer.getString('Login.txt_sign_in_with_title') }});
                    }}>
                    { localizer.getString('Meetings.btn_join_on_web') }
                </Button>
            }</StackNavigationContextConsumer> }
            <Button key={ 1 } id='btnDownload' primary={ !canJoinOnWeb } text={ canJoinOnWeb } onClick={ downloadSkype(this.props.threadId) }>
                { localizer.getString('btn_launch_download_skype') }
            </Button>
        </>;
    }

    renderMobileBody() {
        if (deviceType !== DeviceTypes.Mobile) {
            return null;
        }

        const joinMeeting = this._isFallbackFlow() ?
            <JoinLink shortId={ this.props.shortId }/> :
            <Text size={ 'medium' }>
                <a href='javascript:void(0);'
                    onClick={ this.props.onLaunchClick } style={ { textDecoration: 'none', color: '#0078d4' } }>
                    { localizer.getString('btn_launch_join') }
                </a>
            </Text>;

        return <>
            <Text size={ 'medium' } className='body'>
                { localizer.getString('Meetings.txt_join_meeting_description') }
            </Text>

            { joinMeeting }
        </>;
    }

    render() {
        return <div className='meetings joinView'>
            <PageHeader />
            <Padder />
            <div className='scrollable-y'>
                <ProviderConsumer render={ this.renderLogo.bind(this) }></ProviderConsumer>
                { this.renderTitle() }
                { this.renderControls() }
                { this.renderMobileBody() }
                { this.renderDesktopBody() }
            </div>
            <Padder />
            <Footer />
        </div>;
    }

    private _isFallbackFlow = (): boolean => isIOSInAppBrowserFlow && undefined !== UrlHelper.getQueryParameter('fallback');
}
