import * as React from 'react';

export interface ProfilesContext {
    skypeId: string;
}

// Default context does not mean much sense here
const context = React.createContext<ProfilesContext>({} as ProfilesContext);

export const ProfilesContextProvider = context.Provider;

export const ProfilesContextConsumer = context.Consumer;
