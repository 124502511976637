import config from 'config';
import { ProfilesContext } from '../../contexts/profiles/ProfilesContext';
import { OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { logger } from '../../helpers/LoggerHelper';
import { Actions } from '../../models/SharedTypes';
import { ALauncher } from '../common/ALauncher';

interface LauncherProps {
    profile: ProfilesContext;
}

export class Launcher extends ALauncher<LauncherProps> {

    protected _getLauncherUrl() {
        if (os === OperatingSystems.Android) {
            return config.urls.doLauncher.intentUri(Actions.Chat, this.props.profile.skypeId, logger.getCorrelationId(), 'launcher', config.urls.app.download);
        }
        return config.urls.doLauncher.skypeUri(Actions.Chat, this.props.profile.skypeId, logger.getCorrelationId(), 'launcher');
    }

    protected _getLoggerAction() {
        return 'join-profile';
    }
}
