import { localizer } from '@skype/bew-localization';
import { Avatar, Button, Header, Icon, Layout, Text } from '@stardust-ui/react';
import config from 'config';
import * as React from 'react';
import { ConfigContext, ConfigContextConsumer } from 'src/ts/contexts/ConfigContext';
import { canJoinOnWeb, deviceType, DeviceTypes, isSafariBrowserEligibleForMeetingGuestFlow, OperatingSystems, os } from 'src/ts/helpers/EnvironmentHelper';
import { logger } from 'src/ts/helpers/LoggerHelper';
import { UrlHelper } from 'src/ts/helpers/UrlHelper';
import { IMeetNowSettings } from 'src/ts/meetnow/MeetNowSettings';
import { handleJoin } from 'src/ts/meetnowJoin/JoinHandler';
import { EcsContextConsumer } from '../../../contexts/EcsContext';
import { MeetingContext, MeetingContextConsumer } from '../../../contexts/meetings/MeetingContext';
import { StackNavigationContext, StackNavigationContextConsumer } from '../../../contexts/StackNavigationContext';
import { UserContextConsumer } from '../../../contexts/UserContext';
import { CookieHelper } from '../../../helpers/CookieHelper';
import { getRedirectSubdomain } from '../../../helpers/SafariRedirectHelper';
import { UserProfile } from '../../../models/ProfileServiceModel';
import { COUNTRY_CHINA } from '../../../models/SharedTypes';
import { ErrorModal } from '../../common/ErrorModal';
import { GuestLogin } from './GuestLogin';

interface UnifiedLoginMethodProps {
    onLaunchClick: () => void;
    allowDesktopSafariGuestFlow: boolean;
    meetNowSettings: IMeetNowSettings;
    country?: string;
}

interface UnifiedLoginMethodState {
    isMeetingRedirectInProgress: boolean;
    handleJoinError?: Error;
}

export class UnifiedLoginMethod extends React.PureComponent<UnifiedLoginMethodProps, UnifiedLoginMethodState> {
    public readonly state: UnifiedLoginMethodState = { isMeetingRedirectInProgress: false };

    render() {
        return <div className='body'>
            { this._renderTitle() }
            { this._renderActions() }
        </div>;
    }

    private _renderTitle() {
        return <UserContextConsumer>{ (userContext) =>
            <Header as='h1' className='title'>
                { userContext.profile ?
                    localizer.getString('Login.txt_sign_in_with_title')
                    :
                    localizer.getString('Meetings.txt_join_meeting_title')
                }
            </Header>
        }</UserContextConsumer>;
    }

    private _renderActions() {
        return <UserContextConsumer>{ (userContext) => {
            const canJoinOnWebWithSafariException = this._canJoinOnWebWithSafariException(!userContext.profile);

            return <div className='actions-container'>
                <div className='actions'>
                    { canJoinOnWebWithSafariException ? (
                        userContext.profile ?
                            this._renderAuthenticatedActions(userContext.profile) :
                            this._renderUnauthenticatedActions()
                    ) : (
                        <>
                            { this.renderDownloadButton(!userContext.profile) }
                            { this.renderDesktopBody(!userContext.profile) }
                        </>
                    ) }
                </div>
                { canJoinOnWebWithSafariException && this.renderDownloadButton(!userContext.profile) }
                { canJoinOnWebWithSafariException && this.renderDesktopBody(!userContext.profile) }
                {
                    this.state.handleJoinError &&
                    <ConfigContextConsumer>
                        {
                            (configContext) => this.state.handleJoinError && (
                                <ErrorModal
                                    error={ this.state.handleJoinError }
                                    assetsBasePath={ configContext.basePath }
                                    onClose={
                                        () => {
                                            this.setState({
                                                handleJoinError: undefined,
                                            });
                                        }
                                    } />
                                )
                        }
                    </ConfigContextConsumer>
                }
            </div>;
        }}</UserContextConsumer>;
    }

    private renderDesktopBody = (isGuest: boolean) => {
        if (deviceType !== DeviceTypes.Desktop) {
            return null;
        }

        if (this._canJoinOnWebWithSafariException(isGuest)) {
            return (
                <Text size={ 'medium' } className='text-body'>
                    { localizer.getString('Meetings.txt_join_meeting_description') }&nbsp;
                    { <Button
                        key={ 0 }
                        id='btnLaunchNow'
                        onClick={ this.props.onLaunchClick }
                        className='fakeLink'
                        aria-label={ `${ localizer.getString('Meetings.txt_join_meeting_description') } ${ localizer.getString('Meetings.lnk_join_meeting_retry') }` }
                        text={ true }>{ localizer.getString('Meetings.lnk_join_meeting_retry') }
                    </Button> }
                </Text>
            );
        }

        return (
            <>
                <Text size={ 'medium' } className='margin-top'>
                    { localizer.getString('Meetings.txt_join_meeting_description') }
                </Text>
                <Button
                    key={ 0 }
                    id='btnLaunchNow'
                    onClick={ this.props.onLaunchClick }
                    aria-label={ `${ localizer.getString('Meetings.txt_join_meeting_description') } ${ localizer.getString('Meetings.lnk_join_meeting_retry') }` }
                >
                    <Text weight='semibold'>{ localizer.getString('Meetings.lnk_join_meeting_retry') }</Text>
                </Button>
            </>
        );
    }

    private renderDownloadButton = (isGuest: boolean) => {
        if (this._canJoinOnWebWithSafariException(isGuest)) {
            return (
                <Button key={ 1 } id='btnDownload' text={ true } onClick={ this.downloadSkype }>
                    { localizer.getString('btn_launch_download_skype') }
                </Button>
            );
        }

        return (
            <Button key={ 1 } id='btnDownload' primary={ true } autoFocus={ true } onClick={ this.downloadSkype }>
                <Text weight='semibold'>{ localizer.getString('btn_launch_download_skype') }</Text>
            </Button>
        );
    }

    private downloadSkype = () => {
        logger.sessionFinished({completionType: 'DownloadSkypeButton'});
        const downloadUrl = os === OperatingSystems.MacOS ? config.urls.app.downloadDesktop : config.urls.app.download;
        window.location.href = downloadUrl;
    }

    private _renderAuthenticatedActions(userProfile: UserProfile) {
        return <MeetingContextConsumer>{ (meetingContext) => meetingContext &&
            <>
                <ConfigContextConsumer>{ (configContext) =>
                    this._renderAccounts(userProfile, meetingContext, configContext) }
                </ConfigContextConsumer>
                { this._renderWhatsThis() }
            </>
        }</MeetingContextConsumer>;
    }

    private _renderAccounts(userProfile: UserProfile, meetingContext: MeetingContext, configContext: ConfigContext) {
        const email: string | undefined = userProfile.emails && userProfile.emails.length ?
            userProfile.emails[0] : undefined;
        const name: string = userProfile.firstname && userProfile.lastname ?
            `${ userProfile.firstname } ${ userProfile.lastname }` : userProfile.username;
        const avatarImage = {
            src: userProfile.avatarUrl || `${ configContext.basePath }/images/avatar.jpg`,
            styles: { width: '100%', height: '100%', objectFit: 'cover' },
        };

        const currentAccountAvatar = <Avatar styles={ { margin: '10px 0px' } } size={ 40 } image={ avatarImage } />;
        const currentAccountText = <Layout vertical
            start={ <Text size='small' weight='semibold'>{ name }</Text> }
            end={ <Text size='small'>{ email }</Text> } />;
        const currentAccount = <EcsContextConsumer>{ (ecsContext) =>
            <Button autoFocus text onClick={ this._joinOnWeb(meetingContext, false, getRedirectSubdomain(ecsContext.settings)) }>
                <Layout className='login-option' start={ currentAccountAvatar } main={ currentAccountText } />
            </Button>
        }</EcsContextConsumer>;

        const otherAccountIcon = <Icon name='add' size='large'
            styles={ { backgroundColor: '#E5E4E8', borderRadius: '100%', margin: '10px 0px', color: 'white', padding: '10px' } } />;
        const otherAccountText = <Layout vertical
            start={ <Text size='small' weight='semibold'>{ localizer.getString('Login.btn_use_other_account') }</Text> } />;
        const otherAccount = <EcsContextConsumer>{ (ecsContext) =>
                <Button text onClick={ this._joinOnWebOtherAccount(meetingContext, getRedirectSubdomain(ecsContext.settings)) }>
                <Layout className='login-option' start={ otherAccountIcon } main={ otherAccountText } />
            </Button>
        }</EcsContextConsumer>;

        return <div className='active'>
            <Layout vertical start={ currentAccount } end={ otherAccount } />
        </div>;
    }

    private _joinOnWebAsGuest = (stackNavigationContext: StackNavigationContext, meetingContext: MeetingContext) => {
        return () => {
            logger.action({ name: 'join-as-guest' });
            logger.sessionIntermediateStep({eventType: 'JoinOnWebAsGuestInitialClickUnifiedView'});

            if (canJoinOnWeb) {
                if (this.props.meetNowSettings) {
                    this.setState({
                        isMeetingRedirectInProgress: true,
                    });

                    handleJoin(this.props.meetNowSettings, {
                        action: 'joinmeetnow',
                        flowId: logger.getCorrelationId(),
                        id: '',
                        resource: meetingContext.conversation.resource,
                        shortId: UrlHelper.getShortIdFromUrl(),
                    }, undefined, true).then(() => {
                        this.setState({
                            isMeetingRedirectInProgress: false,
                        });
                    }).catch((err) => {
                        logger.error(err);
                        this.setState({
                            isMeetingRedirectInProgress: false,
                            handleJoinError: err,
                        });
                    });
                } else {
                    logger.action({ name: 'join-as-guest-fallback' });
                    stackNavigationContext.push(<GuestLogin />, { eventViewName: 'guest-login' });
                }
            } else {
                logger.action({ name: 'join-as-guest-fallback' });
                stackNavigationContext.push(<GuestLogin />, { eventViewName: 'guest-login' });
            }
        };
    }

    private _joinOnWebOtherAccount = (meetingContext: MeetingContext, webSubdomainForRedirect: string | undefined) => {
        return () => {
            logger.action({ name: 'join-logout' });
            logger.sessionFinished({completionType: 'JoinOnWebOtherAccount'});
            const webClientUrl = UrlHelper.getWebClientUrlWithCorrelationId({ threadId: meetingContext.conversation.resource }, webSubdomainForRedirect);
            window.location.href = config.urls.app.logout(webClientUrl, CookieHelper.getCookie(config.cookies.csrf).singleValue);
        };
    }

    private _joinOnWeb = (meetingContext: MeetingContext, isUnauthenticatedFlow: boolean, webSubdomainForRedirect: string | undefined) => {
        return () => {
            logger.action({ name: 'join-login' });
            logger.sessionFinished({completionType: 'JoinOnWeb'});
            if (isUnauthenticatedFlow) {
                const webClientUrl = UrlHelper.getWebClientUrlWithCorrelationId({ threadId: meetingContext.conversation.resource }, webSubdomainForRedirect);
                window.location.href = config.urls.app.logout(webClientUrl, CookieHelper.getCookie(config.cookies.csrf).singleValue);
            } else {
                window.location.assign(UrlHelper.getWebClientUrlWithCorrelationId({ threadId: meetingContext.conversation.resource }, webSubdomainForRedirect));
            }
        };
    }

    private _renderUnauthenticatedActions() {
        return <>
            {
                this.props.country === COUNTRY_CHINA && this._renderDataProcessingStatement()
            }
            <StackNavigationContextConsumer>{ (stackNavigationContext) =>
                <MeetingContextConsumer>{ (meetingContext) => meetingContext &&
                     <Button key={ 0 }
                            id='btnJoinAsGuest'
                            primary={ true }
                            autoFocus={ true }
                            disabled={ this.state.isMeetingRedirectInProgress }
                            onClick={ this._joinOnWebAsGuest(stackNavigationContext, meetingContext) }>
                        <Text weight='semibold'>
                            { this.props.country === COUNTRY_CHINA ? localizer.getString('Login.btn_join_as_guest_cn') : localizer.getString('Login.btn_join_as_guest') }
                        </Text>
                    </Button>
                }
                </MeetingContextConsumer>
            }</StackNavigationContextConsumer>
            {canJoinOnWeb && <MeetingContextConsumer>{ (meetingContext) => meetingContext &&
                <EcsContextConsumer>{ (ecsContext) =>
                    <>
                        <Button key={ 1 } id='btnLogin' onClick={ this._joinOnWeb(meetingContext, true, getRedirectSubdomain(ecsContext.settings)) }>
                            <Text weight='semibold'>{ localizer.getString('Login.btn_signin_create') }</Text>
                        </Button>
                        { this._renderWhatsThis() }
                    </>
                }</EcsContextConsumer>
            }</MeetingContextConsumer>}
        </>;
    }
    private _renderDataProcessingStatement() {
        return (
            <>
                <span className='privacy-statement'>
                    <Text weight='semibold'>{ localizer.getString('china_data_processing.header') }</Text>
                    <p>{ localizer.get('china_data_processing.paragraph_1', {
                        learnMoreLink: {
                            type: 'a', href: 'https://support.microsoft.com/topic/' +
                                'why-does-my-data-need-to-transfer-from-china-to-another-country-when-i-use-this-microsoft-product-b7b538b0-fd1c-4177-ba24-8b8c97defef6',
                        },
                    }) } </p>
                    <p>{ localizer.get('china_data_processing.paragraph_2', {
                        learnMoreLink: {
                            type: 'a', href: 'https://support.microsoft.com/topic/' +
                                'why-does-my-data-need-to-transfer-from-china-to-another-country-when-i-use-this-microsoft-product-b7b538b0-fd1c-4177-ba24-8b8c97defef6',
                        },
                        refundLink: { type: 'a', href: 'https://aka.ms/refund' },
                    }) }</p>
                    <p>{ localizer.get('china_data_processing.paragraph_3', {
                        learnMoreLink: {
                            type: 'a', href: 'https://support.microsoft.com/topic/' +
                                'why-does-my-data-need-to-transfer-from-china-to-another-country-when-i-use-this-microsoft-product-b7b538b0-fd1c-4177-ba24-8b8c97defef6',
                        },
                        privacyStatementLink: { type: 'a', href: 'https://privacy.microsoft.com/privacystatement' },
                    }) }</p>
                </span>
            </>
        );
    }

    private _renderWhatsThis() {
        return this._renderTextWithLink(
            'help',
            localizer.getString('Login.txt_use_ms_account'),
            'https://go.skype.com/help.password.faq.unifiedview',
            'what-is-msa',
            localizer.getString('Login.lnk_a11y_what_is_ms_account'));
    }

    private _renderTextWithLink(className: string, text: string, linkUrl: string, linkClickTelemetryLoggerAction: string, label: string) {
        return <span className={className}>
            { text }
            &nbsp;
            <a href={ linkUrl }
               onClick={ () => logger.action({ name: linkClickTelemetryLoggerAction }) }
               target='_blank'
               style={ { color: '#0078d4' } }
               aria-label={ label } >
                { label }
            </a>
        </span>;
    }

    private _canJoinOnWebWithSafariException = (isGuest: boolean): boolean => {
        if (this.props.allowDesktopSafariGuestFlow && isGuest && os === OperatingSystems.MacOS) {
            return canJoinOnWeb || isSafariBrowserEligibleForMeetingGuestFlow;
        }

        return canJoinOnWeb;
    }
}
