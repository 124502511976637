import { AWTEventPriority } from '@aria/webjs-sdk';
import * as React from 'react';
import { AriaMessage } from './AriaContext';

export interface StackNavigationContext {
    views: StackView[];
    animating: boolean;
    push: (view: React.ReactNode, properties?: Partial<StackViewProperties>) => void;
    pop: () => void;
    pushRoot: (view: React.ReactNode, properties?: Partial<StackViewProperties>) => void;
    popRoot: () => void;
    pushParent: (view: React.ReactNode, properties?: Partial<StackViewProperties>) => void;
    popParent: () => void;
}

export interface StackView {
    view: React.ReactNode;
    properties: StackViewProperties;
}

export interface StackAnimation {
    type: AnimationType;
    duration: number;
}

export interface StackViewProperties {
    type: ViewType;
    animation: StackAnimation;
    ariaMessage?: AriaMessage;
    eventViewName?: string;
    eventPriority?: AWTEventPriority;
}

export enum ViewType {
    Fullscreen, Overlay,
}

export enum AnimationType {
    None = 'none',
    Fade = 'fade',
    FadeOut = 'fadeOut',
    Left = 'left',
    LeftOut = 'leftOut',
    Slide = 'slide',
    SlideOut = 'slideOut',
}

export const defaultSlideAnimation: StackAnimation = { type: AnimationType.Slide, duration: 0.2 };
export const defaultFadeAnimation: StackAnimation = { type: AnimationType.Fade, duration: 0.5 };

export const defaultStackViewProperties: StackViewProperties = {
    type: ViewType.Fullscreen,
    animation: { type: AnimationType.None, duration: 0 },
};

// tslint:disable-next-line:no-empty
const noop = () => { };
const context = React.createContext<StackNavigationContext>({
    animating: false,
    views: [],
    push: noop,
    pop: noop,
    pushRoot: noop,
    popRoot: noop,
    pushParent: noop,
    popParent: noop,
});
export const StackNavigationContextProvider = context.Provider;

export const StackNavigationContextConsumer = context.Consumer;
