import { Config } from 'config';
import * as React from 'react';

export interface ConfigContext extends Config {
    basePath: string;
    language: string;
    switchLanguage: (language: string) => void;
}

// Default context does not mean much sense here
const context = React.createContext<ConfigContext>({} as ConfigContext);

export const ConfigContextProvider = context.Provider;

export const ConfigContextConsumer = context.Consumer;
