import { Image, ProviderConsumer, ThemePrepared } from '@stardust-ui/react';
import * as React from 'react';
import { AriaContextConsumer } from 'src/ts/contexts/AriaContext';
import { ConfigContextConsumer } from '../../contexts/ConfigContext';
import { Footer } from '../common/Footer';
import { NestedStackNavigationView } from '../common/NestedStackNavigationView';
import { LoginMethod } from './login/LoginMethod';

export class LoginView extends React.PureComponent {

    renderThemeAware = (theme: ThemePrepared) => {
        return <div className='loginView' style={ { background: `linear-gradient(180deg, white -200%, ${ theme.siteVariables.brand } 100%)` } }>

            <div className='body'>
                <ConfigContextConsumer>{ (configContext) =>
                    <Image className='logo' src={ `${ configContext.basePath }/images/icons/skypex-icon-white.png` } aria-hidden='true' />
                }</ConfigContextConsumer>
                <AriaContextConsumer>{ (ariaContext) =>
                    <NestedStackNavigationView ariaContext={ ariaContext } initialView={ <LoginMethod /> } initialViewProps={ { eventViewName: 'login-method' } } />
                }</AriaContextConsumer>
            </div>

            <Footer dark={ true } />
        </div>;
    }

    render() {
        return <ProviderConsumer render={ this.renderThemeAware } />;
    }
}
