import * as React from 'react';

export class LoadingView extends React.PureComponent {

    render() {
        return <div className='shimmer-container column'>
            <div className='padder'></div>
            <div className='shimmer avatar'></div>
            <div className='shimmer title'></div>
            <div className='shimmer title'></div>
            <div className='shimmer primary-button'></div>
            <div className='shimmer secondary-button'></div>
            <div className='shimmer description'></div>
            <div className='padder'></div>
        </div>;
    }
}
