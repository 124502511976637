import { EcsSettings } from '../contexts/EcsContext';
import { isSafariBrowserEligibleForMeetingGuestFlow } from './EnvironmentHelper';

export const getRedirectSubdomain = (settings: EcsSettings): string | undefined => {
    if (settings.allowDesktopSafariGuestFlow && isSafariBrowserEligibleForMeetingGuestFlow) {
        return settings.webSubdomainForRedirect;
    }

    return undefined;
};
