import config from 'config';
import { OperatingSystems, os } from './EnvironmentHelper';
import { logger } from './LoggerHelper';

export const downloadSkype = (threadId?: string) => {
    return () => {
        logger.action({ name: 'download' });
        logger.sessionFinished({completionType: 'DownloadSkypeButton'});

        let url: string;
        if (os === OperatingSystems.Android) {
            url = config.urls.app.downloadAndroid(threadId);
        } else if (os === OperatingSystems.MacOS) {
            url = config.urls.app.downloadDesktop;
        } else {
            url = config.urls.app.download;
        }

        window.location.href = url;
    };
};
