import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App, EntryPoint } from './App';
import './app.less';
import { logger } from './helpers/LoggerHelper';
import { UrlHelper } from './helpers/UrlHelper';
import { Themes } from './models/SharedTypes';

const container = document.getElementById('launcherApp')!!!;
const htmlElement = document.getElementsByTagName('html')[0];
const language = htmlElement.lang;
const basePath = container.getAttribute('basePath')!!!;
const injectedEntryPoint = container.getAttribute('entryPoint');
const injectedSkypetoken = container.getAttribute('skypetoken');
const dataRegion = htmlElement.getAttribute('data-region') || undefined;

const entryPoint = (injectedEntryPoint && injectedEntryPoint !== '{ENTRYPOINT}') ? injectedEntryPoint as EntryPoint : EntryPoint.Meetings;
const skypetoken = (injectedSkypetoken && injectedSkypetoken !== '{SKYPETOKEN}') ? injectedSkypetoken : undefined;

const rawTheme = UrlHelper.getQueryParameter('theme') as any;
let theme: Themes | undefined;
if (Object.values(Themes).includes(rawTheme)) {
    theme = rawTheme as Themes;
}

logger.init();
ReactDOM.render(<App language={ language } theme={ theme } basePath={ basePath } entryPoint={ entryPoint } skypetoken={ skypetoken } country={ dataRegion } />, container);
