import config from 'config';
import * as SyncTasks from 'synctasks';
import { logger, SilentLoginStatus } from './LoggerHelper';

export interface SilentLoginResult {
    skypetoken: string;
    expiration: number;
}

export const silentLoginAsync = () => {
    const initLogin = (): SyncTasks.Promise<SilentLoginResult | undefined> => {

        const silentLogin = (window as any).SKYPE.login.Silent;
        const deferral = SyncTasks.Defer<SilentLoginResult | undefined>();

        silentLogin.init(config.silentLogin)
            .setCallback((response: any) => {
                if (response && response.skypetoken) {
                    deferral.resolve({ skypetoken: response.skypetoken, expiration: response.expires_in });
                    return;
                }
                if (response && response.error) {
                    deferral.reject({ error: response.error, errorDescription: response.error_description || '' });
                    return;
                }
                deferral.reject();
            })
            .load();

        return deferral.promise();
    };

    const startTime = new Date().getTime();

    return initLogin()
        .done(() => logger.silentLogin({ status: SilentLoginStatus.Success, duration: new Date().getTime() - startTime }))
        .fail((response) => logger.silentLogin({
            status: SilentLoginStatus.Fail,
            duration: new Date().getTime() - startTime,
            error: response.error,
            errorDescription: response.errorDescription,
        }));
};
