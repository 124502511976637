import curry from 'lodash/curry';
import map from 'lodash/map';

export const doPostRedirect = (url: string, params: object) => {
    const form = buildForm(url, params);

    document.body.appendChild(form);
    form.submit();
};

/**
 * This helper is not using React to render the "utility form" because on Meet Now Edge page, React is not included in the package.
 *
 * @param url
 * @param params
 */
export const buildForm = (url: string, params: object): HTMLFormElement => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.style.display = 'none';
    form.action = url;

    addParameters(form, params);

    return form;
};

export const addParameters = (form: HTMLFormElement, params: object) => {
    map(params, addParameter(form));
};

export const addParameter = curry((form: HTMLFormElement, value: string, key: string) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;

    form.appendChild(input);
});
