import { localizer } from '@skype/bew-localization';
import { Button, ICSSInJSStyle, Input, Text } from '@stardust-ui/react';
import * as React from 'react';
import { WebErrorResponse } from 'simplerestclients';
import { EcsContext, EcsContextConsumer } from 'src/ts/contexts/EcsContext';
import MeetingsApiClient from 'src/ts/rest/MeetingsApiClient';
import { ErrorContext, ErrorContextConsumer } from '../../../contexts/ErrorContext';
import { MeetingContext, MeetingContextConsumer } from '../../../contexts/meetings/MeetingContext';
import { StackNavigationContext, StackNavigationContextConsumer } from '../../../contexts/StackNavigationContext';
import { DisplayableError, displayableErrors, ErrorHelper } from '../../../helpers/ErrorHelper';
import { logger } from '../../../helpers/LoggerHelper';
import { GuestJoinPayloadMeetingsApiWithCaptcha } from '../../../models/GuestJoinPayloadMeetingsApiWithCaptcha';
import { Captcha, CaptchaSolution } from '../../common/Captcha';
import { Spinner } from '../../common/Spinner';
import { GuestRedirect } from './GuestRedirect';

interface GuestLoginState {
    guestName: string;
    joinButtonEnabled: boolean;
    joinButtonSpinEnabled: boolean;
    captchaState: CaptchaState;
    captchaPayload: CaptchaPayload | undefined;
    error?: Error;
    ecsContext: EcsContext;
}

interface CaptchaState {
    show: boolean;
    ready: boolean;
    error?: string;
}

interface CaptchaPayload {
    challenge: string;
    challengeId: string;
    azureRegion: string;
}

interface JoinAsGuestBaseParams {
    meetingContext: MeetingContext;
    stackNavigationContext: StackNavigationContext;
    errorContext: ErrorContext;
}

interface JoinAsGuestParams extends JoinAsGuestBaseParams {
    ecsContext: EcsContext;
}

interface JoinAsGuestMeetingsApiParams extends JoinAsGuestBaseParams {
    joinThread?: boolean;
    retries?: number;
}

const maxGuestNameLength = 50;
export class GuestLogin extends React.PureComponent<{}, GuestLoginState> {
    private _captchaSolution?: CaptchaSolution;

    constructor(props: {}) {
        super(props);
        this.state = {
            guestName: '',
            joinButtonEnabled: false,
            joinButtonSpinEnabled: false,
            captchaPayload: undefined,
            captchaState: {
                show: false,
                ready: false,
            },
            ecsContext: {
                settings: {
                    useMeetingsApi: true,
                    useUnifiedView: false,
                    useJoinThread: false,
                    maxRetries: 0,
                },
            },
        };
    }

    componentWillUnmount() {
        delete this._captchaSolution;
    }

    componentDidUpdate(_prevProps: {}, prevState: GuestLoginState) {
        if (prevState.captchaState.show && !this.state.captchaState.show) {
            delete this._captchaSolution;
        }
    }

    render() {
        return <form className='body guestLogin'>
            { this._renderTitle() }
            { this._renderExpirationWarning() }
            { this._renderInput() }
            { this.state.captchaState.show && this.state.captchaPayload &&
                <Captcha challenge={this.state.captchaPayload.challenge}
                    challengeId={this.state.captchaPayload.challengeId}
                    azureRegion={this.state.captchaPayload.azureRegion}
                    onSolutionUpdated={this._onCaptchaSolutionUpdated} /> }
            { this.state.captchaState.show && !this.state.captchaState.ready && <div>
                <div className='shimmer-container column'>
                    <div className='shimmer captcha' style={ { width: 216, height: 96 } }></div>
                </div>
            </div> }
            { this.state.captchaState.error && <Text size='medium' styles={ { color: 'darkred', marginTop: '0.5rem' } } weight='bold'>* { this.state.captchaState.error }</Text> }

            <div className='actions'>
                <StackNavigationContextConsumer>{ (stackNavigationContext) =>
                    <>
                        <EcsContextConsumer>{ (ecsContext) =>
                            <ErrorContextConsumer>{ (errorContext) =>
                                <MeetingContextConsumer>{ (meetingContext) => meetingContext && meetingContext.conversation &&
                                    <>
                                        { this._renderTosAndPrivacy() }
                                        <Button id='btnCreateGuest'
                                            onClick={ this._createGuest(stackNavigationContext, meetingContext, errorContext, ecsContext) }
                                            disabled={ !this.state.joinButtonEnabled }>
                                            { this.state.joinButtonSpinEnabled && <Spinner /> }
                                            <Text weight='semibold'>{ localizer.getString('Common.btn_join') }</Text>
                                        </Button>
                                    </>
                                }</MeetingContextConsumer>
                            }</ErrorContextConsumer>
                        }</EcsContextConsumer>
                        <Button id='btnBack' onClick={ stackNavigationContext.pop } text={ true }>
                            <Text styles={ { color: 'white' } } weight='semibold'>{ localizer.getString('Common.btn_back') }</Text>
                        </Button>
                    </>
                }</StackNavigationContextConsumer>
            </div>
        </form>;
    }

    private _renderTitle() {
        return <Text size='larger' styles={ { marginTop: '2rem' } }>
            { localizer.getString('Login.btn_join_as_guest') }
        </Text>;
    }

    private _renderExpirationWarning() {
        return (
        <div>
            <EcsContextConsumer>{ (ecsContext) =>
                ecsContext.settings.useUnifiedView ? <Text size='small' styles={ { marginTop: '0.2rem', color: '#a8abad' } }>
                { localizer.getString('Login.txt_guest_account_expiration') }
            </Text> : <></>
            }</EcsContextConsumer>
        </div>);
    }

    private _renderInput() {
        const styles: ICSSInJSStyle = {
            fontSize: '1rem',
            marginTop: '2rem',
            marginBottom: '1rem',
        };
        return <Input
            id='inputGuestName'
            value={ this.state.guestName }
            onChange={ this._updateGuestName }
            autoFocus
            styles={ styles }
            placeholder={ localizer.getString('Login.txt_placeholder_enter_name') } />;
    }

    private _renderTosAndPrivacy() {
        const styles: ICSSInJSStyle = {
            fontSize: '0.75rem',
            marginBottom: '1rem',
            textAlign: 'center',
        };
        return <Text styles={ styles }>
            { localizer.get('txt_launch_guest_accept_tos', {
                linkPrivacy: { type: 'a', href: 'https://go.microsoft.com/fwlink/?LinkId=521839' },
                linkTerms: { type: 'a', href: 'https://go.microsoft.com/fwlink/?LinkID=246338' },
            }) }
        </Text>;
    }

    private _updateGuestName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value && event.target.value.length > maxGuestNameLength) {
            return;
        }

        this.setState({
            guestName: event.target.value,
            joinButtonEnabled: !!event.target.value && event.target.value.length > 0,
        });
    }

    private _createGuest = (stackNavigationContext: StackNavigationContext, meetingContext: MeetingContext, errorContext: ErrorContext, ecsContext: EcsContext) => {
        return (e: React.MouseEvent<HTMLButtonElement>) => {
            const joinParams: JoinAsGuestParams = {
                stackNavigationContext,
                meetingContext,
                errorContext,
                ecsContext,
            };

            logger.action({ name: 'create-guest' });
            this.setState({ joinButtonEnabled: false, joinButtonSpinEnabled: true });

            if (this.state.captchaState.show && this._captchaSolution) {
                this._joinAsGuest(joinParams);
            } else {
                this._joinAsGuest(joinParams);
            }
            e.preventDefault();
            e.stopPropagation();
        };
    }

    private _joinAsGuest = (params: JoinAsGuestParams) => {
        const { ecsContext, ...joinParams } = params;
        const ecsSettings = ecsContext.settings;

        logger.sessionFinished({completionType: 'JoinOnWebAsGuest'});

        return this.joinAsGuestMeetingsApi({
                ...joinParams,
                retries: ecsSettings.maxRetries,
                joinThread: ecsSettings.useJoinThread,
            });
    }

    private joinAsGuestMeetingsApi = (params: JoinAsGuestMeetingsApiParams) => {
        return MeetingsApiClient.joinAsGuest<GuestJoinPayloadMeetingsApiWithCaptcha>({
            flowId: logger.getCorrelationId(),
            displayName: this.state.guestName,
            threadId: params.meetingContext.conversation.resource,
            shortId: params.meetingContext.shortId,
        },
            params.retries,
            params.joinThread,
            this._captchaSolution,
            () => ({ includesCaptcha: !!this._captchaSolution }))
        .then((guestUser) => {
            params.stackNavigationContext.push(
                <GuestRedirect skypetoken={ guestUser.skypetoken } username={ guestUser.skypeId } />,
                { eventViewName: 'guest-redirect' },
            );
        }).catch((response: WebErrorResponse<any>) => {
            this.setState((oldState) => {
                const newState: GuestLoginState = { ...oldState, joinButtonSpinEnabled: false, joinButtonEnabled: true };

                const createGuestRequest = 'Create guest user';
                let error: DisplayableError | undefined;
                this._captchaSolution = undefined;
                switch (response.statusCode) {
                    case 403:
                        error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.JoinDisabled, response, createGuestRequest);
                        break;
                    case 404:
                        error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.InviteNotFound, response, createGuestRequest);
                        break;
                    case 412: // @todo review all response codes
                        error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.TooManyUsers, response, createGuestRequest);
                        break;
                    case 429:
                        newState.joinButtonEnabled = false;
                        newState.captchaState = {
                            ready: true,
                            show: true,
                        };
                        newState.captchaPayload = {
                            challenge: response.body.challenge,
                            challengeId: response.body.challengeId,
                            azureRegion: response.body.azureRegion,
                        };
                        error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.TooManyUsers, response, createGuestRequest);
                        break;
                    case 500:
                        error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.Generic, response, createGuestRequest);
                        break;
                    default:
                        error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.Busy, response, createGuestRequest);
                        break;
                }
                if (error) {
                    params.errorContext.setError(error);
                    logger.error(error);
                }

                return newState;
            });
        });
    }

    private _onCaptchaSolutionUpdated = (solutionValue: string) => {
        if (solutionValue && this.state.captchaPayload) {
            if (this._captchaSolution) {
                this._captchaSolution.solution = solutionValue;
            } else {
                this._captchaSolution = {
                    solution: solutionValue,
                    challengeId: this.state.captchaPayload.challengeId,
                    azureRegion: this.state.captchaPayload.azureRegion,
                };
            }
            this.setState({
                joinButtonEnabled: true,
            });
        } else if (!solutionValue && this.state.joinButtonEnabled) {
            this.setState({
                joinButtonEnabled: false,
            });
        }
    }
}
