import config from 'config';
import * as SyncTasks from 'synctasks';
import { BotInfoServiceModel } from '../models/BotServiceModel';
import { ApiCallOptions, BaseRestClient } from './BaseRestClient';

class BotsRestClient extends BaseRestClient {
    getBotInfo(botId: string): SyncTasks.Promise<BotInfoServiceModel> {
        const basePath = '/v1/bots/info/';
        const options: ApiCallOptions = {
            eventProperties: {
                genericEventPath: basePath,
            },
        };

        return this.performApiGet<BotInfoServiceModel>(basePath + botId, options);
    }

    addBotToContacts(botId: string, skypetoken: string) {
        const basePath = '/v1/bots/add/';
        const options: ApiCallOptions = {
            headers: {
                'X-Skypetoken': skypetoken,
            },
            eventProperties: {
                genericEventPath: basePath,
            },
        };

        return this.performApiPut(basePath + botId, {}, options);
    }
}

export default new BotsRestClient(config.urls.api.huddle);
