import { AWTEventPriority } from '@aria/webjs-sdk';
import { ApiCallOptions as BaseApiCallOptions, GenericRestClient, WebResponse } from 'simplerestclients';
import * as SyncTasks from 'synctasks';
import { logger } from '../helpers/LoggerHelper';

export interface EventProperties {
    priority?: AWTEventPriority;
    genericEventPath?: string; // Override default path for telemtry if it contains sensitive information
    customPropertiesProvider?: ((response: WebResponse<any>) => any); // Append any custom field from response to the event
}

export interface ApiCallOptions extends BaseApiCallOptions {
    eventProperties?: EventProperties;
}

const unwrap: <T>(resp: WebResponse<T>) => T = (resp) => resp.body;
export class BaseRestClient extends GenericRestClient {
    protected _defaultOptions: ApiCallOptions = {
        contentType: 'application/json',
        acceptType: 'application/json',
    };

    performApiGet<T>(apiPath: string, options?: ApiCallOptions): SyncTasks.Promise<T> {
        return this.performApiGetDetailed<T>(apiPath, options).then(unwrap);
    }

    performApiGetDetailed<T>(apiPath: string, options?: ApiCallOptions): SyncTasks.Promise<WebResponse<T, ApiCallOptions>> {
        const startTime = new Date().getTime();
        const promise = super.performApiGetDetailed<T>(apiPath, options);
        promise.then(this.networkCallback(startTime, options), this.networkCallback(startTime, options));
        return promise;
    }

    performApiPost<T>(apiPath: string, objToPost: any, options?: ApiCallOptions): SyncTasks.Promise<T> {
        return this.performApiPostDetailed<T>(apiPath, objToPost, options).then(unwrap);
    }

    performApiPostDetailed<T>(apiPath: string, objToPost: any, options?: ApiCallOptions): SyncTasks.Promise<WebResponse<T, ApiCallOptions>> {
        const startTime = new Date().getTime();
        const promise = super.performApiPostDetailed<T>(apiPath, objToPost, options);
        promise.then(this.networkCallback(startTime, options), this.networkCallback(startTime, options));
        return promise;
    }

    performApiPatch<T>(apiPath: string, objToPatch: any, options?: ApiCallOptions): SyncTasks.Promise<T> {
        return this.performApiPatchDetailed<T>(apiPath, objToPatch, options).then(unwrap);
    }

    performApiPatchDetailed<T>(apiPath: string, objToPatch: any, options?: ApiCallOptions): SyncTasks.Promise<WebResponse<T, ApiCallOptions>> {
        const startTime = new Date().getTime();
        const promise = super.performApiPatchDetailed<T>(apiPath, objToPatch, options);
        promise.then(this.networkCallback(startTime, options), this.networkCallback(startTime, options));
        return promise;
    }

    performApiPut<T>(apiPath: string, objToPut: any, options?: ApiCallOptions): SyncTasks.Promise<T> {
        return this.performApiPutDetailed<T>(apiPath, objToPut, options).then(unwrap);
    }

    performApiPutDetailed<T>(apiPath: string, objToPut: any, options?: ApiCallOptions): SyncTasks.Promise<WebResponse<T, ApiCallOptions>> {
        const startTime = new Date().getTime();
        const promise = super.performApiPutDetailed<T>(apiPath, objToPut, options);
        promise.then(this.networkCallback(startTime, options), this.networkCallback(startTime, options));
        return promise;
    }

    performApiDelete<T>(apiPath: string, objToDelete?: any, options?: ApiCallOptions): SyncTasks.Promise<T> {
        return this.performApiDeleteDetailed<T>(apiPath, objToDelete, options).then(unwrap);
    }

    performApiDeleteDetailed<T>(apiPath: string, objToDelete: any, options?: ApiCallOptions): SyncTasks.Promise<WebResponse<T, ApiCallOptions>> {
        const startTime = new Date().getTime();
        const promise = super.performApiDeleteDetailed<T>(apiPath, objToDelete, options);
        promise.then(this.networkCallback(startTime, options), this.networkCallback(startTime, options));
        return promise;
    }

    protected networkCallback = (startTime: number, options?: ApiCallOptions): ((response: WebResponse<any>) => void) => {
        const eventProperties = options && options.eventProperties;
        const genericEventPath = eventProperties && eventProperties.genericEventPath;
        const customPropertiesProvider = eventProperties && eventProperties.customPropertiesProvider;
        const eventPriority = eventProperties && eventProperties.priority;
        return (response: WebResponse<any>) => {
            const endTime = new Date().getTime();
            const responseUrl = genericEventPath ? this._endpointUrl + genericEventPath : response.url;
            const eventUrl = responseUrl ? responseUrl.split('?', 1).join('') : responseUrl;
            const path = eventUrl ? eventUrl.replace(this._endpointUrl, '') : eventUrl;
            logger.network({
                method: response.method,
                responseTime: endTime - startTime,
                statusCode: response.statusCode,
                statusText: response.statusText,
                url: eventUrl,
                host: this._endpointUrl,
                path,
                custom: customPropertiesProvider ? customPropertiesProvider(response) : undefined,
            }, eventPriority);
        };
    }
}
