import { AWTEventPriority, AWTPiiKind, AWTPropertyType } from '@aria/webjs-sdk';
import config from 'config';
import curry from 'lodash/curry';
import { CookieHelper } from '../helpers/CookieHelper';
import { CommonProperty, logger } from '../helpers/LoggerHelper';
import { doPostRedirect } from '../helpers/RedirectHelper';
import { SilentLoginResult } from '../helpers/SilentLoginHelper';
import { getMsaCidHexFromSkypeToken, getSkypeIdFromSkypeToken } from '../helpers/SkypeTokenHelper';
import { UrlHelper } from '../helpers/UrlHelper';
import { ConversationServiceModel } from '../models/ConversationServiceModel';
import {
    CreateMeetingServiceModel,
    MeetnowGuestServiceModel,
    WebClientRedirectParams,
} from '../models/GuestUserServiceModel';
import { AppIds } from '../models/SharedTypes';
import MeetingsApiClient from '../rest/MeetingsApiClient';
import { triggerFallbackFlow } from './FallbackFlow';
import { IMeetNowSettings } from './MeetNowSettings';

export const getShortIdFromJoinLink = (joinLink: string): string => joinLink.split('/').pop() || '';

export const getSource = () => {
    const source = UrlHelper.getQueryParameter('source');
    return source ? `${source}` : undefined;
};

export const logShortIdAndThreadId = (meeting: CreateMeetingServiceModel): CreateMeetingServiceModel => {
    logger.initCommonProperty({
        key: CommonProperty.ShortId,
        value: getShortIdFromJoinLink(meeting.joinLink),
        piiKind: AWTPiiKind.GenericData,
        type: AWTPropertyType.String,
    });
    logger.initCommonProperty({
        key: CommonProperty.ThreadId,
        value: meeting.threadId,
        piiKind: AWTPiiKind.GenericData,
        type: AWTPropertyType.String,
    });

    return meeting;
};

export const logUserId = (meeting: MeetnowGuestServiceModel): MeetnowGuestServiceModel => {
    logger.addSkypeIdAndMsaCidHexCommonProperties(meeting.skypetoken, true);

    return meeting;
};

export const createConversationServiceModel = (meeting: CreateMeetingServiceModel): ConversationServiceModel => {
    return {
        shortId: getShortIdFromJoinLink(meeting.joinLink),
        resource: meeting.threadId,
        id: '',
        flowId: '',
        action: '',
    };
};

export const createGuestMeeting = (settings: IMeetNowSettings, source = 'HuddleUi') => {
    return MeetingsApiClient.createGuestMeeting({ title: settings.DefaultMeetingTitle, source }, settings.MeetNowEdgeNumberOfFetchRetries);
};

export const createMeetnowGuest = (settings: IMeetNowSettings, source = 'HuddleUi') => {
    const localizedGuestDefaultName = document.getElementById('meetnowContainer')?.getAttribute('data-guest-user-localized-text') ?? 'Guest user';
    return MeetingsApiClient.meetnowGuest({
        displayName: localizedGuestDefaultName,
        source,
    }, settings.MeetNowEdgeNumberOfFetchRetries);
};

export const createAuthenticatedMeeting = (settings: IMeetNowSettings, loginResult: SilentLoginResult, source = 'HuddleUi') => {
    return MeetingsApiClient
        .createAuthenticatedMeeting({ title: settings.DefaultMeetingTitle, SkypeToken: loginResult.skypetoken, source }, settings.MeetNowEdgeNumberOfFetchRetries);
};

export const getThreadId = curry((settings: IMeetNowSettings, shortId: string) => {
    const params = {
        shortId,
        type: AppIds.Meetings,
    };

    return MeetingsApiClient
        .getConversation(params, settings.MeetNowEdgeNumberOfFetchRetries)
        .then((conversion) => {
            logger.initCommonProperty({
                key: CommonProperty.ThreadId,
                value: conversion.resource,
                piiKind: AWTPiiKind.GenericData,
                type: AWTPropertyType.String,
            });
            return conversion;
        })
        .then((conversion) => ({ shortId, ...conversion }));
});

export const buildWebClientRedirectParamsForAuthenticatedFlow =
    curry((loginResult: SilentLoginResult, conversationServiceModel: ConversationServiceModel): WebClientRedirectParams => {
        return {
            threadId: conversationServiceModel.resource,
            shortId: conversationServiceModel.shortId,
            skypetoken: loginResult.skypetoken,
            skypeId: getSkypeIdFromSkypeToken(loginResult.skypetoken) || '',
        };
    });

export const buildWebClientRedirectParamsFromMeetnowGuestServiceModel = (meetnowGuestServiceModel: MeetnowGuestServiceModel): WebClientRedirectParams => {
    return {
        skypetoken: meetnowGuestServiceModel.skypetoken,
        skypeId: meetnowGuestServiceModel.skypeId,
        threadId: meetnowGuestServiceModel.threadId,
        shortId: getShortIdFromJoinLink(meetnowGuestServiceModel.joinLink),
    };
};
export const redirectToSkypeClient = curry((
    settings: IMeetNowSettings,
    completionType: string,
    action: string,
    isNewJoinFlowFromJoinLink: boolean,
    webClientRedirectParams: WebClientRedirectParams): void => {
    const params = {
        skypetoken: webClientRedirectParams.skypetoken,
        expires_in: 24 * 3600,
        skypeid: webClientRedirectParams.skypeId,
        state: CookieHelper.getCookie(config.cookies.csrf).singleValue,
        msacidhex: getMsaCidHexFromSkypeToken(webClientRedirectParams.skypetoken) || '',
    };

    logger.sessionFinished({ completionType }, AWTEventPriority.High);

    const queryParams = `action=${action}&${buildRedirectQueryParameters(settings.MeetNowEdgeAllowedQueryParameters, logger.getCorrelationId(), isNewJoinFlowFromJoinLink)}`;
    doPostRedirect(`${UrlHelper.getWebClientUrl({ threadId: webClientRedirectParams.threadId })}?${queryParams}`, params);
});

export const buildRedirectQueryParameters = (allowedParameters: string[], fallbackCorrelationId: string, isNewJoinFlowFromJoinLink = false): string => {
    const params = UrlHelper.getAllowedQueryParameters(allowedParameters);

    if (!params.find((param) => param.key === 'correlationId')) {
        params.push({ key: 'correlationId', value: fallbackCorrelationId });
    }

    if (isNewJoinFlowFromJoinLink) {
        params.push({ key: 'source', value: 'NewJoinLinkFlow' });
    }

    return params
        .map((param) => `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`)
        .join('&');
};

export const processMeetNowEdgeGuestRequest = (settings: IMeetNowSettings): void => {
    createMeetnowGuest(settings, getSource())
        .then(logShortIdAndThreadId)
        .then(logUserId)
        .then(buildWebClientRedirectParamsFromMeetnowGuestServiceModel)
        .then(redirectToSkypeClient(settings, 'MeetNowEdgeIntegration', 'createmeetnow', false))
        .catch((error) => triggerFallbackFlow(error, 'processMeetNowEdgeGuestRequest failure'));
};

export const processMeetNowEdgeAuthenticatedRequest = (settings: IMeetNowSettings, loginResult: SilentLoginResult): void => {
    createAuthenticatedMeeting(settings, loginResult, getSource())
        .then(logShortIdAndThreadId)
        .then(createConversationServiceModel)
        .then(buildWebClientRedirectParamsForAuthenticatedFlow(loginResult))
        .then(redirectToSkypeClient(settings, 'MeetNowEdgeIntegration', 'createmeetnow', false))
        .catch((error) => triggerFallbackFlow(error, 'processMeetNowEdgeAuthenticatedRequest failure'));
};
