import * as React from 'react';
import { ConversationServiceModel } from '../../models/ConversationServiceModel';

export interface MeetingContext {
    conversation: ConversationServiceModel;
    shortId: string;
}

// Default context does not mean much sense here
const context = React.createContext<MeetingContext | undefined>(undefined);

export const MeetingContextProvider = context.Provider;

export const MeetingContextConsumer = context.Consumer;
