import config from 'config';
import { DoContext } from 'src/ts/contexts/do/DoContext';
import { OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { logger } from '../../helpers/LoggerHelper';
import { ALauncher } from '../common/ALauncher';

interface LauncherProps {
    do: DoContext;
}

export class Launcher extends ALauncher<LauncherProps> {

    protected _getLauncherUrl() {
        if (os === OperatingSystems.Android) {
            return config.urls.doLauncher.intentUri(this.props.do.action, this.props.do.skypeId, logger.getCorrelationId(), 'launcher', config.urls.app.download);
        }
        return config.urls.doLauncher.skypeUri(this.props.do.action, this.props.do.skypeId, logger.getCorrelationId(), 'launcher');
    }

    protected _getLoggerAction() {
        return 'join-do';
    }
}
