import { AWTPiiKind } from '@aria/webjs-sdk';
import { localizer } from '@skype/bew-localization';
import * as React from 'react';
import { WebErrorResponse } from 'simplerestclients';
import { AriaContextConsumer } from 'src/ts/contexts/AriaContext';
import { EcsSettings } from 'src/ts/contexts/EcsContext';
import { UserContext } from 'src/ts/contexts/UserContext';
import { UrlHelper } from 'src/ts/helpers/UrlHelper';
import getMeetNowSettings from 'src/ts/meetnow/MeetNowSettings';
import { handleJoin } from 'src/ts/meetnowJoin/JoinHandler';
import { AppIds } from 'src/ts/models/SharedTypes';
import HuddleApiClient from 'src/ts/rest/HuddleApiClient';
import MeetingsApiClient from 'src/ts/rest/MeetingsApiClient';
import { MeetingContext, MeetingContextProvider } from '../../contexts/meetings/MeetingContext';
import { canJoinOnWeb, deviceType, DeviceTypes } from '../../helpers/EnvironmentHelper';
import { displayableErrors, ErrorHelper } from '../../helpers/ErrorHelper';
import { CommonProperty, logger } from '../../helpers/LoggerHelper';
import { ARootView, ARootViewProps } from '../common/ARootView';
import { LoadingView } from '../common/LoadingView';
import { StackNavigationView } from '../common/StackNavigationView';
import { JoinView } from './JoinView';
import { Launcher } from './Launcher';
import { UnifiedView } from './UnifiedView';

export interface RootViewState {
    launcherKey: number;
    meetingContext?: MeetingContext;
    error?: Error;
    loadingConversation: boolean;
}

interface RootViewProps extends ARootViewProps {
    userContext: UserContext;
    ecsSettings: EcsSettings;
    country?: string;
}

enum SilentLoginStatus {
    Started,
    Ended,
    Skipped,
}

export class RootView extends ARootView<RootViewProps, RootViewState> {

    private _silentLoginStatus: SilentLoginStatus;
    private _preRequisitesLoadCompleted = false;

    constructor(props: RootViewProps) {
        super(props);

        this.state = {
            launcherKey: deviceType === DeviceTypes.Desktop ? 1 : 0,
            loadingConversation: true,
        };

        this._silentLoginStatus = !!props.userContext.silentLoginNotSupported ? SilentLoginStatus.Skipped : SilentLoginStatus.Started;
    }

    componentDidMount() {
        if (this.props.userContext && !this.props.userContext.isLoginInProgress && !this.props.loading) {
            this.loadConversation();
        }
    }

    componentDidUpdate(prevProps: RootViewProps) {
        if (prevProps.loading && !this.props.loading) {
            document.title = localizer.getString('hdr_launch_title');
        }

        if (prevProps && !!prevProps.userContext &&
            prevProps.userContext.isLoginInProgress && !this.props.userContext.isLoginInProgress) {
            this._silentLoginStatus = SilentLoginStatus.Ended;
        }

        if (prevProps.loading && !this.props.loading) {
            this._preRequisitesLoadCompleted = true;
        }

        if ((this._silentLoginStatus === SilentLoginStatus.Skipped || this._silentLoginStatus === SilentLoginStatus.Ended) &&
            this._preRequisitesLoadCompleted &&
            !this.state.meetingContext &&
            !this.state.error) {
            this.loadConversation();
        }
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        const conversation = this.state.meetingContext ? this.state.meetingContext.conversation : undefined;
        const isLoading = this.props.loading || this.state.loadingConversation;
        return <MeetingContextProvider value={ this.state.meetingContext }>
            { !!this.state.launcherKey && conversation && <Launcher key={ this.state.launcherKey } conversation={ conversation } /> }
            <AriaContextConsumer>{ (ariaContext) => {
                if (isLoading) {
                    return <LoadingView />;
                } else {
                    return <StackNavigationView
                                ariaContext={ ariaContext }
                                loading={ isLoading }
                                initialView={this.getLoginView()}
                                initialViewProps={ { eventViewName: 'join-view' } } />;
                }
            }
            }</AriaContextConsumer>
        </MeetingContextProvider>;
    }

    private getLoginView = () => {
        const useUnifiedView = this.determineIsItUnifiedView(this.props.ecsSettings.useUnifiedView);
        const meetNowSettings = getMeetNowSettings();
        if (useUnifiedView) {
            return <UnifiedView
                country={this.props.country}
                onLaunchClick={ this._onLaunchClick }
                allowDesktopSafariGuestFlow={ !!this.props.ecsSettings.allowDesktopSafariGuestFlow }
                meetNowSettings={ meetNowSettings } />;
        }

        const threadId = this.state.meetingContext && this.state.meetingContext.conversation ? this.state.meetingContext.conversation.resource : undefined;
        if (deviceType !== DeviceTypes.Mobile || threadId === undefined) {
            return <JoinView onLaunchClick={ this._onLaunchClick } shortId={ this._getShortIdFromUrl() } threadId={ threadId } />;
        } else {
            const legacyMobileFlowView =
                <JoinView onLaunchClick={ this._onLaunchClick } shortId={ this._getShortIdFromUrl() } threadId={ threadId } />;
            if (canJoinOnWeb && !!this.props.ecsSettings.useNewMobileJoinFlow) {
                try {
                    if (meetNowSettings) {
                        // For supported mobile browsers, attempt to go straight to a call if we didn't deep-link into the app already
                        // (since most likely, the app is not installed on the device, or the user pasted the link in the browser)
                        handleJoin(meetNowSettings, {
                            action: 'joinmeetnow',
                            flowId: logger.getCorrelationId(),
                            id: '',
                            resource: threadId,
                            shortId: this._getShortIdFromUrl(),
                        }, this.props.userContext?.skypetoken, true).catch((err) => {
                            logger.error(err);
                            this.setState({
                                error: err,
                            });
                        });
                        return null;
                    } else {
                        return legacyMobileFlowView;
                    }
                } catch (err) {
                    logger.error(err);
                    return legacyMobileFlowView;
                }
            } else {
                // For unsupported mobile browsers, fallback to old mobile join page allowing users to "Download Skype"
                return legacyMobileFlowView;
            }
        }
    }

    private determineIsItUnifiedView(unifiedViewEcsSettings: boolean) {
        return (unifiedViewEcsSettings && deviceType === DeviceTypes.Desktop);
    }

    private loadConversation = () => {
        const shortId = this._getShortIdFromUrl();

        if (this.props.userContext) {
            if (this.props.ecsSettings.useMeetingsApi) {
                this.loadConversationFromMeetingsApi(shortId);
            } else {
                this.loadConversationFromHuddleApi(shortId);
            }
        }
    }

    private loadConversationFromHuddleApi(shortId: string) {
        HuddleApiClient.getConversation({
            shortId,
            type: AppIds.Meetings,
        }).then((conversation) => {
            this.setState({
                loadingConversation: false,
                meetingContext: {
                    conversation,
                    shortId,
                },
            });
            logger.initCommonProperty({ key: CommonProperty.ThreadId, value: conversation.resource, piiKind: AWTPiiKind.GenericData });
        }).catch((response: WebErrorResponse) => {
            const error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.ShortIdFromUrl, response, 'Get conversation details');
            this.setState({
                error,
            });
        });
    }

    private loadConversationFromMeetingsApi(shortId: string) {
        MeetingsApiClient.getConversation({
            shortId,
            type: AppIds.Meetings,
        }, this.props.ecsSettings.maxRetries).then((conversation) => {
            this.setState({
                loadingConversation: false,
                meetingContext: {
                    conversation,
                    shortId,
                },
            });
            logger.initCommonProperty({ key: CommonProperty.ThreadId, value: conversation.resource, piiKind: AWTPiiKind.GenericData });
        }).catch((response: WebErrorResponse) => {
            const error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.ShortIdFromUrl, response, 'Get conversation details');
            this.setState({
                error,
            });
        });
    }

    private _getShortIdFromUrl(): string {
        return UrlHelper.getShortIdFromUrl();
    }

    private _onLaunchClick = () => {
        logger.sessionFinished({completionType: 'LaunchSkypeNowButton'});
        this.setState((oldState) => {
            return { launcherKey: oldState.launcherKey + 1 };
        });
    }
}
