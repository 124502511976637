import * as React from 'react';

export class DomHelper {

    public static fakeButtonAccessibilityKey = (e: React.KeyboardEvent<HTMLElement>, callback?: (e: React.SyntheticEvent<HTMLElement>) => void) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if (!!callback) {
                callback(e);
            } else {
                e.currentTarget.click();
            }
        }
    }

    public static setFocus = (element: HTMLElement | null) => {
        if (element) {
            element.focus();
        }
    }
}
