import * as React from 'react';

import { localizer } from '@skype/bew-localization';
import { Avatar, Header, Image, Text } from '@stardust-ui/react';
import { BotsContextConsumer } from '../../contexts/bots/BotsContext';
import { ConfigContext, ConfigContextConsumer } from '../../contexts/ConfigContext';
import { BotInfoServiceModel } from '../../models/BotServiceModel';

export class BotInfo extends React.PureComponent {

    render() {
        return <section className='botInfoContainer'>
            <BotsContextConsumer>
                { (botContext) => botContext &&
                    <>
                        <ConfigContextConsumer>
                            { (configContext) => this._renderAvatar(configContext, botContext.botInfo) }
                        </ConfigContextConsumer>
                        { botContext.botInfo.isTrusted && <div>
                                <Text size='medium' className='certified' content={ localizer.getString('skype_certified') }/>
                                <ConfigContextConsumer>{ (configContext) =>
                                    <Image src={ `${ configContext.basePath }/images/AvatarBotCertified.svg` } aria-hidden='true' /> }
                                </ConfigContextConsumer>
                            </div>
                        }
                        <Header as='h2' content={ botContext.botInfo.name } />
                        <Header as='h3' content={ botContext.botInfo.developer } />
                    </>
                }
            </BotsContextConsumer>
        </section>;
    }

    private _renderAvatar(configContext: ConfigContext, botInfo: BotInfoServiceModel) {
        const avatarImage = {
            src: botInfo.avatarUrl || `${ configContext.basePath }/images/avatar.jpg`,
            styles: { width: '100%', height: '100%', objectFit: 'cover' },
        };

        return <div style={{position: 'relative'}}>
                <Avatar styles={ { margin: '10px 0px' } } size={ 100 } image={ avatarImage } />
                <Image src={`${ configContext.basePath }/images/agentMask.svg`} className='avatarMask'/>
            </div>;
    }
}
