import { localizer } from '@skype/bew-localization';
import { Avatar, Button, Header, Text } from '@stardust-ui/react';
import config from 'config';
import * as React from 'react';
import { InviteContext, InviteContextConsumer } from 'src/ts/contexts/invites/InviteContext';
import { logger } from 'src/ts/helpers/LoggerHelper';
import { UrlHelper } from 'src/ts/helpers/UrlHelper';
import { CookieHelper } from '../../helpers/CookieHelper';
import { canJoinOnWeb, deviceType, DeviceTypes, OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { Footer } from '../common/Footer';
import { Header as PageHeader } from '../common/Header';
import { Padder } from '../common/Padder';

interface InviteViewProps {
    onLaunchClick: () => void;
}

export class InviteView extends React.PureComponent<InviteViewProps, React.StatelessComponent> {

    renderAvatar() {
        return <InviteContextConsumer>{ (inviteContext) => inviteContext &&
            <Avatar styles={ { margin: '10px 0px' } } size={ 100 } image={ {
                src: `https://avatar.skype.com/v1/avatars/${ inviteContext.invite.inviterSkypeId }/public?size=m&returnDefaultImage=true`,
                styles: { width: '100%', height: '100%', objectFit: 'cover' },
            } } />
        }</InviteContextConsumer>;
    }

    renderTitle() {
        return <InviteContextConsumer>{ (inviteContext) => inviteContext &&
            <Header as='h1' className='title'>{ localizer.getString('txt_off_skype_invites_join_skype', { user_name: inviteContext.invite.inviterName }) }</Header>
        }</InviteContextConsumer>;
    }

    renderDesktopBody() {
        if (deviceType !== DeviceTypes.Desktop) {
            return null;
        }

        return <Text size={ 'medium' } className='body'>
            { localizer.getString('Meetings.txt_join_meeting_description') }&nbsp;
            { <Button
                key={ 0 }
                id='btnLaunchNow'
                onClick={ this.props.onLaunchClick }
                className='fakeLink'
                aria-label={ `${ localizer.getString('Meetings.txt_join_meeting_description') } ${ localizer.getString('Meetings.lnk_join_meeting_retry') }` }
                text={ true }>{ localizer.getString('Meetings.lnk_join_meeting_retry') }
            </Button> }
        </Text>;
    }

    renderControls() {
        if (deviceType === DeviceTypes.Mobile) {
            return <Button key={ 0 } id='btnLaunch' primary={ true } onClick={ this.props.onLaunchClick }>
                { localizer.getString('btn_launch_join') }
            </Button>;
        }

        return <InviteContextConsumer>{ (inviteContext) => inviteContext &&
            <>
                { canJoinOnWeb && <Button key={ 0 } id='btnJoin' primary={ true } autoFocus
                    onClick={ this._joinOnWeb(inviteContext) }>
                    { localizer.getString('Meetings.btn_join_on_web') }
                </Button> }

                <Button key={ 1 } id='btnDownload' primary={ !canJoinOnWeb } text={ canJoinOnWeb } onClick={ this._downloadSkype() }>
                    { localizer.getString('btn_launch_download_skype') }
                </Button>
            </>
        }</InviteContextConsumer>;
    }

    renderMobileBody() {
        if (deviceType !== DeviceTypes.Mobile) {
            return null;
        }

        return <>
            <Text size={ 'medium' } className='body'>
                { localizer.getString('txt_launch_first_time_device') }
            </Text>
            <Text size={ 'medium' }>
                { localizer.get('txt_launch_please_download', {
                    download_skype: <a href='javascript:void(0);' id='downloadMobileClient'
                        onClick={ this._downloadSkype() } style={ { textDecoration: 'none', color: '#0078d4' } }>
                        { localizer.getString('btn_launch_download_skype') }
                    </a>,
                }) }
            </Text>
        </>;
    }

    render() {
        return <div className='invites inviteView'>
            <PageHeader />
            <Padder />
            <div className='scrollable-y'>
                { this.renderAvatar() }
                { this.renderTitle() }
                { this.renderControls() }
                { this.renderMobileBody() }
                { this.renderDesktopBody() }
            </div>
            <Padder />
            <Footer />
        </div>;
    }

    private _joinOnWeb = (inviteContext: InviteContext) => {
        return () => {
            logger.action({ name: 'join-login' });

            const webClientUrl = UrlHelper.getWebClientUrlWithCorrelationId({
                inviteId: inviteContext.invite.longId,
                skypeId: inviteContext.invite.inviterSkypeId,
            });
            window.location.href = config.urls.app.login(webClientUrl, CookieHelper.getCookie(config.cookies.csrf).singleValue);
        };
    }

    private _downloadSkype = () => {
        return () => {
            logger.action({ name: 'download' });
            const downloadUrl = os === OperatingSystems.MacOS ? config.urls.app.downloadDesktop : config.urls.app.download;
            window.open(downloadUrl, '_blank');
        };
    }
}
