import config from 'config';
import * as SyncTasks from 'synctasks';
import { UserProfile } from '../models/ProfileServiceModel';
import { ApiCallOptions, BaseRestClient } from './BaseRestClient';

class ProfileRestClient extends BaseRestClient {
    getUserProfileInfo(skypetoken: string): SyncTasks.Promise<UserProfile> {
        const uri = '/v1/users/self/profile';
        const options: ApiCallOptions = {
            headers: {
                'X-Skypetoken': skypetoken,
            },
        };

        return this.performApiGet<UserProfile>(uri, options);
    }
}

export default new ProfileRestClient(config.urls.api.profile);
