import { AWTPiiKind } from '@aria/webjs-sdk';
import { localizer } from '@skype/bew-localization';
import * as React from 'react';
import { WebErrorResponse } from 'simplerestclients';
import { AriaContextConsumer } from 'src/ts/contexts/AriaContext';
import { InviteContext, InviteContextProvider } from 'src/ts/contexts/invites/InviteContext';
import { AppIds } from 'src/ts/models/SharedTypes';
import HuddleApiClient from 'src/ts/rest/HuddleApiClient';
import { deviceType, DeviceTypes } from '../../helpers/EnvironmentHelper';
import { displayableErrors, ErrorHelper } from '../../helpers/ErrorHelper';
import { CommonProperty, logger } from '../../helpers/LoggerHelper';
import { ARootView, ARootViewProps } from '../common/ARootView';
import { StackNavigationView } from '../common/StackNavigationView';
import { InviteView } from './InviteView';
import { Launcher } from './Launcher';

export interface RootViewState {
    launcherKey: number;
    inviteContext?: InviteContext;
    error?: Error | undefined;
}

export class RootView extends ARootView<ARootViewProps, RootViewState> {

    constructor(props: ARootViewProps) {
        super(props);
        this.state = {
            launcherKey: deviceType === DeviceTypes.Desktop ? 1 : 0,
        };
    }

    componentDidMount() {
        const shortId = this._getShortIdFromUrl();
        if (!shortId) {
            throw displayableErrors.InviteIdFromUrl;
        }

        HuddleApiClient.getOffSkypeInvite({
            shortId,
            type: AppIds.OffSkypeInvites,
        }).then((invite) => {
            this.setState({
                inviteContext: { invite },
            });
            logger.initCommonProperty({ key: CommonProperty.InviteSkypeId, value: invite.inviterSkypeId, piiKind: AWTPiiKind.Identity });
        }).catch((response: WebErrorResponse) => {
            const error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.InviteNotFound, response, 'Get invite details');
            this.setState({
                error,
            });
        });
    }

    componentDidUpdate(prevProps: ARootViewProps) {
        if (prevProps.loading && !this.props.loading) {
            document.title = localizer.getString('hdr_launch_title');
        }
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        const invite = this.state.inviteContext ? this.state.inviteContext.invite : undefined;
        return <InviteContextProvider value={ this.state.inviteContext }>
            { !!this.state.launcherKey && invite && <Launcher key={ this.state.launcherKey } invite={ invite } /> }
            <AriaContextConsumer>{ (ariaContext) =>
                <StackNavigationView ariaContext={ ariaContext } loading={ this.props.loading } initialView={ <InviteView onLaunchClick={ this._onLaunchClick } /> }
                    initialViewProps={ { eventViewName: 'invite-view' } } />
            }</AriaContextConsumer>
        </InviteContextProvider>;
    }

    private _getShortIdFromUrl() {
        const pathName = window.location.pathname.replace(/\/+$/, '');
        const pathParts = pathName.split('/');

        return pathParts.pop();
    }

    private _onLaunchClick = () => {
        this.setState((oldState) => {
            return { launcherKey: oldState.launcherKey + 1 };
        });
    }
}
