import { ErrorHandlingType, WebErrorResponse } from 'simplerestclients';
import { ConversationServiceModel, RawConversationServiceModel } from '../models/ConversationServiceModel';

type Transform = (model: RawConversationServiceModel) => ConversationServiceModel;

function transform(model: RawConversationServiceModel): ConversationServiceModel {
    return {
        action: 'Chat',
        flowId: model.flowId,
        id: model.longId,
        resource: model.threadId,
    };
}

export const transformWrapper = (shortId: string): Transform =>
    (model: RawConversationServiceModel): ConversationServiceModel => ({
        ...transform(model),
        shortId,
    });

const shouldRetry = (statusCode: number): boolean =>
    statusCode === 0 || (statusCode >= 500 && statusCode < 600);

// @todo: add logging if needed
export const customErrorHandler = ({ }, errResp: WebErrorResponse): ErrorHandlingType =>
    shouldRetry(errResp.statusCode) ? ErrorHandlingType.RetryCountedWithBackoff : ErrorHandlingType.DoNotRetry;
