import { ThemeInput } from '@stardust-ui/react';

const theme: ThemeInput = {
    componentStyles: {
        Button: {
            root: {
                fontSize: '80%',
                height: '3.5rem',
            },
        },
    },
};

export default theme;
