import { localizer } from '@skype/bew-localization';
import * as React from 'react';
import { WebErrorResponse } from 'simplerestclients';
import * as SyncTasks from 'synctasks';
import { AriaContextConsumer } from '../../contexts/AriaContext';
import { BotsContext, BotsContextProvider } from '../../contexts/bots/BotsContext';
import { UserContext, UserContextConsumer } from '../../contexts/UserContext';
import { displayableErrors, ErrorHelper } from '../../helpers/ErrorHelper';
import { CommonProperty, logger } from '../../helpers/LoggerHelper';
import BotsRestClient from '../../rest/BotsRestClient';
import { ARootView, ARootViewProps } from '../common/ARootView';
import { StackNavigationView } from '../common/StackNavigationView';
import { AddBotView } from './AddBotView';

export interface RootViewState {
    context?: BotsContext;
    error?: Error | undefined;
    isAddBotInProgress: boolean;
}

interface RootViewProps extends ARootViewProps {
    skypetoken: string | undefined;
}

export class RootView extends ARootView<RootViewProps, RootViewState> {
    public readonly state: RootViewState = { isAddBotInProgress: false };

    componentDidMount() {
        const botId = this._getBotIdFromUrl();
        if (!botId) {
            throw displayableErrors.BotNotFound;
        }

        logger.initCommonProperty({ key: CommonProperty.BotId, value: botId });

        this._getBotInfo(botId)
            .then((botInfo) => {
                const context: BotsContext = { botId, botInfo, isAddedAsContact: false };
                this.setState({ context });
            }).catch((error: Error) => {
                this.setState({
                    error,
                });
            });
    }

    componentDidUpdate(prevProps: ARootViewProps) {
        if (prevProps.loading && !this.props.loading) {
            document.title = localizer.getString('add_bot_to_contacts');
        }
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        return <BotsContextProvider value={ this.state.context }>
            <UserContextConsumer>
                { (userContext) =>
                    <AriaContextConsumer>{ (ariaContext) =>
                        <StackNavigationView ariaContext={ ariaContext } loading={ this._isLoading(userContext) }
                            initialView={(
                                <AddBotView
                                    skypetoken={this.props.skypetoken}
                                    onProgress={this.handleAddBotViewProgress}
                                    onAdd={this.handleAddBotViewAdd}
                                    onError={this.handleAddBotViewError}
                                />
                            )}
                            initialViewProps={ { eventViewName: 'add-bot-view' } } />
                    }</AriaContextConsumer>
                }
            </UserContextConsumer>
        </BotsContextProvider>;
    }

    private readonly handleAddBotViewProgress = () => this.setState({ isAddBotInProgress: true });

    private readonly handleAddBotViewAdd =
        () => this.setState((state) => ({ context: { ...state.context!, isAddedAsContact: true }, isAddBotInProgress: false }))

    private readonly handleAddBotViewError = (error: Error) => this.setState({ error, isAddBotInProgress: false });

    private _getBotInfo(botId: string) {
        return BotsRestClient.getBotInfo(botId)
            .catch((response: WebErrorResponse) => {
                const error = ErrorHelper.enrichErrorFromWebResponse(displayableErrors.BotNotFound, response, 'Get bot info');
                return SyncTasks.Rejected(error);
            });
    }

    private _isLoading = (userContext: UserContext) => {
        return  this.props.loading || !(this.state.context && this.state.context.botInfo) || userContext.isLoginInProgress || this.state.isAddBotInProgress;
    }

    private _getBotIdFromUrl() {
        const pathName = window.location.pathname.replace(/\/+$/, '');
        const pathParts = pathName.split('/');

        return pathParts.pop();
    }
}
