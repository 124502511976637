import UAParser from 'ua-parser-js';
import { QuickJoinFlowSupportedBrowsers } from '../contexts/EcsContext';
import { Dictionary } from '../models/SharedTypes';

export enum OperatingSystems {
    WinPhone = 'Windows Phone',
    WinRT = 'Windows RT',
    Win = 'Windows',
    iOS = 'iOS',
    MacOS = 'Mac OS',
    Android = 'Android',
    AndroidX86 = 'Android -x86',
    ChromeOS = 'ChromeOS',
    Linux = 'Linux',
    Tizen = 'Tizen',
    Ubuntu = 'Ubuntu',
    WebOS = 'WebOS',
    Unknown = 'Unknown',
}

export enum Browsers {
    AndroidBrowser = 'Android Browser',
    Avast = 'Avast',
    AVG = 'AVG',
    Bolt = 'Bolt',
    ChromeWebView = 'Chrome WebView',
    Chrome = 'Chrome',
    Chromium = 'Chromium',
    Dolphin = 'Dolphin',
    EdgeOld = 'EdgeOld',
    Edge = 'Edge',
    Electron = 'Electron',
    Facebook = 'Facebook',
    Firefox = 'Firefox',
    Instagram = 'Instagram',
    Kindle = 'Kindle',
    Opera = 'Opera',
    OperaMini = 'Opera Mini',
    OperaMobile = 'Opera Mobile',
    MSIE = 'IE',
    Mozilla = 'Mozilla',
    Safari = 'Safari',
    SafariMobile = 'Mobile Safari',
    PhantomJS = 'PhantomJS',
    SamsungBrowser = 'Samsung Browser',
    SkypeShell = 'SkypeShell',
    Tesla = 'Tesla',
    Tizen = 'Tizen Browser',
    Yandex = 'Yandex',
    WeChat = 'WeChat',
    Baidu = 'baidu',
    Unknown = 'Unknown',
}

const osRegExps: Dictionary<RegExp> = {};
osRegExps[OperatingSystems.WinPhone] = /windows\sphone/i;
osRegExps[OperatingSystems.WinRT] = /arm;/i;
osRegExps[OperatingSystems.Win] = /(windows|win32)/i;
osRegExps[OperatingSystems.iOS] = /(iPad|iPhone|iPod)/i;
osRegExps[OperatingSystems.MacOS] = /(macintosh|mac os x)/i;
osRegExps[OperatingSystems.Android] = /android/i;
osRegExps[OperatingSystems.ChromeOS] = /CrOS/i;
osRegExps[OperatingSystems.Linux] = /(linux|x11)/i;

const browserRegExps: Dictionary<RegExp> = {};
browserRegExps[Browsers.Opera] = /(OPR\/|Opera)/i;
browserRegExps[Browsers.EdgeOld] = /Edge/i;
browserRegExps[Browsers.Edge] = /Edg/i;
browserRegExps[Browsers.MSIE] = /Trident/i;
browserRegExps[Browsers.Chrome] = /Chrome/i;
browserRegExps[Browsers.Firefox] = /Firefox/i;
browserRegExps[Browsers.Safari] = /Safari/i;
browserRegExps[Browsers.Facebook] = /(FBAN|FBAV)/i;

export interface IBrowserVersion {
    major: number;
    minor: number;
    bugfix?: number;
}

export enum DeviceTypes {
    Desktop = 'desktop',
    Mobile = 'mobile',
    Other = 'other',
}

export class EnvironmentHelper {

    /**
     * Returns the Operating System the code is executed in
     *
     * @return {string}
     */
    static getOS = (): OperatingSystems => {
        const osInformation = EnvironmentHelper._uaParser.getOS();
        const detectedOs = osInformation?.name as OperatingSystems ?? EnvironmentHelper._getOsFallback(EnvironmentHelper._uaParser.getUA() || window.navigator.userAgent);
        // iPads send by default MacOS user agent to so correctly detect iPad we use this trick
        if (detectedOs === OperatingSystems.MacOS && (navigator?.maxTouchPoints ?? 0) > 1) {
            return OperatingSystems.iOS;
        }

        return detectedOs;
    }

    /**
     * Returns the browser name
     *
     * @return {string}
     */
    static getBrowserName = (): Browsers => {
        const browserName = EnvironmentHelper._uaParser.getBrowser();
        return browserName?.name as Browsers ?? EnvironmentHelper._getBrowserNameFallback(EnvironmentHelper._uaParser.getUA() || window.navigator.userAgent);
    }

    static getSafariBrowserVersion = (): IBrowserVersion | undefined => {
        const version = EnvironmentHelper._uaParser.getUA().match(/version\/(\d+)\.(\d+)(\.(\d+))?/i);

        if (!version) {
            return;
        }

        return {
            major: parseInt(version[1], 10),
            minor: parseInt(version[2], 10),
            bugfix: parseInt(version[4], 10) || undefined,
        };
    }

    static isSafariBrowserVersionAtLeast(minimumVersion: IBrowserVersion): boolean {
        if (EnvironmentHelper.getBrowserName() !== Browsers.Safari) {
            return false;
        }

        const version = EnvironmentHelper.getSafariBrowserVersion();

        if (!version) {
            return false;
        }

        return version.major > minimumVersion.major
            || (version.major === minimumVersion.major && version.minor >= minimumVersion.minor);
    }

    static isSafariBrowserEligibleForMeetingGuestFlow = (): boolean => {
        return EnvironmentHelper.isSafariBrowserVersionAtLeast({
            major: 13,
            minor: 1,
        });
    }

    /**
     * Returns the device type
     *
     * @return {string}
     */
    static getDeviceType = (): DeviceTypes => {
        switch (EnvironmentHelper.getOS()) {
            case OperatingSystems.Win:
            case OperatingSystems.MacOS:
            case OperatingSystems.WinRT:
            case OperatingSystems.ChromeOS:
            case OperatingSystems.Linux:
            case OperatingSystems.Ubuntu:
                return DeviceTypes.Desktop;

            case OperatingSystems.WinPhone:
            case OperatingSystems.iOS:
            case OperatingSystems.Android:
            case OperatingSystems.AndroidX86:
                return DeviceTypes.Mobile;

            case OperatingSystems.Unknown:
            case OperatingSystems.WebOS:
            case OperatingSystems.Tizen:
            default:
                return DeviceTypes.Other;
        }
    }

    static isQuickWebJoinSupported = (): boolean => {
        return EnvironmentHelper._currentBrowserSupportsQuickJoinOnWeb();
    }

    /**
     * Returns current screen resolution
     *
     * @return {string}
     */
    static getScreenResolution = (_screen = window.screen) => {
        if (_screen.width == null || typeof _screen.width === 'undefined' ||
            _screen.height == null || typeof _screen.height === 'undefined') {

            return 'Unknown';
        }

        return _screen.width + 'x' + _screen.height;
    }

    /**
     * Returns language from the browser
     *
     * @return {string}
     */
    static getLanguage = (_navigator = window.navigator) => {
        const lang = _navigator.language || (_navigator as any).userLanguage;

        if (lang !== undefined && lang.length > 2) {
            return lang.substr(0, 2);
        }

        return lang;
    }

    /**
     * Returns the iOS version
     * Based on detectDevice can returns iPhone/iPad or just version number
     *
     * @return {string}
     */
    static getIOSVersion() {

        const iOSVersionMatches = EnvironmentHelper._uaParser.getUA().match(
            new RegExp('(iPhone OS|CPU OS)' + ' ([\\d_]+)'),
        );

        if (iOSVersionMatches) {
            return iOSVersionMatches[2].replace(/_/g, '.');
        }

        return 'Unknown';
    }

    static isIOSInAppBrowserFlow = (_os: OperatingSystems = os, _browser: Browsers = browser): boolean => {
        const knownInAppBrowsers = [
            Browsers.Facebook,
        ];

        return _os === OperatingSystems.iOS && knownInAppBrowsers.indexOf(browser) > -1;
    }

    static getSupportedBrowsersDefault = (): QuickJoinFlowSupportedBrowsers => {
        return {
            android: [Browsers.Chrome, Browsers.Chromium, Browsers.Edge, Browsers.Firefox, Browsers.Opera, Browsers.Yandex],
            ios: [Browsers.Safari, Browsers.SafariMobile, Browsers.Chrome, Browsers.Chromium, Browsers.Edge, Browsers.Firefox, Browsers.Opera, Browsers.Yandex],
            mac: [Browsers.Safari, Browsers.Chrome, Browsers.Chromium, Browsers.Edge, Browsers.Firefox, Browsers.Opera, Browsers.Yandex],
            windows: [Browsers.Chrome, Browsers.Chromium, Browsers.Edge, Browsers.EdgeOld, Browsers.Firefox, Browsers.Opera, Browsers.Yandex],
            others: [Browsers.Chrome, Browsers.Chromium, Browsers.Edge, Browsers.Firefox, Browsers.Opera, Browsers.Yandex],
        };
    }

    static updateEnvironmentSettings = (quickJoinFlowSupportedBrowsers?: QuickJoinFlowSupportedBrowsers, customUaParser?: UAParser) => {
        EnvironmentHelper._quickJoinFlowSupportedBrowsers = quickJoinFlowSupportedBrowsers ?? EnvironmentHelper._quickJoinFlowSupportedBrowsers;
        if (customUaParser) {
            EnvironmentHelper._uaParser = customUaParser;
        }
    }

    private static _uaParser = new UAParser();
    private static _quickJoinFlowSupportedBrowsers = EnvironmentHelper.getSupportedBrowsersDefault();

    private static _currentBrowserSupportsQuickJoinOnWeb = (): boolean => {
        const currentBrowser = EnvironmentHelper.getBrowserName();
        const currentOs = EnvironmentHelper.getOS();
        const supportedBrowsers = EnvironmentHelper._quickJoinFlowSupportedBrowsers;
        switch (currentOs) {
            case OperatingSystems.Android:
            case OperatingSystems.AndroidX86:
            case OperatingSystems.ChromeOS:
                return supportedBrowsers.android.includes(currentBrowser);

            case OperatingSystems.iOS:
                return supportedBrowsers.ios.includes(currentBrowser);

            case OperatingSystems.MacOS:
                return supportedBrowsers.mac.includes(currentBrowser);

            case OperatingSystems.Win:
            case OperatingSystems.WinRT:
                return supportedBrowsers.windows.includes(currentBrowser);

            default:
                return supportedBrowsers.others.includes(currentBrowser);
        }
    }

    private static _getOsFallback = (userAgent: string): OperatingSystems => {
        for (const osKey in osRegExps) {
            if (userAgent.match(osRegExps[osKey])) {
                // iPads send by default MacOS user agent to so correctly detect iPad we use this trick
                if (osKey === OperatingSystems.MacOS && (navigator?.maxTouchPoints ?? 0) > 1) {
                    return OperatingSystems.iOS;
                }

                return osKey as OperatingSystems;
            }
        }

        return OperatingSystems.Unknown;
    }

    private static _getBrowserNameFallback = (userAgent: string): Browsers => {
        for (const browserKey in browserRegExps) {
            if (userAgent.match(browserRegExps[browserKey])) {
                return browserKey as Browsers;
            }
        }

        return Browsers.Unknown;
    }
}

// PlatformVersion: Helpers.Environment.pureOSVersion,
//             Platform: Helpers.Environment.platform,
//             Language: Helpers.Environment.language,
//             ScreenResolution: Helpers.Environment.screenResolution

export const os = EnvironmentHelper.getOS();
export const browser = EnvironmentHelper.getBrowserName();
export const deviceType = EnvironmentHelper.getDeviceType();
export const canJoinOnWeb = EnvironmentHelper.isQuickWebJoinSupported();
export const screenResolution = EnvironmentHelper.getScreenResolution();
export const deviceLanguage = EnvironmentHelper.getLanguage();
export const platform = window.navigator.platform;
export const isIOSInAppBrowserFlow = EnvironmentHelper.isIOSInAppBrowserFlow();
export const isSafariBrowserEligibleForMeetingGuestFlow = EnvironmentHelper.isSafariBrowserEligibleForMeetingGuestFlow();
export const getSupportedBrowsersDefault = EnvironmentHelper.getSupportedBrowsersDefault();
