import { AWTEventPriority, AWTEventProperties, AWTLogger, AWTLogManager, AWTPiiKind, AWTPropertyType } from '@aria/webjs-sdk';
import Config from 'config';
import { Dictionary } from '../models/SharedTypes';
import { getDiffInMS } from './DateHelper';
import { browser, deviceType, os } from './EnvironmentHelper';
import { getMsaCidHexFromSkypeToken, getSkypeIdFromSkypeToken } from './SkypeTokenHelper';
import { UrlHelper } from './UrlHelper';

export interface NetworkEvent {
    host: string;
    path: string;
    url: string;
    method: string;
    statusCode: number;
    statusText?: string;
    responseTime: number;
    custom?: any;
}

export type NavigationEventType = 'push' | 'pop';
export interface NavigationEvent {
    view: string;
    type: NavigationEventType;
    isRoot: boolean;
}

export interface SessionStartedEvent {
    useUnifiedView: boolean;
    wasPreLoggedin: boolean;
}

export interface SessionFinishedEvent {
    useUnifiedView?: boolean;
    sessionDurationInMs?: number;
    completionType: string;
    wasLoggedIn?: boolean;
}

export interface SessionIntermediateEvent {
    useUnifiedView?: boolean;
    wasLoggedIn?: boolean;
    eventType: string;
}

export interface ActionEvent {
    name: string;
}

export enum SilentLoginStatus {
    Success = 'success',
    Fail = 'fail',
}
export interface SilentLoginEvent {
    status: SilentLoginStatus;
    duration: number;
    error?: string;
    errorDescription?: string;
}

export interface FallbackFlowEvent {
    message: string;
    reason: string;
}

const defaultPrefix = 'ui_';

export enum CommonProperty {
    SkypeId = 'UserInfo.SkypeId',
    ThreadId = 'Meeting.ThreadId',
    ShortId = 'Meeting.ShortId',
    InviteSkypeId = 'Invite.SkypeId',
    BotId = 'Bot.BotId',
    ChannelId = 'Channel.ChannelId',
    EntryPoint = 'Page.EntryPoint',
    CorrelationId = 'Page.CorrelationId',
    Source = 'Page.Source',
    Profile = 'Profile.SkypeId',
    IsLoggedIn = 'UserInfo.IsLoggedIn',
    UserId = 'UserInfo.Id',
    MsaCidHex = 'MSACIDHEX',
    IsUnsupportedPlatformOrBrowser = 'IsUnsupportedPlatformOrBrowser',
}

export interface Property {
    key: CommonProperty;
    value: string | number | boolean | Date;
    piiKind?: AWTPiiKind;
    type?: AWTPropertyType;
}

class LoggerHelper {
    private _logger: AWTLogger = AWTLogManager.getLogger();
    private _commonProperties: Dictionary<Property> = {};
    private _sessionStartDate: Date = new Date();
    private _wasLoggedIn: boolean = false;
    private _wasUnifiedView: boolean = false;
    private _prefix: string = defaultPrefix;

    init(ariaTenantId = Config.logging.ariaTenant, prefix?: string) {
        if (prefix) {
            this._prefix = prefix;
        }

        AWTLogManager.initialize(ariaTenantId, {
            canSendStatEvent: () => false,
            enableAutoUserSession: true,
        });

        window.onerror = this._onError;

        // Current implementation of the flushAndTeardown method has wrong context when used as callback
        // therefore asynchronous pending events would be lost, let's fix it
        window.removeEventListener('beforeunload', AWTLogManager.flushAndTeardown);
        window.addEventListener('beforeunload', AWTLogManager.flushAndTeardown.bind(AWTLogManager));
    }

    getSessionId = () => this._logger.getSessionId();

    public initCommonProperty(property: Property) {
        this._commonProperties[property.key] = property;
    }

    public addSkypeIdAndMsaCidHexCommonProperties(skypeToken: string | undefined, isGuest: boolean) {
        this.initCommonProperty({
            key: CommonProperty.UserId,
            value: getSkypeIdFromSkypeToken(skypeToken) || '',
            piiKind: isGuest ? AWTPiiKind.NotSet : AWTPiiKind.Identity, // guest skypeId is not considered PII as it's anonymous and valid for 24 hours
            type: AWTPropertyType.String,
        });

        this.initCommonProperty({
            key: CommonProperty.MsaCidHex,
            value: getMsaCidHexFromSkypeToken(skypeToken) || '',
            piiKind: AWTPiiKind.NotSet,
            type: AWTPropertyType.String,
        });
    }

    error = (event: Error, priority: AWTEventPriority = AWTEventPriority.Normal) => {
        this._log(event, priority, 'error', 'Error');
    }

    network = (event: NetworkEvent, priority: AWTEventPriority = AWTEventPriority.Normal) => {
        this._log(event, priority, 'network_event', 'Network');
    }

    navigation = (event: NavigationEvent, priority: AWTEventPriority = AWTEventPriority.Normal) => {
        this._log(event, priority, 'navigation', 'Navigation');
    }

    action = (event: ActionEvent, priority: AWTEventPriority = AWTEventPriority.Normal) => {
        this._log(event, priority, 'action', 'Action');
    }

    silentLogin = (event: SilentLoginEvent, priority: AWTEventPriority = AWTEventPriority.Normal) => {
        this._log(event, priority, 'silent_login', 'SilentLogin');
    }

    sessionStarted = (event: SessionStartedEvent, priority: AWTEventPriority = AWTEventPriority.Normal) => {
        this._sessionStartDate = new Date();
        this._wasLoggedIn = event.wasPreLoggedin;
        this._wasUnifiedView = event.useUnifiedView;
        this._log(event, priority, 'session_started', 'SessionStarted');
    }

    sessionIntermediateStep = (event: SessionIntermediateEvent, priority: AWTEventPriority = AWTEventPriority.Normal) => {
        event.wasLoggedIn = event.wasLoggedIn === undefined ? this._wasLoggedIn : event.wasLoggedIn;
        event.useUnifiedView = this._wasUnifiedView;
        this._log(event, priority, 'session_intermediate', 'SessionIntermediateEvent');
    }

    sessionFinished = (event: SessionFinishedEvent, priority: AWTEventPriority = AWTEventPriority.Normal) => {
        event.sessionDurationInMs = getDiffInMS(this._sessionStartDate);
        event.wasLoggedIn = this._wasLoggedIn;
        event.useUnifiedView = this._wasUnifiedView;

        this._log(event, priority, 'session_finished', 'SessionFinished');
    }

    meetNowFallbackFlow = (event: FallbackFlowEvent) => {
        this._log(event, AWTEventPriority.Normal, 'fallback_flow_started', 'FallbackFlowStarted');
    }

    getCorrelationId = () => {
        const correlationIdFromQuery = UrlHelper.getQueryParameterAsString('correlationId');
        if (correlationIdFromQuery) {
            return correlationIdFromQuery;
        }

        return this.getSessionId();
    }

    private setName = (eventProperties: AWTEventProperties, name: string) => {
        eventProperties.setName(this._prefix + name);
        eventProperties.setType(name);
    }

    private setCommonProperties = (eventProperties: AWTEventProperties) => {
        Object.keys(this._commonProperties).forEach((key) => {
            const property = (this._commonProperties as any)[key] as Property;
            if (property.piiKind) {
                eventProperties.setPropertyWithPii(property.key, property.value, property.piiKind);
            } else {
                eventProperties.setProperty(property.key, property.value);
            }
        });
        eventProperties.setProperty('DeviceInfo.Custom.Os', os);
        eventProperties.setProperty('DeviceInfo.Custom.Browser', browser);
        eventProperties.setProperty('DeviceInfo.Custom.DeviceType', deviceType);
        eventProperties.setProperty('Config.Env', Config.env);
        eventProperties.setProperty('Source', UrlHelper.getQueryParameterAsString('source'));
        eventProperties.setProperty('Exp', UrlHelper.getQueryParameterAsString('exp'));
        eventProperties.setProperty('Call', UrlHelper.getQueryParameterAsString('call'));
        eventProperties.setProperty('Video', UrlHelper.getQueryParameterAsString('video'));
        eventProperties.setProperty('Mic', UrlHelper.getQueryParameterAsString('mic'));
        eventProperties.setProperty('CorrelationId', this.getCorrelationId());
        eventProperties.setProperty('session_id', this.getCorrelationId()); // this is used as an actor in Interana
    }

    private setProperties = (eventProperties: AWTEventProperties, properties: any, propertyPrefix: string) => {
        Object.keys(properties).forEach((property) => {
            if (typeof properties[property] === 'object' && properties[property]) {
                this.setProperties(eventProperties, properties[property], propertyPrefix + '.' + property);
            } else {
                eventProperties.setProperty(propertyPrefix + '.' + property, properties[property]);
            }
        });
    }

    private setPiiProperties = (eventProperties: AWTEventProperties, piiProperties: Property[], propertyPrefix: string) => {
        piiProperties.forEach((property: Property) => {
            eventProperties.setPropertyWithPii(propertyPrefix + '.' + property.key, property.value, property.piiKind || AWTPiiKind.NotSet, property.type);
        });
    }

    private _onError = (message: any, file: any, line: any, col: any, error: any) => {
        this._log({
            message,
            file,
            line,
            col,
            error,
        }, AWTEventPriority.Normal, 'js_onerror', 'Error');
    }

    private _log(event: any, priority: AWTEventPriority, name: string, propertyPrefix: string, piiProperties?: Property[]) {
        const eventProperties = new AWTEventProperties();
        eventProperties.setEventPriority(priority);
        this.setName(eventProperties, name);
        this.setCommonProperties(eventProperties);
        this.setProperties(eventProperties, event, propertyPrefix);

        if (piiProperties && Array.isArray(piiProperties)) {
            this.setPiiProperties(eventProperties, piiProperties, propertyPrefix);
        }

        this._logger.logEvent(eventProperties);
    }
}

export const logger = new LoggerHelper();
