import config from 'config';
import { S4LParameters } from '../config/BaseConfig';
import { Dictionary } from '../models/SharedTypes';
import { displayableErrors } from './ErrorHelper';
import { logger } from './LoggerHelper';

interface KeyValuePair {
    key: string;
    value: string;
}

export class UrlHelper {

    static getWebClientUrl(params: S4LParameters, webSubdomainForRedirect?: string | undefined) {
        // Websubdomain allow us to try out the integration with different version of webshell
        // Current up to date list is available: candidate, preview, dev, alpha, beta
        const { webSubDomain } = UrlHelper.getQueryParameters() as Dictionary<string>;

        // webSubDomain passed by query param takes precedence to ECS config
        return config.urls.app.s4lWeb(params, webSubDomain || webSubdomainForRedirect);
    }

    static getWebClientUrlWithCorrelationId(params: S4LParameters, webSubdomainForRedirect?: string | undefined) {
        const url = UrlHelper.getWebClientUrl(params, webSubdomainForRedirect);

        return `${url}${url.includes('?') ? '&' : '?'}correlationId=${logger.getCorrelationId()}`;
    }

    static getAllowedQueryParameters(allowedParameters: string[]): KeyValuePair[] {
        return allowedParameters
            .map((param: string) => ({
                key: param,
                value: UrlHelper.getQueryParameter(param),
            } as KeyValuePair))
            .filter((pair: KeyValuePair) => pair.value);
    }

    static getQueryParameters() {
        if (!location.search || location.search.length < 1) {
            return {} as Dictionary<string | string[]>;
        }

        return location.search
            .substr(1)
            .split('&')
            .map((value) => {
                const split = value.split('=');
                return {
                    key: decodeURIComponent(split[0]),
                    value: decodeURIComponent(split[1] || ''),
                } as KeyValuePair;
            })
            .reduce((prev, current) => {
                if (!!prev[current.key]) {
                    const prevValue = prev[current.key];
                    if (typeof prevValue === 'string') {
                        prev[current.key] = [prev[current.key] as string];
                    }
                    (prev[current.key] as string[]).push(current.value);
                } else {
                    prev[current.key] = current.value;
                }
                return prev;
            }, {} as Dictionary<string | string[]>);
    }

    static getQueryParameter(key: string): string | string[] {
        return UrlHelper.getQueryParameters()[key];
    }

    static getQueryParameterAsString(key: string): string {
        return `${UrlHelper.getQueryParameter(key) || ''}`;
    }

    static getShortIdFromUrl(): string {
        const pathName = window.location.pathname.replace(/\/+$/, '');
        const pathParts = pathName.split('/');
        const shortId = pathParts.pop();

        if (!shortId) {
            throw displayableErrors.ShortIdFromUrl;
        }

        return shortId;
    }
}
