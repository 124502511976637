import { Actions } from '../models/SharedTypes';

export interface CookieConfig {
    name: string;
    key?: string;
    path?: string;
    maxAge?: number;
    domain?: string;
}

export interface S4LParameters {
    threadId?: string;
    inviteId?: string;
    skypeId?: string;
    channelId?: string;
}

export interface Config {
    env: 'dev' | 'latest' | 'qa' | 'dogfood' | 'production';
    cookies: {
        skype: CookieConfig;
        csrf: CookieConfig;
    };
    silentLogin: {
        env: string;
        client_id: string;
        redirect_uri: string;
    };
    logging: {
        ariaTenant: string;
    };
    urls: {
        ecs: string,
        api: {
            profile: string;
            huddle: string;
            meetings: string;
            invite: string;
            channels: string;
            captcha: (lang: string, flowId: string, rand: string) => string;
        }
        app: {
            faq: string;
            login: (redirectUri: string, state: string) => string;
            logout: (redirectUri: string, state: string) => string;
            s4lWeb: (params: S4LParameters, webSubDomain?: string) => string;
            botLogin: (botId: string, useBotLauncherQueryParam?: boolean) => string;
            download: string;
            downloadAndroid: (threadId?: string) => string;
            downloadDesktop: string; // Remove once we have access to goLink CMS, the first one should be enough
            cookiePolicy: string;
            skypeCom: string,
            join: string,
        },
        launcher: {
            skypeUri: (action: Actions, threadId: string, sessionId: string, origin: string) => string;
            intentUri: (action: Actions, threadId: string, sessionId: string, origin: string, fallbackUri: string) => string;
            universalUri: (shortId: string, sessionId: string) => string;
        },
        inviteLauncher: {
            skypeUri: (skypeId: string, sessionId: string, offSkypeInviteId: string, origin: string) => string;
            intentUri: (skypeId: string, sessionId: string, offSkypeInviteId: string, origin: string, fallbackUri: string) => string;
        },
        botLauncher: {
            skypeUri: (botId: string, sessionId: string, origin: string) => string;
            intentUri: (botId: string, sessionId: string, origin: string, fallbackUri: string) => string;
        },
        channelLauncher: {
            skypeUri: (channelId: string, sessionId: string, origin: string) => string;
            intentUri: (channelId: string, sessionId: string, origin: string, fallbackUri: string) => string;
        },
        doLauncher: {
            skypeUri: (action: Actions, skypeId: string, sessionId: string, origin: string) => string;
            intentUri: (action: Actions, skypeId: string, sessionId: string, origin: string, fallbackUri: string) => string;
        },
        meetNowJoinLauncher: {
            skypeUri: (action: Actions, source: string, exp: string, call: string, video: string, mic: string, sessionId: string, origin: string) => string;
            intentUri: (action: Actions, source: string, exp: string, call: string, video: string, mic: string, sessionId: string, origin: string, fallbackUri: string) => string;
        },
    };
}

const config: Config = {
    env: 'production',
    cookies: {
        skype: {
            name: 'SC',
            path: '/',
            maxAge: 3600 * 24 * 90, // 90 days
            domain: '.skype.com',
        },
        csrf: {
            name: 'x-csrf-token',
        },
    },
    logging: {
        ariaTenant: '36f18b389f2543c0b54bf5c5555e3587-e50b4ede-9173-4805-ab3a-7c8564e50b52-6674',
    },
    silentLogin: {
        env: 'live',
        client_id: '371752',
        redirect_uri: 'https://join.skype.com',
    },
    urls: {
        ecs: 'https://a.config.skype.com',
        api: {
            profile: 'https://profile.skype.com/profile',
            huddle: 'https://join.skype.com/api',
            meetings: 'https://api.join.skype.com',
            invite: 'https://inviteconnector.skype.com',
            channels: 'https://channels.skype.com',
            captcha: (lang: string, flowId: string, rand: string) =>
                `https://client.hip.live.com/GetHIP/GetWLSPHIP0/WLSPHIP0?mkt=${ lang }&fid=${ flowId }&id=skypeguest&type=visual&rnd=${ rand }`,
        },
        app: {
            faq: 'https://go.skype.com/faq.invites',
            login: (redirectUri: string, state: string) =>
                `https://login.skype.com/login?client_id=578134&redirect_uri=${ encodeURIComponent(redirectUri) }&state=${ state }`,
            logout: (redirectUri: string, state: string) =>
                `https://login.skype.com/logout?client_id=578134&redirect_uri=${ encodeURIComponent(redirectUri) }&state=${ state }`,
            s4lWeb: (params, webSubDomain) => {
                const url = `https://${ !!webSubDomain ? `${ webSubDomain }.` : '' }web.skype.com`;
                if (params.threadId) {
                    return `${ url }/${ params.threadId }`;
                }

                if (params.inviteId && params.skypeId) {
                    return `${ url }/8:${ params.skypeId }?inviteId=${ params.inviteId }`;
                }

                if (params.channelId) {
                    return `${ url }/channel/${ params.channelId }`;
                }

                return url;
            },
            botLogin: (botId: string, useBotLauncherQueryParam?: boolean) => {
                const loginUri = `https://login.skype.com/login?client_id=708557&redirect_uri=https%3A%2F%2Fjoin.skype.com%2Fbot%2F${ botId }`;
                if (useBotLauncherQueryParam) {
                    return loginUri + '?botsLauncher=true';
                }

                return loginUri;
            },
            download: 'https://go.skype.com/spaces.getskype',
            downloadAndroid: (threadId?: string) =>
                `https://play.google.com/store/apps/details?id=com.skype.raider&referrer=${threadId && encodeURIComponent(
                    `utm_source=invite&utm_thread=${threadId}`)}`,
            downloadDesktop: 'https://go.skype.com/download-skype/',
            cookiePolicy: 'https://go.skype.com/privacy',
            skypeCom: 'https://www.skype.com',
            join: 'https://join.skype.com',
        },
        launcher: {
            skypeUri: (action, threadId, sessionId, origin) => `skype:?${ action }&threadId=${ threadId }&correlationId=${ sessionId }&origin=${ origin }`,
            intentUri: (action, threadId, sessionId, origin, fallbackUri) =>
                `intent://?${ action }&threadId=${ threadId }&correlationId=${ sessionId }&origin=${ origin }`
                + `#Intent;scheme=skype;package=com.skype.raider;S.browser_fallback_url=${ encodeURIComponent(fallbackUri) };end;`,
            universalUri: (shortId, sessionId) => `https://www.skype.com/meetnow/${ shortId }?fallback=true&correlationId=${sessionId}`,
        },
        inviteLauncher: {
            skypeUri: (skypeId, sessionId, offSkypeInviteId, origin) =>
                `skype:${ skypeId }?chat&correlationId=${ sessionId }&utm_source=buddy&utm_content=${ offSkypeInviteId }&origin=${ origin }`,
            intentUri: (skypeId, sessionId, offSkypeInviteId, origin, fallbackUri) =>
                `intent://${ skypeId }?chat&correlationId=${ sessionId }&utm_source=buddy&utm_content=${ offSkypeInviteId }&origin=${ origin }`
                + `#Intent;scheme=skype;S.browser_fallback_url=${ encodeURIComponent(fallbackUri) };end;`,
        },
        botLauncher: {
            skypeUri: (botId, sessionId, origin) => `skype:${ botId }?chat&correlationId=${ sessionId }&origin=${ origin }`,
            intentUri: (botId, sessionId, origin, fallbackUri) =>
                `intent://${ botId }?chat&correlationId=${ sessionId }&origin=${ origin }#Intent;scheme=skype;S.browser_fallback_url=${ encodeURIComponent(fallbackUri) };end;`,
        },
        channelLauncher: {
            skypeUri: (channelId, sessionId, origin) => `skype:?channel=${ encodeURIComponent(channelId) }&correlationId=${ sessionId }&origin=${ origin }`,
            intentUri: (channelId, sessionId, origin, fallbackUri) =>
                `intent://?channel=${ encodeURIComponent(channelId) }&correlationId=${ sessionId }&origin=${ origin }`
                + `#Intent;scheme=skype;S.browser_fallback_url=${ encodeURIComponent(fallbackUri) };end;`,
        },
        doLauncher: {
            skypeUri: (action, skypeId, sessionId, origin) =>
                `skype:${ skypeId }?${ action }&correlationId=${ sessionId }&origin=${ origin }`,
            intentUri: (action, skypeId, sessionId, origin, fallbackUri) =>
                `intent://${ skypeId }?${ action }&correlationId=${ sessionId }&origin=${ origin }`
                + `#Intent;scheme=skype;S.browser_fallback_url=${ encodeURIComponent(fallbackUri) };end;`,
        },
        meetNowJoinLauncher: {
            skypeUri: (action, source, exp, call, video, mic, sessionId, origin) =>
                `skype:?action=${ action }&source=${ source }&exp=${ exp }&correlationId=${ sessionId }&origin=${ origin }&call=${ call }&video=${ video}&mic=${ mic }`,
            intentUri: (action, source, exp,  call, video, mic, sessionId, origin, fallbackUri) =>
                `intent://?action=${ action }&source=${ source }&exp=${ exp }&correlationId=${ sessionId }&origin=${ origin }&call=${ call }&video=${ video}&mic=${ mic }`
                + `#Intent;scheme=skype;package=com.skype.raider;S.browser_fallback_url=${ encodeURIComponent(fallbackUri) };end;`,
        },
    },
};

export default config;
