import { localizer } from '@skype/bew-localization';
import { Button, Header } from '@stardust-ui/react';
import * as React from 'react';
import { ConfigContext, ConfigContextConsumer } from '../../contexts/ConfigContext';
import { OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { logger } from '../../helpers/LoggerHelper';
import { mainContentId } from '../../models/SharedTypes';
import { ErrorDetails } from './ErrorDetails';

interface ErrorBoundaryState {
    error: Error | undefined;
    errorInfo: React.ErrorInfo | undefined;
}

interface AlternativeItem {
    contentLocString: string;
    descriptonLocString: string;
    linkTextLocString: string;
    linkClickHandler: (config: ConfigContext) => void;
}

export class ErrorBoundary extends React.PureComponent<{}, ErrorBoundaryState>  {
    private readonly _alternatives: AlternativeItem[];

    constructor(props: {}) {
        super(props);

        this.state = {
            error: undefined,
            errorInfo: undefined,
        };

        this._alternatives = [
            {
                contentLocString: 'txt_helpBox_got_question',
                descriptonLocString: 'txt_helpBox_have_your_queries',
                linkTextLocString: 'txt_helpBox_see_faq',
                linkClickHandler: this._onFaqClick,
            },
            {
                contentLocString: 'txt_helpBox_explore_skype',
                descriptonLocString: 'txt_helpBox_have_your_queries',
                linkTextLocString: 'txt_helpBox_see_faq',
                linkClickHandler: this._onExploreClick,
            },
            {
                contentLocString: 'txt_helpBox_need_to_use_skype',
                descriptonLocString: 'txt_helpBox_download_on_device',
                linkTextLocString: 'txt_helpBox_get_the_app',
                linkClickHandler: this._onDownloadClick,
            },
        ];
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logger.error(error);

        this.setState({
            error,
            errorInfo,
        });

        if (ErrorHelper.isDisplayableError(error)) {
            document.title = localizer.getString(error.pageTitleLocString);
        }
    }

    render() {
        if (this.state.error) {
            return  <div className='errorBoundary' role='main' id={ mainContentId }>
                <ErrorDetails error={ this.state.error } showSkype={ true } />
                { this._renderAlternatives() }
            </div>;
        }

        return this.props.children;
    }

    private _renderAlternatives() {
        return <div className='alternatives'>
            { this._alternatives.map((item, index) => this._renderAlternativeItem(item, index)) }
        </div>;
    }

    private _renderAlternativeItem(item: AlternativeItem, index: number) {
        return <div className='item' key={ index }>
            <Header
                as='h3'
                content={ localizer.getString(item.contentLocString) }
                description={ localizer.getString(item.descriptonLocString) }
            />
            <ConfigContextConsumer>
                { (configContext) => <Button text onClick={ () => item.linkClickHandler(configContext) }>
                    { localizer.getString(item.linkTextLocString) }
                </Button>
                }
            </ConfigContextConsumer>
        </div>;
    }

    private _onFaqClick = (config: ConfigContext) => {
        window.location.href = config.urls.app.faq;
    }

    private _onExploreClick = (config: ConfigContext) => {
        window.location.href = config.urls.app.skypeCom;
    }

    private _onDownloadClick = (config: ConfigContext) => {
        const downloadUrl = os === OperatingSystems.MacOS ? config.urls.app.downloadDesktop : config.urls.app.download;
        window.location.href = downloadUrl;
    }
}
