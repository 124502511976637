import * as React from 'react';
import { DomHelper } from 'src/ts/helpers/DomHelper';

export interface SelectProps {
    options: OptionProps[];
    value?: OptionProps;
    disabled?: boolean;
    onChange: (value: string) => void;
    ariaLabel: string;
    autoFocus?: boolean;
}

export interface SelectState {
    selected: OptionProps;
    focused: boolean;
}

export interface OptionProps {
    value: string;
    text: string;
}

const defaultEmptyOption = { value: 'NOT', text: '' };

export class Select extends React.Component<SelectProps, SelectState> {
    private _domReference: HTMLSelectElement | null;

    constructor(props: SelectProps) {
        super(props);
        this.state = {
            selected: props.value || defaultEmptyOption,
            focused: props.autoFocus || false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: SelectProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                selected: nextProps.value || this.state.selected,
            });
        }
    }

    render() {
        let wrapperAdditionalClass = '';
        if (this.props.options.length <= 1) {
            wrapperAdditionalClass += ' noOptions';
        }
        if (this.state.focused) {
            wrapperAdditionalClass += ' focused';
        }
        return (
            <div className={ `selectWrapper ${ wrapperAdditionalClass }` }>
                <strong className='selectTitle' aria-hidden='true' tabIndex={ -1 }>
                    <span className='selected'>{ this.state.selected.text }</span>
                    { this.props.options.length > 1 && <span className='iconFontBold' data-icon=''></span> }
                </strong>
                { this.props.options.length > 1 && <select
                    className='select'
                    tabIndex={ 0 }
                    aria-label={ this.props.ariaLabel }
                    onChange={ this._onChange }
                    value={ this.state.selected.value }
                    ref={ this._autoFocus }
                    onFocus={ this._onFocus }
                    onBlur={ this._onBlur }>
                    { this.renderOptions() }
                </select> }
            </div>
        );
    }

    private renderOptions = () => {
        return this.props.options.map((option: OptionProps) => {
            return <option key={ option.value } value={ option.value }> { option.text } </option>;
        }).concat(<option key='NOT' value='NOT' disabled={ true } style={ { display: 'none' } }></option>);
    }

    private _onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountry = event.target.value;
        const searchOption = this.props.options.filter((option) => option.value === event.target.value);
        this.setState({
            selected: searchOption.length ? searchOption[0] : defaultEmptyOption,
        }, () => this.props.onChange(selectedCountry));
    }

    private _onFocus = () => {
        if (this.state.focused) {
            return;
        }
        this.setState({
            focused: true,
        });
    }

    private _onBlur = () => {
        if (!this.state.focused) {
            return;
        }
        this.setState({
            focused: false,
        });
    }

    private _autoFocus = (el: HTMLSelectElement | null) => {
        if (this.props.autoFocus && el && !this._domReference) {
            this._domReference = el;
            window.setTimeout(() => DomHelper.setFocus(this._domReference), 200);
        }
    }
}
