import config from 'config';
import { OffSkypeInviteServiceModel } from 'src/ts/models/OffSkypeInviteServiceModel';
import { OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { logger } from '../../helpers/LoggerHelper';
import { ALauncher } from '../common/ALauncher';

interface LauncherProps {
    invite: OffSkypeInviteServiceModel;
}

export class Launcher extends ALauncher<LauncherProps> {

    protected _getLauncherUrl() {
        const invite = this.props.invite;
        const sessionId = logger.getCorrelationId();
        if (os === OperatingSystems.Android) {
            return config.urls.inviteLauncher.intentUri(invite.inviterSkypeId, sessionId, invite.longId, 'launcher', config.urls.app.download);
        }
        return config.urls.inviteLauncher.skypeUri(invite.inviterSkypeId, sessionId, invite.longId, 'launcher');
    }

    protected _getLoggerAction() {
        return 'join-invite';
    }
}
