import * as React from 'react';
import { Actions } from 'src/ts/models/SharedTypes';

export interface DoContext {
    action: Actions;
    skypeId: string;
}

// Default context does not mean much sense here
const context = React.createContext<DoContext | undefined>(undefined);

export const DoContextProvider = context.Provider;

export const DoContextConsumer = context.Consumer;
