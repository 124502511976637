import { localizer } from '@skype/bew-localization';

export const loadTranslation = (language: string) => {
    localizer.initSync({
        locale: language,
        localizedTable: (window as any).I18NBUNDLE,
        fallback: 'en',
        fallbackTable: (window as any).I18NBUNDLE_FALLBACK || (window as any).I18NBUNDLE,
    });
};
