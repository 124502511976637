import { localizer } from '@skype/bew-localization';
import { Button } from '@stardust-ui/react';
import React from 'react';
import { ErrorDetails } from './ErrorDetails';

export interface ErrorModalProps {
    error: Error;
    assetsBasePath?: string;
    onClose: () => void;
}

export class ErrorModal extends React.PureComponent<ErrorModalProps, React.StatelessComponent> {
    render() {
        return (
            <div className='portalContainer' role='dialog' aria-modal='true'>
                <div className='portalContent' >
                    <ErrorDetails error={ this.props.error } assetsBasePath={ this.props.assetsBasePath } />
                    <Button
                        autoFocus
                        content={ localizer.getString('Common.btn_close') }
                        className='closeButton ui-button'
                        onClick={ this.props.onClose }/>
                </div>
            </div>
        );
    }
}
