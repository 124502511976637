import { localizer } from '@skype/bew-localization';
import * as React from 'react';
import { logger } from 'src/ts/helpers/LoggerHelper';
import { ConfigContext, ConfigContextConsumer } from '../../contexts/ConfigContext';
import { defaultDimension, Dimension } from '../../models/SharedTypes';
import { OptionProps, Select } from './Select';

const languages: OptionProps[] = [
    { value: 'en', text: 'English' },
    { value: 'ar', text: 'عربي' },
    { value: 'bg', text: 'Български' },
    { value: 'cs', text: 'Česky' },
    { value: 'da', text: 'Dansk' },
    { value: 'de', text: 'Deutsch' },
    { value: 'el', text: 'Ελληνικά' },
    { value: 'es', text: 'Español' },
    { value: 'et', text: 'Eesti' },
    { value: 'fi', text: 'Suomi' },
    { value: 'fr', text: 'Français' },
    { value: 'he', text: 'עברית' },
    { value: 'hi', text: 'हिन्दी' },
    { value: 'hu', text: 'Magyar' },
    { value: 'id', text: 'Bahasa Indonesia' },
    { value: 'it', text: 'Italiano' },
    { value: 'ja', text: '日本語' },
    { value: 'ko', text: '한국어' },
    { value: 'nl', text: 'Nederlands' },
    { value: 'no', text: 'Norsk' },
    { value: 'pl', text: 'Polski' },
    { value: 'pt', text: 'Português' },
    { value: 'pt-br', text: 'Português (Brasileiro)' },
    { value: 'ro', text: 'Română' },
    { value: 'ru', text: 'Русский' },
    { value: 'sv', text: 'Svenska' },
    { value: 'tr', text: 'Türkçe' },
    { value: 'uk', text: 'Українська' },
    { value: 'zh-Hans', text: '中文（简体)' },
    { value: 'zh-Hant', text: '中文 (繁體)' },
];

interface FooterState {
    language: string;
    footerDimension: Dimension;
}

interface FooterProps {
    dark?: boolean;
    showOnlyLegalInfo?: boolean;
}

export class Footer extends React.PureComponent<FooterProps, FooterState> {
    private _footerElement?: HTMLDivElement;
    private _debounce: number;

    constructor(props: FooterProps) {
        super(props);

        const matchingLanguage = languages.filter((language) => language.value === localizer.getLocale());
        this.state = {
            language: matchingLanguage[0].value || 'en',
            footerDimension: defaultDimension,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this._updateDimensionDebouncer);
        this._updateDimension();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._updateDimensionDebouncer);
        delete this._footerElement;
    }

    render() {
        const separator = <div className='separator' aria-hidden={ true }>·</div>;
        return <ConfigContextConsumer>{ (configContext) =>
            <div className='outer' style={ { minHeight: this.state.footerDimension.height, width: '100%' } }>
                <div className={ `footer ${ this.props.dark ? 'dark' : '' }` } ref={ this._setElement }>
                    <div className='app'>
                        { !this.props.showOnlyLegalInfo && <>
                            <a href={ configContext.urls.app.faq } className='link' target='_blank' onClick={ () => logger.action({ name: 'faq' }) }>
                                { localizer.getString('footer_btn_FAQ') }
                            </a>
                            { separator }
                            <form className='language-selector'>
                                <Select
                                    onChange={ this._changeLanguage(configContext) }
                                    ariaLabel={ localizer.getString('Common.a11y_select_language') }
                                    options={ languages }
                                    value={ this._selectedLanguage() } />
                            </form>
                        </>}
                    </div>
                    <div className='legal'>
                        <a href='https://www.skype.com/go/tou' className='link' target='_blank' onClick={ () => logger.action({ name: 'terms' }) }>
                            { localizer.getString('footer_btn_terms') }
                        </a>
                        { separator }
                        <a href='https://www.skype.com/go/privacy' className='link' target='_blank' onClick={ () => logger.action({ name: 'privacy' }) }>
                            { localizer.getString('footer_btn_privacy') }
                        </a>
                        { separator }
                        <span className='copyright'>{ localizer.getString('footer_btn_copyright', { YEAR: new Date().getFullYear().toString() }) }</span>
                    </div>
                </div>
            </div>
        }</ConfigContextConsumer>;
    }

    private _selectedLanguage = () => {
        const matchingLanguage = languages.filter((language) => language.value === this.state.language);
        return matchingLanguage ? matchingLanguage[0] : undefined;
    }

    private _setElement = (element?: HTMLDivElement | null) => {
        if (element) {
            this._footerElement = element;
        }
    }

    private _updateDimensionDebouncer = () => {
        if (this._debounce) {
            window.clearTimeout(this._debounce);
        }

        this._debounce = window.setTimeout(() => {
            this._updateDimension();
        }, 100);
    }

    private _updateDimension = () => {
        this.setState(() => {
            let footerDimension: Dimension = defaultDimension;
            if (this._footerElement) {
                footerDimension = {
                    height: this._footerElement.clientHeight,
                    width: this._footerElement.clientWidth,
                };
            }
            return { footerDimension };
        });
    }

    private _changeLanguage = (configContext: ConfigContext) => {
        return (value: string) => {
            configContext.switchLanguage(value);
        };
    }
}
