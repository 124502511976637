import config from 'config';
import * as SyncTasks from 'synctasks';
import { CaptchaSolution } from '../components/common/Captcha';
import { logger } from '../helpers/LoggerHelper';
import { ConversationPayload, ConversationServiceModel, RawConversationServiceModel } from '../models/ConversationServiceModel';
import {
    CreateAuthenticatedMeetingPayloadMeetingsApi,
    CreateMeetingPayloadMeetingsApi,
    CreateMeetingServiceModel,
    GuestJoinPayloadMeetingsApi,
    GuestUserServiceModel,
    MeetnowGuestServiceModel,
} from '../models/GuestUserServiceModel';
import { BaseRestClient } from './BaseRestClient';
import { customErrorHandler, transformWrapper } from './MeetingsApiClientUtils';

const SessionIdHeaderKey = 'x-sessionId';
const SupportsCaptchaHeader = 'X-Skype-SupportsCaptcha';
const CaptchaSolutionHeader = 'X-Skype-CaptchaSolution';
const CaptchaChallengeIdHeader = 'X-Skype-CaptchaChallengeId';
const CaptchaAzureRegionHeader = 'X-Skype-CaptchaAzureRegion';

class MeetingsApiClient extends BaseRestClient {
    joinAsGuest<T extends GuestJoinPayloadMeetingsApi>(
        payload: T,
        retries: number = 0,
        joinThread: boolean = false,
        captcha?: CaptchaSolution | undefined,
        customPropertiesProvider?: () => object): SyncTasks.Promise<GuestUserServiceModel> {
        const genericEventPath = joinThread ?
            `/v1/threads/{threadId}/members` : `/v1/meetings/{shortId}/members`;
        const apiPath = joinThread ?
            `/v1/threads/${payload.threadId}/members` : `/v1/meetings/${payload.shortId}/members`;
        const headers: { [key: string]: string } = {};
        headers[SessionIdHeaderKey] = logger.getCorrelationId();
        if (captcha?.solution) {
            headers[SupportsCaptchaHeader] = 'yes';
            headers[CaptchaSolutionHeader] = captcha.solution;
            headers[CaptchaChallengeIdHeader] = captcha.challengeId;
            headers[CaptchaAzureRegionHeader] = captcha.azureRegion;
        }

        return this.performApiPost<GuestUserServiceModel>(
            apiPath,
            {
                displayName: payload.displayName,
            },
            {
                customErrorHandler,
                eventProperties: {
                    genericEventPath,
                    customPropertiesProvider,
                },
                retries,
                headers,
            });
    }

    getConversation(payload: ConversationPayload, retries: number = 0): SyncTasks.Promise<ConversationServiceModel> {
        const shortId = payload.shortId;
        const headers: { [key: string]: string } = {};
        headers[SessionIdHeaderKey] = logger.getCorrelationId();

        return this.performApiGet<RawConversationServiceModel>(
            `/v1/meetings/${ shortId }`,
            {
                customErrorHandler,
                eventProperties: {
                    genericEventPath: '/v1/meetings/{shortId}',
                },
                retries,
                headers,
            },
        ).then(transformWrapper(shortId));
    }

    createGuestMeeting(payload: CreateMeetingPayloadMeetingsApi, retries: number = 0): SyncTasks.Promise<CreateMeetingServiceModel> {
        return this.createMeeting('/v1/meetnow/createjoinlinkguest', payload, retries);
    }

    createAuthenticatedMeeting(payload: CreateAuthenticatedMeetingPayloadMeetingsApi, retries: number = 0): SyncTasks.Promise<CreateMeetingServiceModel> {
        return this.createMeeting('/v1/meetnow/createjoinlink', payload, retries);
    }

    meetnowGuest(payload: CreateMeetingPayloadMeetingsApi, retries: number = 0): SyncTasks.Promise<MeetnowGuestServiceModel> {
        return this.createMeeting('/v1/meetnow/guest', payload, retries);
    }

    private createMeeting<T extends CreateMeetingServiceModel>(path: string, payload: CreateMeetingPayloadMeetingsApi, retries: number): SyncTasks.Promise<T> {
        const headers: { [key: string]: string } = {};
        headers[SessionIdHeaderKey] = logger.getCorrelationId();

        return this.performApiPost<T>(
            path,
            payload,
            {
                customErrorHandler,
                eventProperties: {
                    genericEventPath: path,
                },
                retries,
                headers,
            },
        );
    }
}

export default new MeetingsApiClient(config.urls.api.meetings);
