import config from 'config';
import { Actions } from 'src/ts/models/SharedTypes';
import { isIOSInAppBrowserFlow, OperatingSystems, os } from '../../helpers/EnvironmentHelper';
import { logger } from '../../helpers/LoggerHelper';
import { ConversationServiceModel } from '../../models/ConversationServiceModel';
import { ALauncher } from '../common/ALauncher';

interface LauncherProps {
    conversation: ConversationServiceModel;
}

export class Launcher extends ALauncher<LauncherProps> {

    protected _getLauncherUrl() {
        const conv = this.props.conversation;
        const sessionId = logger.getCorrelationId();

        if (os === OperatingSystems.Android) {
            return config.urls.launcher.intentUri(
                conv.action.toLowerCase() as Actions,
                conv.resource,
                sessionId,
                'launcher',
                config.urls.app.downloadAndroid(conv.resource));
        } else if (isIOSInAppBrowserFlow && conv.shortId) {
            return config.urls.launcher.universalUri(conv.shortId, sessionId);
        }

        return config.urls.launcher.skypeUri(conv.action.toLowerCase() as Actions, conv.resource, sessionId, 'launcher');
    }

    protected _getLoggerAction() {
        return 'join';
    }
}
